import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query sendNotification(
    $doctorName: String!
    $consultId: ID!
    $patientname: String!
    $patientNumber: String!
    $patientEmail: String
    $consultPdf: String
  ) {
    sendConsultClosedNotification(
      doctorName: $doctorName
      consultId: $consultId
      patientname: $patientname
      patientNumber: $patientNumber
      patientEmail: $patientEmail
      consultPdf: $consultPdf
    ) {
      consultId
    }
  }
`

const sendNotification = async ({
  doctorName,
  consultId,
  patientname,
  patientNumber,
  patientEmail,
  consultPdf
}) => {
  let variables = {
    doctorName,
    consultId,
    patientname,
    patientNumber,
    patientEmail
  }

  if(consultPdf){
    variables.consultPdf = consultPdf
  }

  const details = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })

  return details
}

export default sendNotification
