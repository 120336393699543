import React from "react"
import {
  Filter,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  LongTextInput,
  CloneButton,
  CardActions,
  ListButton,
  RefreshButton,
  Link,
  SaveButton,
  Responsive,
  SimpleList,
  ReferenceInput,
  SelectInput

} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import { validateFirstName, validateLastName, validatePractice, validateEmail, validateRequiredPhoneNumField } from "../util/validation"

const CustomEditActions = ({ basePath, data, resource }) => (
    <CardActions>
      <ListButton basePath={basePath} record={data} />
      {/* <RefreshButton basePath={basePath} record={data} /> */}
    </CardActions>
  )

export const EditPracticeUser = props => {
    return (
      <Edit
        actions={<CustomEditActions />}
        title={
          <Link to="/">
            <img
              className="dashboard__img-logo"
              src={logo}
              alt="dermio-logo"
              style={{ paddingTop: "0.3em" }}
            />
          </Link>
        }
        {...props}
        style={{ marginTop: "4rem" }}
      >
        <SimpleForm 
          defaultValue={
            props.permissions === "practiceAdmin"
              ? {
                  practiceId: localStorage.getItem("adminPracticeId")
                }
              : { }
          }
        >
        <TextInput source="firstName" validate={validateFirstName} />
        <TextInput source="lastName" validate={validateLastName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source="phone" validate={validateRequiredPhoneNumField} />
        {/* <SelectInput
          source="role"
          choices={[
            { id: "staff", name: "Staff" },
            { id: "nurse", name: "Nurse" }
          ]}
          defaultValue={"staff"}
        /> */}
        {/* <ReferenceInput
          label="Practice"
          source="practiceId"
          reference="Practice"
          validate={validatePractice}
          id="practiceList"
        >
          <SelectInput optionText="name" />
        </ReferenceInput> */}
      </SimpleForm>
      </Edit>
    )
  }