import AppsyncPatientPortal from "../util/patientPortalClient"
import gql from 'graphql-tag'



const patientPortalClient = AppsyncPatientPortal();



const updatePatientTimeQuery = gql`
  mutation updateDoctorTime($id: String!, $masterStatus: String ) {
    updateDoctorTime(id: $id, masterStatus: $masterStatus) {
      id
      time
      masterStatus
    }
  }`


const doctorActiveSubscriptionQuery = gql`
  subscription onUpdatePatientTime ($id: String!) {
    onUpdatePatientTime (id: $id) {
      time
      patientStatus
    }
  }
`

export const mutateStatus = (id, status) => {
  patientPortalClient
    .hydrated()
    .then(function (cl) {
      const mutation = cl.mutate({
        mutation: updatePatientTimeQuery,
        variables: { id: id, masterStatus: status },
        fetchPolicy: "no-cache",
      })
    })
}


export const subscribe = (videoCallId, actBasedOnPatientState) => {
  patientPortalClient
    .subscribe({ query: doctorActiveSubscriptionQuery, variables: videoCallId })
    .subscribe({
      next: data => {
        actBasedOnPatientState(data)
      },
      error: error => {
        console.error(error)
      },
    });
}