import { Auth } from "aws-amplify"

export default async (consults) => {
  let data = {consults: []}
  const currentUser = (await Auth.currentSession()).getIdToken();

  if(currentUser && currentUser.payload && currentUser.payload.sub){
    data.decryptorDetails = {
      id: currentUser.payload.sub,
      role: currentUser.payload["cognito:groups"],
      bulk: "access"
    };
  }

  data.consults = consults.map((i) => ({
    id: i.id,
    patientname: i.patientname,
    createdAt: i.createdAt,
    isVideoCallConsult: i.isVideoCallConsult,
  }))
  // console.log("data", data)

  const res = await fetch(
    process.env.REACT_APP_DECRYPT_DASH_URL,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, cors, *same-origin
      cache: "no-cache",
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    }
  )
    .then(response => {
      // console.log('response', response);
      return response.text()
    })
    .then(result => {
      // console.log('result', result);
      return JSON.parse(result)
    })
    const decryptedFields = res.data;
    const finalData = consults.map((consultFields, index) => {
      return { ...consultFields, ...decryptedFields[index] };
    });
  return finalData;
}
