import Appsync from "../util/client"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
  query myConsult($patientId: String!) {
    myConsult(patientId: $patientId) {
      id
      email
      cardToken
      cardDeclined
      doctorId
      patientId
      status
      pharmacy_NCPDPID
      patientDob
      allergy
      age
      patientname
      alchohol
      cigrattes_per_day
      drinks_per_week
      gender
      medical_issues
      currentMedicalIssue
      medication
      surgeries
      createdAt
      url1
      url2
      url3
      url4
      email
      chats {
        createdAt
        senderRole
        senderId
        receiverId
        message
        url
      }
      hasPdf
      archived
    }
  }
`

const fetchMyConsult = async variables => {
  console.log(variables, "variables")
  const result = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })
  return result
}

export default fetchMyConsult
