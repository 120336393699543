import React from "react"
import {
  Filter,
  List,
  Datagrid,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  ImageInput,
  SelectArrayInput,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  DisabledInput,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  NumberInput,
  DateInput,
  FormDataConsumer,
  Link,
  SaveButton,
  CardActions,
  Toolbar,
  SelectInput,
} from "react-admin"
import logo from "./Images/dermio-logo.svg"
import { ColorField, ColorInput } from "react-admin-color-input"
import Pagination from "./Pagination"
import CustomEditActions from "./Components/CustomEditActions"
import CustomEditToolbar from "./Components/CustomEditToolbar"
import PracticeImage from "./Components/PracticeImage"
import BotAvatar from "./Components/BotAvatar"
import UserAvatar from "./Components/UserAvatar"
import {
  validateFirstName,
  validateEmail,
  validatePhone,
  validateRequiredTextField,
  validateRequiredTextFieldState,
  validateRequiredNumberField,
  validateZipCode,
  validateRequiredPhoneNumField,
  validateAltPhone,
  validateRequiredAddressField,
  validateRequiredPositiveNumberField,
} from "./util/validation"

import welcomeMsgImg from "./Images/Customized_welcome_message.png"
import finalMsgImg from "./Images/Customized_ending_message.png"
import ConsultSrtMsgImg from "./Images/ConsultSrtMsgImg.png"
import InsuranceMsg from "./Images/InsuranceMsg.png"
import PracticeBankDetails from "./Components/PracticeBankDetails"

export const PracticeFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Email" source="email" />
    <TextInput label="Website" source="website" />
    <TextInput label="Phone" source="phone" />
    <TextInput label="State" source="state" />
    <TextInput label="City" source="city" />
    <NumberInput source="zip" />
    <TextInput source="address" />
    <TextInput source="altPhone" />
    <TextInput source="fax" />
  </Filter>
)

export const PracticeList = props => (
  <List
    {...props}
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    style={{ marginTop: "4rem" }}
    filters={<PracticeFilter />}
    pagination={<Pagination {...props} />}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone" />
      <EditButton id="practiceCreate" />
    </Datagrid>
  </List>
)

const PracticeTitle = ({ record }) => {
  return <span>{record.name}</span>
}

export const PracticeEdit = props => {
  const redirect = (basePath, id, data) => {
    return `/Practice/${data.id}`
    // return `/`;
  }
  const { permissions } = props

  return (
    <Edit
      actions={<CustomEditActions permissions={permissions} />}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      {...props}
      style={{ marginTop: "4rem" }}
    >
      <TabbedForm
        toolbar={<CustomEditToolbar permissions={permissions} />}
        submitOnEnter={false}
        redirect={permissions === "doctor" && redirect}
        validate={validatePhone}
      >
        <FormTab label="Practice Info">
          <TextInput source="name" validate={validateFirstName} />
          <TextInput source="email" type="email" validate={validateEmail} />
          <TextInput source="phone" validate={validateRequiredPhoneNumField} />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Note: Must be 10 digits only.
          </p>
          <TextInput source="website" validate={validateRequiredTextField} />
          <TextInput
            source="address"
            validate={validateRequiredAddressField}
            label="Address Line"
          />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Ex: 9200 Calumet Avenue, Suite N203
          </p>
          <TextInput source="city" validate={validateRequiredTextField} />
          <TextInput source="state" validate={validateRequiredTextFieldState} />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Note: State should only be written in it's shortest form. Eg: New
            York as NY
          </p>
          <TextInput source="zip" validate={validateZipCode} />
          <TextInput
            source="altPhone"
            validate={validateAltPhone}
            defaultValue=""
          />
          <TextInput source="fax" validate={validateRequiredTextField} />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Note: Must be 10 digits. Can also contain an optional extension.
          </p>
          <NumberInput
            source="consultationFee"
            label="Consultation Fee"
            validate={validateRequiredPositiveNumberField}
          />
          <PracticeBankDetails permissions={permissions} />
        </FormTab>
        <FormTab label="ChatBot Messages">
          <div style={{ height: "auto", width: "100vw" }}>
            <LongTextInput
              source="chatBotWelcomeMsg"
              label="Welcome message. For example: Hello! I'm Felecia. Let's have a chat."
            />
            <h4 style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: 15 }}>
              Example image:{" "}
            </h4>
            <img
              src={welcomeMsgImg}
              style={{
                height: "400px",
                width: "400px",
                marginTop: "10px",
                boxShadow: "2px 2px 14px 1px #202420",
                borderRadius: 10,
              }}
            />
          </div>
          <div style={{ height: "auto", width: "100vw", marginTop: 25 }}>
            <LongTextInput
              source="chatBotEndingMsg"
              label="Ending message after patient completes consult details. For example: Thank you for submitting payment details."
            />
            <h4 style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: 15 }}>
              Example image:{" "}
            </h4>
            <img
              src={finalMsgImg}
              style={{
                height: "400px",
                width: "400px",
                marginTop: "10px",
                boxShadow: "2px 2px 14px 1px #202420",
                borderRadius: 10,
                marginBottom: 15,
              }}
            />
          </div>
          <div style={{ height: "auto", width: "100vw", marginTop: 25 }}>
            <BooleanInput source="disableInsuranceMsg" label="Disable insurance message" />
            <LongTextInput
              source="insuranceMsg"
              label="Custom message for insurance informations. For example: This virtual dermatology service does not participate in any insurance programs."
            />
            <h4 style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: 15 }}>
              Example image:{" "}
            </h4>
            <img
              src={InsuranceMsg}
              style={{
                height: "400px",
                width: "400px",
                marginTop: "10px",
                boxShadow: "2px 2px 14px 1px #202420",
                borderRadius: 10,
                marginBottom: 15,
              }}
            />
          </div>
          <div style={{ height: "auto", width: "100vw", marginTop: 25 }}>
            <LongTextInput
              source="consultStartMsg"
              label="Message before asking consult details. For example: Your board-certified dermatologist will help you with your skin issue online."
            />
            <h4 style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: 15 }}>
              Example image:{" "}
            </h4>
            <img
              src={ConsultSrtMsgImg}
              style={{
                height: "400px",
                width: "400px",
                marginTop: "10px",
                boxShadow: "2px 2px 14px 1px #202420",
                borderRadius: 10,
                marginBottom: 15,
              }}
            />
          </div>
        </FormTab>
        <FormTab label="ChatBot Styling">
          <TextInput source="chatbotDisplayName" label="ChatBot Display Name" />
          <NumberInput source="width" label="ChatBot Width" />
          <SelectInput
            source="chatbotPosition"
            label="ChatBot Position"
            choices={[
              { id: "left", name: "Bottom-Left" },
              { id: "right", name: "Bottom-Right" },
            ]}
          />
          <p
            style={{
              color: "rgba(3, 3, 3, 0.74)",
              fontSize: 15,
              width: "100%",
            }}
          >
            Note: By default ChatBot's shown in the Bottom-Right side.
          </p>
          <BooleanInput source="autoOpenChatbot" label="Auto open chatbot" />
          <ColorInput source="headerBgColor" label="Header Background Color" />
          <ColorInput source="headerFontColor" label="Header Font Color" />
          <NumberInput source="headerFontSize" label="Header Font Size" />
          <PracticeImage permissions={permissions} />
          <TextInput source="fontFamilyUrl" label="Font Family Url" />
          <p
            style={{
              color: "rgba(3, 3, 3, 0.74)",
              fontSize: 15,
              width: "100%",
            }}
          >
            Ex: https://fonts.googleapis.com/css?family=Open+Sans
          </p>
          <TextInput source="fontFamily" label="Font Family Name" />
          <NumberInput source="bubbleFontSize" label="Font Size" />
          <ColorInput source="background" label="Body Background Color" />
          <BooleanInput source="hideBotAvatar" label="Hide Bot Avatar" />
          <BooleanInput source="hideUserAvatar" label="Hide User Avatar" />
          <BooleanInput source="hideSubmitButton" label="Hide Submit Button" />
          <ColorInput source="botBubbleColor" label="Bot Bubble Color" />
          <ColorInput source="botFontColor" label="Bot Font Color" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.hideBotAvatar && <BotAvatar {...rest} />
            }
          </FormDataConsumer>
          <ColorInput source="userBubbleColor" label="User Bubble Color" />
          <ColorInput source="userFontColor" label="User Font Color" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.hideUserAvatar && <UserAvatar {...rest} />
            }
          </FormDataConsumer>
          <ColorInput
            source="avatarBackground"
            label="Avatar Background Color"
          />
          <ColorInput source="footerFontColor" label="Footer Font Color" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.hideSubmitButton && (
                <ColorInput
                  source="submitButtonBackground"
                  label="Submit Button Color"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <TextInput source="userInputMessage" label="User Input Message" />
          <ColorInput
            source="chatBotIconBackground"
            label="ChatBot Icon Color"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const PracticeCreate = props => (
  <Create
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    {...props}
    style={{ marginTop: "4rem" }}
  >
    <TabbedForm
      toolbar={<SaveButton id="practiceCreate" style={{ marginTop: 10 }} />}
      defaultValue={{
        role: "practice",
        width: 500,
        headerBgColor: "#ff9f43",
        headerFontColor: "#fff",
      }}
      submitOnEnter={false}
      validate={validatePhone}
      redirect={`/Practice`}
    >
      <FormTab label="Basic Info">
        <TextInput source="name" validate={validateFirstName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source="phone" validate={validateRequiredPhoneNumField} />
        <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
          Note: Must be 10 digits only. Should not contain spaces or symbols
          between them.
        </p>
      </FormTab>
    </TabbedForm>
  </Create>
)
