import React from "react"
import { Route } from "react-router-dom"
import Chat from "./Chat/chat"
import Prescribe from "./Prescribe"
import copyChatBotScript from "./Components/CopyChatBotScript"

export default [
  <Route exact path="/chat" component={Chat} />,
  <Route exact path="/prescribe" component={Prescribe} noLayout />,
  <Route exact path="/copyChatBotScript" component={copyChatBotScript} />,
]

// see: https://github.com/marmelab/admin-on-rest/blob/master/docs/Admin.md#customroutes
