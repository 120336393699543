import React, { useState, useEffect } from "react"
import { Button, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import fetchPractice from "../queries/fetchPractice"

const styles = theme => ({
  typography: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
})

const PracticeBankDetails = ({ classes, record, permissions }) => {

    const [stripeId, setStripeId] = useState(null);

    const practiceId = localStorage.getItem("adminPracticeId");
    useEffect(()=>{
        // console.log(record)
        if(practiceId){
          fetchPractice(practiceId).then((res)=>{
            const {data: { Practice }} = res;
            if(Practice && Practice.stripeId){
              setStripeId(Practice.stripeId);
            }
          }).catch(console.log);
        }        
    },[]);

  return permissions === "practiceAdmin" ? (!stripeId ? (
    <a
      href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID}&scope=read_write&state=${practiceId}&redirect_uri=${process.env.REACT_APP_STRIPE_CONNECT_REDIRECT_URI}`}
      style={{ textDecoration: "none" }}
    >
      {/* old: ca_DafXMMGfUJ2Guc29ASOdkcbgoHUMClbj, new: ca_E6VLNX4YU4wPO6SisAI33ctWyKafqWsN*/}
      <Button variant="contained" color="primary" className={classes.button}>
        Connect Practice Bank Details
      </Button>
    </a>
  ) : (
    !record.enabled && (
      <a
        href={`https://dashboard.stripe.com`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Button variant="contained" color="primary" className={classes.button}>
          View Bank Details
        </Button>
      </a>
    )
  )
  ):
  <></>
}

export default withStyles(styles)(PracticeBankDetails)
