import React, { Component } from "react"
import { ShowController } from "react-admin/lib"
import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import MainShowPage from "./MainShowPage"
import "../../../node_modules/react-dropzone-component/styles/filepicker.css"
import "font-awesome/css/font-awesome.min.css"
import "../../customStyle.css"
import logo from "../../Images/dermio-logo.svg"

const ConsultTitle = () => {
  return (
    <Link to="/">
      <img src={logo} alt="dermio-logo" style={{ paddingTop: "0.3em" }} />
    </Link>
  )
}

const showStyles = theme => ({
  wrapper: {
    margin: "4rem 0",
  },

  rootMd: {
    display: "flex",
    background: "#fafafa",
    margin: "0 0 3rem 0",
    maxHeight: "41rem",
    position: "relative", // for patient images
  },

  rootSm: {
    display: "flex",
    flexDirection: "column",
    margin: "0 2rem 2rem",
    position: "static",
    background: "#fafafa",
  },

  patientDetailsCardMd: {
    flex: "1 1 50%",
    minWidth: "25rem",
    overflow: "auto",
    margin: "0 2rem 0 0",
    padding: ".5rem",
  },

  patientDetailsCardSm: {
    display: "flex",
    flexDirection: "column",
    position: "relative", // for patient images
    background: "#fff",
    padding: ".5rem",
  },

  margin: {
    margin: "0 0 3rem 0",

    [theme.breakpoints.down("sm")]: {
      margin: "0rem 2rem 3rem 2rem",
    },
  },
})

class ConsultShow extends Component {
  render() {
    return (
      <ShowController title={<ConsultTitle />} {...this.props}>
        {controllerProps => {
          return (
            <MainShowPage consultRecordObj={controllerProps} {...this.props} />
          )
        }}
      </ShowController>
    )
  }
}

export default withStyles(showStyles)(ConsultShow)
