import React, { Fragment } from "react"
import { connect } from "react-redux"
import { showNotification, fetchStart, fetchEnd } from "react-admin"
import { push } from "react-router-redux"
import Typography from "@material-ui/core/Typography"
import fetchPrescription from "../queries/fetchPrescriptionURL"
import Button from "./StatusButton"
import LoadingSpinner from "./LoadingSpinner"

const PrescribeButton = ({
  isShown,
  record: consultDetails,
  showNotification,
  fetchStart,
  fetchEnd,
  push,
  isLoading,
  showLoadingSpinner,
}) => {
  const { pharmacy_NCPDPID, patientDob } = consultDetails

  const handlePrescribe = async () => {
    const doctorDetails = JSON.parse(localStorage.getItem("doctorDetails"))

    // console.log(doctorDetails)

    if (!patientDob || !pharmacy_NCPDPID) {
      return showNotification(
        "Some of the patient details are missing. Please wait for them to log back in and enter their details!!",
        "warning"
      )
    }

    showLoadingSpinner(true)

    fetchStart()
    // await Promise.resolve('testing')
    // await Promise.reject('testing')
    await fetchPrescription(consultDetails, doctorDetails)
      .then(data => {
        // console.log('results of query: ', data);

        const { url: prescriptionURL } = data.data.prescription

        localStorage.setItem("consultDetails", JSON.stringify(consultDetails))
        localStorage.setItem("prescriptionURL", prescriptionURL)

        // debugger;

        push("/prescribe")
      })
      .catch(e => {
        // console.log(e);
        showNotification(
          "Something went wrong. Please try again later.",
          "warning"
        )
      })
      .finally(fetchEnd)

    showLoadingSpinner(false)
  }

  return (
    <Fragment>
      {isShown && (
        <div>
          {!pharmacy_NCPDPID || !patientDob ? (
            <Typography color="error">
              Sorry! You cannot prescribe at this time because the patient has
              not entered their{" "}
              {!pharmacy_NCPDPID
                ? `pharmacy`
                : !patientDob
                ? `date of birth`
                : null}{" "}
              details yet. Please wait for them to log back in and enter missing
              details.
            </Typography>
          ) : null}
          {!isLoading ? (
            <Button
              isDisabled={
                !consultDetails.pharmacy_NCPDPID || !consultDetails.patientDob
                  ? true
                  : false
              }
              handleClick={handlePrescribe}
              btnText="Prescribe"
            />
          ) : (
            <LoadingSpinner />
          )}
        </div>
      )}
    </Fragment>
  )
}

export default connect(
  null,
  {
    showNotification,
    fetchStart,
    fetchEnd,
    push,
  }
)(PrescribeButton)
