import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query doctorDetails($id: ID!) {
    doctorDetails: Doctor(id: $id) {
      id
      name
      practiceId
      enabled
      practice {
        website
        disabledPrescription
      }
      notifyByEmail
      notifyBySms
      videocallFee
      dea
      deactivated
    }
  }
`

export const fetchDoctorDetails = async doctorId => {
  const variables = {
    id: doctorId,
  }
  const cl = await client.hydrated()
  const res = await cl.query({ query, variables, fetchPolicy: "no-cache" })
  const { doctorDetails } = res.data
  return doctorDetails
}
