import { AUTH_TYPE } from "aws-appsync/lib/link/auth-link"
import { Auth } from "aws-amplify"
import AWSAppSyncClient from "aws-appsync"

export default () =>
  new AWSAppSyncClient({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    region: process.env.REACT_APP_REGION,
    disableOffline: true,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
  })
