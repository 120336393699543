import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query fetchTemplates(
    $doctorId: ID!
    $createdBy: String!
    $inputText: String!
  ) {
    allMatchingTemplates(
      doctorId: $doctorId
      createdBy: $createdBy
      inputText: $inputText
    ) {
      items {
        id
        createdBy
        plan
        impression
        impressionDescription
        sideEffectsString
        skinCareInstructions
      }
    }
  }
`

const fetchTemplate = async (doctorId, createdBy, inputText) => {
  const variables = { doctorId, createdBy, inputText }

  const template = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "network-only" })
  })

  return template
}

export default fetchTemplate
