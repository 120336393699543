import React, {useEffect, useState} from "react"
import { Button, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import fetchPractice from "../queries/fetchPractice"

const styles = theme => ({
  typography: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  button: {
    marginTop: theme.spacing.unit,
  },
})

const DoctorBankDetails = ({ classes, record, permissions }) => {
  const [practiceStripeId, setPracticeStripeId] = useState(null);
  useEffect(()=>{
    if(record && record.practiceId){
      fetchPractice(record.practiceId).then((res)=>{
        const {data: { Practice }} = res;
        if(Practice && Practice.stripeId){
          // console.log(Practice.stripeId)
          setPracticeStripeId(Practice.stripeId);
        }
      }).catch(console.log);
    }
  },[]);
  
  return (permissions === "admin" || permissions === "practiceAdmin") ? (
    <Typography variant="button" color="primary" className={classes.typography}>
      <strong>
        {(record.stripeId || practiceStripeId)
          ? "Bank details connected"
          : "Bank details yet to be connected"}
      </strong>
    </Typography>
  ) : !record.stripeId && !practiceStripeId? (
    <a
      href={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID}&scope=read_write&state=${record.id}`}
      style={{ textDecoration: "none" }}
    >
      {/* old: ca_DafXMMGfUJ2Guc29ASOdkcbgoHUMClbj, new: ca_E6VLNX4YU4wPO6SisAI33ctWyKafqWsN*/}
      <Button variant="contained" color="primary" className={classes.button}>
        Connect Bank Details
      </Button>
    </a>
  ) : (
    !record.enabled && (
      <a
        href={`https://dashboard.stripe.com`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Button variant="contained" color="primary" className={classes.button}>
          View Bank Details
        </Button>
      </a>
    )
  )
}

export default withStyles(styles)(DoctorBankDetails)
