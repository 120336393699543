import React from "react"
import {
  Filter,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  LongTextInput,
  CloneButton,
  CardActions,
  ListButton,
  RefreshButton,
  Link,
  SaveButton,
  Responsive,
  SimpleList,
} from "react-admin"
import logo from "./Images/dermio-logo.svg"
import { validateText } from "./util/validation"

const CustomEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    {/* <RefreshButton basePath={basePath} record={data} /> */}
  </CardActions>
)

const filterFunc = () => {
  return {
    doctorId: localStorage.getItem("userId"),
  }
}

const adminFilterFunc = () => {
  return {
    createdBy: "admin",
  }
}

export const ChatResponseFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
  </Filter>
)

export const ChatResponseList = ({ permissions, ...props }) => (
  <List
    {...props}
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    filter={filterFunc()}
    filters={<ChatResponseFilter />}
    style={{ marginTop: "4rem" }}
    bulkActionButtons={false}
  >
    <Responsive
      small={<SimpleList primaryText={record => record.name} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Impression" />
          <EditButton />
          <CloneButton />
        </Datagrid>
      }
    />
  </List>
)

export const AdminChatResponseList = ({ permissions, ...props }) => (
  <List
    {...props}
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    filter={adminFilterFunc()}
    filters={<ChatResponseFilter />}
    style={{ marginTop: "4rem" }}
    bulkActionButtons={false}
  >
    <Responsive
      small={<SimpleList primaryText={record => record.name} />}
      medium={
        <Datagrid>
          <TextField source="name" label="Impression" />
          <EditButton />
          <CloneButton />
        </Datagrid>
      }
    />
  </List>
)

const ChatResponseTitle = ({ record }) => {
  return <span>Chat Response {record ? `${record.name}` : ""}</span>
}

export const ChatResponseEdit = props => {
  return (
    <Edit
      actions={<CustomEditActions />}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      {...props}
      style={{ marginTop: "4rem" }}
    >
      <SimpleForm
        defaultValue={
          props.permissions === "doctor"
            ? {
                doctorId: localStorage.getItem("userId"),
                createdBy: "doctor",
              }
            : { createdBy: "admin" }
        }
      >
        <TextInput source="name" label="Name" validate={validateText} />
        <LongTextInput
          source="body"
          label="Message Body"
          resettable
          validate={validateText}
        />
      </SimpleForm>
    </Edit>
  )
}

export const ChatResponseCreate = props => (
  <Create
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    {...props}
    style={{ marginTop: "4rem" }}
  >
    <SimpleForm
      defaultValue={
        props.permissions === "doctor"
          ? {
              doctorId: localStorage.getItem("userId"),
              createdBy: "doctor",
            }
          : { createdBy: "admin" }
      }
      toolbar={<SaveButton id="chatResponseCreate" style={{ marginTop: 10 }} />}
    >
      <TextInput source="name" label="Name" validate={validateText} />
      <LongTextInput
        source="body"
        label="Message Body"
        resettable
        validate={validateText}
      />
    </SimpleForm>
  </Create>
)
