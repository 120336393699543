import React, { useState } from "react"
import {
  Edit,
  SimpleForm,
  TextInput,
  CardActions,
  ListButton,
  Link,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  REDUX_FORM_NAME,
  AutocompleteInput
} from "react-admin"
import { change } from 'redux-form'
import LinearProgress from '@material-ui/core/LinearProgress'
import PracticeBankDetails from "../Components/PracticeBankDetails"
import logo from "../Images/dermio-logo.svg"
import { validateFirstName, validateLastName, validatePractice, validateEmail, validateRequiredPhoneNumField } from "../util/validation"
import getPractice from "../queries/fetchPractice"

const CustomEditActions = ({ basePath, data, resource }) => (
    <CardActions>
      <ListButton basePath={basePath} record={data} />
      {/* <RefreshButton basePath={basePath} record={data} /> */}
    </CardActions>
  )

export const EditAdmin = props => {
  // console.log(props)
  const [isPracticeChanged,  setPracticeChanged] = useState(true);
  const [loading,  setLoading] = useState(false);

  const getPracticeName = async (formData, dispatch ) => {
    
    setPracticeChanged(false);
    
    const { data, error } = await getPractice(formData.practiceId)
    if(error){
      console.log(error)
      setLoading(false)
    }
    if(data && data.Practice &&  data.Practice.id){
      dispatch(change(REDUX_FORM_NAME, 'practiceName', data.Practice.name));
      setLoading(false)
    }

  }

    return (
      <Edit
        actions={<CustomEditActions />}
        title={
          <Link to="/">
            <img
              className="dashboard__img-logo"
              src={logo}
              alt="dermio-logo"
              style={{ paddingTop: "0.3em" }}
            />
          </Link>
        }
        {...props}
        style={{ marginTop: "4rem" }}
      >
        <SimpleForm>
        <TextInput source="firstName" validate={validateFirstName} />
        <TextInput source="lastName" validate={validateLastName} />
        <TextInput source="email" type="email" validate={validateEmail} />
        <TextInput source="phone" validate={validateRequiredPhoneNumField} />
        {props.permissions != "practiceAdmin" ? !loading ? <><ReferenceInput
          label="Practice"
          source="practiceId"
          reference="Practice"
          validate={validatePractice}
          id="practiceList"
          onChange={()=>{setPracticeChanged(true)}}
        >
          <AutocompleteInput 
                options={{
                  suggestionsContainerProps: {
                      disablePortal: true,
              }}}
              optionText="name"
              allowEmpty={true}
              />
        </ReferenceInput> <p
              style={{
                width: "90%",
                fontSize: "10px",
                marginTop: 18,
                marginBottom: 18,
                color: "#aeaeae",
              }}
            >
              To clear practice: cmd+a or ctlr+a then backspace
            </p>
          </>: <LinearProgress style={{ marginTop: 20, marginBottom: 10}}/> : ""}
        {props.permissions != "practiceAdmin" ?  <FormDataConsumer>
                 {({ formData, dispatch, ...rest }) =>{
                   if(formData && isPracticeChanged){
                     getPracticeName(formData, dispatch);
                   }
                   return <TextInput source="practiceName" {...rest} style={{display: "none"}}/>
                  }   
                 }
        </FormDataConsumer>
        : ""}
      </SimpleForm>
      </Edit>
    )
  }