import React, { Component } from "react"
import { Auth } from "aws-amplify"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  showNotification,
  fetchStart,
  fetchEnd,
  ListButton,
  RefreshButton,
} from "react-admin"
import { push } from "react-router-redux"
import Storage from "@aws-amplify/storage"
import createPdfMutation from "./queries/createPdfQuery"
import sendConsultClosedNotification from "./queries/sendConsultClosedNotification"
import updateConsults from "./queries/updateConsults"
import fetchPractice from "./queries/fetchPractice"
import { withStyles } from "@material-ui/core/styles"
import StatusButton from "./Components/StatusButton"
import Modal from "./Components/ConfirmationModal"
import VideoConfirmationModal from "./Components/VideoConfirmationModal"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import updateInviteStatus from "./queries/updateInviteStatus"

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  listButton: {
    marginLeft: "auto",
  },
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
})

class StatusButtons extends Component {
  state = {
    consultStatus: {
      accepted: false,
      closedPaid: false,
      closedUnPaid: false,
    },
    isModalOpen: false,
    statusToBeUpdated: "",
    loading: false,
    openVideocallConfirmModal: false,
    practiceStripeId: null,
    currentUserId: null,
  }

  componentDidMount = async () => {
    const { record, showChatBtn } = this.props
    let doctorDetails = JSON.parse(localStorage.getItem("doctorDetails"))
    // console.log(doctorDetails, 'doctorDetails')
    if (doctorDetails && doctorDetails.practiceId) {
      fetchPractice(doctorDetails.practiceId)
        .then(res => {
          const {
            data: { Practice },
          } = res
          if (Practice && Practice.stripeId) {
            this.setState({ practiceStripeId: Practice.stripeId })
          }
        })
        .catch(console.log)
    }

    try {
      const currentSessionRes = await Auth.currentSession()
      const currentUserId = currentSessionRes.idToken.payload["sub"]
      this.setState({ currentUserId: currentUserId })
    } catch (e) {
      console.error(e)
    }

    // console.log(this.props);

    // console.log(record);

    if (record.status !== "pending") {
      showChatBtn()
    }

    if (record.status === "accepted") {
      this.props.changeConsultStatus().consultAccepted(true)
    } else if (record.status === "closed_paid") {
      this.props.changeConsultStatus().consultClosedPaid(true)
    } else if (record.status === "closed_unpaid") {
      this.props.changeConsultStatus().consultClosedUnpaid(true)
    } else if (record.status === "closed_insurance") {
      this.props.changeConsultStatus().consultClosedInsurance(true)
    }
    // console.log("doctorDetails ",doctorDetails)
  }

  handleStatusUpdate = async closedUnpaidReason => {
    // console.log("called  handleStatusUpdate",)
    const {
      record,
      showNotification,
      fetchStart,
      fetchEnd,
      push,
      showChatBtn,
      generatePdf,
      changeConsultStatus,
      didVideoCall,
    } = this.props

    let doctorDetails = JSON.parse(localStorage.getItem("doctorDetails"))
    const { statusToBeUpdated: status, practiceStripeId } = this.state
    // console.log("status ", status)
    // Fetch consult pdf signedurl
    let consultPdfUrl = null
    if (
      status === "closed_paid" ||
      status === "closed_unpaid" ||
      status === "closed_insurance"
    ) {
      Storage.get(`pdfs/${record.id}.pdf`, { expires: 3600 })
        .then(result => {
          consultPdfUrl = result
        })
        .catch(err => console.log(err))
    }

    // console.log("statusToBeUpdated ",status)
    this.setState({ loading: true }, async () => {
      fetchStart()
      await updateConsults(
        record,
        status,
        closedUnpaidReason,
        null,
        didVideoCall,
        doctorDetails,
        practiceStripeId
      )
        .then(async data => {
          // console.log("results of query: ", data)

          switch (status) {
            case "accepted":
              this.updateVideocallInviteStatus("accepted")
              showChatBtn()
              // generatePdf();
              changeConsultStatus().consultAccepted(true)
              showNotification("Consult has been accepted")
              break
            case "closed_paid":
              generatePdf()
              changeConsultStatus().consultClosedPaid(true)
              showNotification(`Consult is closed and marked as paid`)

              try {
                let doctorName = ""

                try {
                  const currentSessionRes = await Auth.currentSession()
                  doctorName = currentSessionRes.idToken.payload["name"]
                } catch (e) {
                  console.error(e)
                }

                await sendConsultClosedNotification({
                  doctorName,
                  patientname: record.patientname,
                  patientNumber: `+1${record.phone_number}`,
                  consultId: record.id,
                  patientEmail: record.email,
                  consultPdf: consultPdfUrl,
                })
              } catch (e) {
                console.error(e)
              }

              break
            case "closed_unpaid":
              generatePdf()
              changeConsultStatus().consultClosedUnpaid(true)
              showNotification(`Consult is closed and marked as unpaid`)

              try {
                let doctorName = ""

                try {
                  const currentSessionRes = await Auth.currentSession()
                  doctorName = currentSessionRes.idToken.payload["name"]
                } catch (e) {
                  console.error(e)
                }

                await sendConsultClosedNotification({
                  doctorName,
                  patientname: record.patientname,
                  patientNumber: `+1${record.phone_number}`,
                  consultId: record.id,
                  patientEmail: record.email,
                  consultPdf: consultPdfUrl,
                })
              } catch (e) {
                console.error(e)
              }

              break
            case "closed_insurance":
              generatePdf()
              changeConsultStatus().consultClosedInsurance(true)
              showNotification(
                `Consult is closed and marked as insurance covered.`
              )

              try {
                let doctorName = ""

                try {
                  const currentSessionRes = await Auth.currentSession()
                  doctorName = currentSessionRes.idToken.payload["name"]
                } catch (e) {
                  console.error(e)
                }

                await sendConsultClosedNotification({
                  doctorName,
                  patientname: record.patientname,
                  patientNumber: `+1${record.phone_number}`,
                  consultId: record.id,
                  patientEmail: record.email,
                  consultPdf: consultPdfUrl,
                })
              } catch (e) {
                console.error(e)
              }

              break
          }

          this.setState({
            isModalOpen: false,
            statusToBeUpdated: "",
            loading: false,
            openVideocallConfirmModal: false,
          })

          return data
        })
        .catch(e => {
          console.log(e)
          this.setState({
            isModalOpen: false,
            statusToBeUpdated: "",
            loading: false,
            openVideocallConfirmModal: false,
          })

          if (e === "Accepted Consult") {
            showNotification(
              "Sorry. The consult has already been accepted",
              "warning"
            )
            return push("/")
          }

          alert(
            `Problem: ${
              e.message.split(":")[1]
                ? e.message.split(":")[1].replace("Your", "Patient's")
                : "Something went wrong, please logout and login once then try again."
            }`
          )
          try {
            let data = {
              data: e.message,
              consultName: record.id || String(new Date()),
            }
            fetch(process.env.REACT_APP_LOG_URL, {
              method: "POST",
              mode: "no-cors",
              body: JSON.stringify(data),
            })
              .then(response => {
                return response.text()
              })
              .then(result => {
                // console.log(result)
              })
              .catch(err => {
                // console.log(err)
              })
          } catch {
            console.log("err")
          }
        })
        .finally(fetchEnd)
    })
  }

  handleModalOpen = () =>
    this.setState({ isModalOpen: true, statusToBeUpdated: "" })

  handleModalClose = () =>
    this.setState({
      isModalOpen: false,
      statusToBeUpdated: "",
      openVideocallConfirmModal: false,
    })

  handleClick = (status, closedUnPaidReason) => {
    // console.log(closedUnPaidReason);
    // console.log("called handleClick")
    const { isModalOpen } = this.state
    const { record } = this.props
    // console.log("status ",status)
    if (!isModalOpen && record && !record.isVideoCallConsult) {
      // console.log("here")
      return this.setState({
        isModalOpen: true,
        statusToBeUpdated: status,
      })
    } else {
      // console.log("heyyy")
      return this.setState(
        {
          statusToBeUpdated: status,
        },
        () => {
          this.handleStatusUpdate(closedUnPaidReason)
        }
      )
    }

    this.handleStatusUpdate(closedUnPaidReason)
  }

  handleGetSignedUrl = url => {
    if (url == null) {
      return null
    } else {
      return Storage.get(url.split("/").pop(), { expires: 60 })
        .then(result => {
          return result
        })
        .catch(err => {
          return err
        })
    }
  }

  updateVideocallInviteStatus = status => {
    const {
      record,
      pendingVideoCall,
      showNotification,
      changeConsultStatus,
    } = this.props
    if (pendingVideoCall && pendingVideoCall[0].id) {
      updateInviteStatus(
        pendingVideoCall && pendingVideoCall[0].id,
        status,
        record.id
      )
        .then(response => {
          console.log("response ", response)
          // changeConsultStatus().consultDeclined(true)
          // showNotification("Consult has been declined")
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  render() {
    const {
      accepted,
      closedPaid,
      closedUnPaid,
      declined,
      closedInsurance,
    } = this.props.consultStatus
    const {
      isModalOpen,
      loading,
      statusToBeUpdated,
      openVideocallConfirmModal,
      currentUserId,
    } = this.state
    const { classes, record, basePath, pendingVideoCall } = this.props
    let doctorDetails = JSON.parse(localStorage.getItem("doctorDetails"))
    let isDeclinedConsult =
      record && record.status === "declined" ? true : false
    let btns = []
    // console.log(closedInsurance)
    if (accepted || closedPaid || closedUnPaid || closedInsurance) {
      if (
        !closedPaid &&
        !closedUnPaid &&
        !closedInsurance &&
        currentUserId === (record && record.doctorId)
      ) {
        if (
          record.practiceId === process.env.REACT_APP_PRACTICE_ID &&
          record.insuranceFile1 != null
        ) {
          btns = [
            <StatusButton
              key="Close (Insurance)"
              isDisabled={!record.insuranceFile1 ? true : false}
              // cardDeclined={record.cardDeclined ? true : false}
              handleClick={() => {
                this.setState({
                  isModalOpen: true,
                  statusToBeUpdated: "closed_insurance",
                })
              }}
              btnText="Finalize Consult (Insurance)"
            />,
            <StatusButton
              key="Close (Unpaid)"
              isDisabled={false}
              handleClick={() => this.handleClick("closed_unpaid")}
              btnText="Finalize Consult (UnPaid)"
            />,
          ]
        } else {
          btns = [
            <StatusButton
              key="Close (Paid)"
              isDisabled={!record.cardToken ? true : false}
              cardDeclined={record.cardDeclined ? true : false}
              handleClick={() => {
                this.setState({
                  isModalOpen: true,
                  statusToBeUpdated: "closed_paid",
                })
              }}
              btnText="Finalize Consult (Paid)" // If changing btnText, do the same in StatusButton
            />,
            <StatusButton
              key="Close (Unpaid)"
              isDisabled={false}
              handleClick={() => this.handleClick("closed_unpaid")}
              btnText="Finalize Consult (UnPaid)"
            />,
          ]
        }
      } else if (closedPaid) {
        btns = [
          <StatusButton
            key="Closed (Paid)"
            isDisabled={true}
            btnText="Consult Finalized (Paid)"
          />,
        ]
      } else if (closedUnPaid) {
        btns = [
          <StatusButton
            key="Closed (UnPaid)"
            isDisabled={true}
            btnText="Consult Finalized (UnPaid)"
          />,
        ]
      } else if (closedInsurance) {
        btns = [
          <StatusButton
            key="Closed (Insurance)"
            isDisabled={true}
            btnText="Consult Finalized (Insurance)"
          />,
        ]
      }
    }

    return (
      <>
        {/* If consult with insurance */}
        {record.practiceId === process.env.REACT_APP_PRACTICE_ID &&
        record.insuranceFile1 != null ? (
          <Typography
            color={"textPrimary"}
            variant={"caption"}
            paragraph={true}
          >
            Note: You can charge this consult through insurance.
          </Typography>
        ) : (
          !record.cardToken && (
            <Typography color="error" paragraph={true}>
              {record.cardDeclined
                ? "The patient's card has insufficient funds (or) declined. You can finalize this consult as paid once they re-enter their card details."
                : "Sorry! You cannot finalize this consult as paid because the patient has not given their credit card details yet."}
            </Typography>
          )
        )}
        <div className={classes.root}>
          <StatusButton
            isDisabled={
              accepted ||
              closedPaid ||
              closedUnPaid ||
              declined ||
              isDeclinedConsult ||
              closedInsurance
            }
            isHidden={
              accepted ||
              closedPaid ||
              closedUnPaid ||
              declined ||
              isDeclinedConsult ||
              closedInsurance
            }
            handleClick={() =>
              record && record.isVideoCallConsult
                ? this.setState({ openVideocallConfirmModal: true })
                : this.handleClick("accepted")
            }
            btnText={accepted ? "Accepted" : "Accept"}
          />
          {!(accepted || closedPaid || closedUnPaid || closedInsurance) &&
            record &&
            record.isVideoCallConsult && (
              <Button
                onClick={() => {
                  this.updateVideocallInviteStatus("declined")
                }}
                variant="contained"
                disabled={isDeclinedConsult}
              >
                {isDeclinedConsult || declined ? "Declined" : "Decline"}
              </Button>
            )}
          {btns}
          <ListButton
            size="large"
            className={classes.listButton}
            basePath={basePath}
            record={record}
          />
          <Modal
            isOpen={isModalOpen}
            handleOpen={this.handleModalOpen}
            handleConfirmation={() => {
              this.handleClick(statusToBeUpdated)
            }}
            handleClose={this.handleModalClose}
            isLoading={loading}
            status={statusToBeUpdated}
          />
          <VideoConfirmationModal
            isOpen={openVideocallConfirmModal}
            handleOpen={() => {}}
            handleConfirmation={() => {
              this.handleClick("accepted")
            }}
            handleClose={() => {
              this.setState({ openVideocallConfirmModal: false })
            }}
            isLoading={loading}
            status={statusToBeUpdated}
          />
        </div>
      </>
    )
  }
}

StatusButtons.propTypes = {
  fetchStart: PropTypes.func,
  fetchEnd: PropTypes.func,
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
  classes: PropTypes.object.isRequired,
}

export default connect(
  null,
  {
    showNotification,
    fetchStart,
    fetchEnd,
    push,
  }
)(withStyles(styles)(StatusButtons))
