import React, { Fragment } from "react"
import doctorStockAvatar from "../Images/stock-doctor-avatar.jpg"
import { ImageField, ImageInput } from "react-admin"
import gql from "graphql-tag"
import { Auth } from "aws-amplify"
import Appsync from "../util/client"

import Storage from "@aws-amplify/storage"

const client = Appsync()

const query = gql`
  query doctorDetails($id: ID!) {
    doctorDetails: Doctor(id: $id) {
      id
      file
    }
  }
`

Storage.configure({
  bucket: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_BUCKET_REGION,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
})

const styles = {
  height: "100px",
  width: "100px",
  display: "block",
}

export default class DoctorImage extends React.Component {
  constructor() {
    super()

    this.state = {
      url: "",
    }
  }

  async componentDidMount() {
    const permissions = this.props.permissions;
    let doctorId = ""
    if(permissions){
      if (permissions === "admin" || permissions === "practiceAdmin") {
        doctorId = window.location.hash.slice(9) // Good enough but could use a regex to make it foolproof.
      } else {
        doctorId = localStorage.getItem("userId")

        if (!doctorId) {
          try {
            const currentSessionRes = await Auth.currentSession()

            doctorId = currentSessionRes.idToken.payload["sub"]
          } catch (e) {
            console.error(e)
          }
        }
      }

      const variables = {
        id: doctorId,
      }

      try {
        const cl = await client.hydrated()
        const res = await cl.query({ query, variables, fetchPolicy: "no-cache" })
        // console.log("variables ",variables)
        const { doctorDetails } = res.data
        // console.log("doctorDetails ",doctorDetails)
        let docProfilePicStr = JSON.parse(doctorDetails.file)
        // let docProfilePicUrl = 'https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg'

        if (docProfilePicStr && docProfilePicStr.s3 && docProfilePicStr.s3.key) {
          // console.log('\n\nDOC\n\n', docProfilePicStr.s3.key.split('/')[1])
          Storage.get(docProfilePicStr.s3.key.split("/")[1], { expires: 3600 })
            .then(result => {
              localStorage.setItem("doctorProfPic", result)
              this.setState({ url: result })
            })
            .catch(err => console.log(err))
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  render() {
    // console.log(record);

    return this.props.permissions === "admin" ? (
      <img
        style={styles}
        src={this.state.url ? this.state.url : doctorStockAvatar}
        alt="doctor avatar"
      />
    ) : (
      <Fragment>
        <img
          style={styles}
          src={
            this.props.record.file
              ? this.state.url
              : "https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg"
          }
          alt="doctor avatar"
        />
        {/* <img style={styles} src={this.props.record.url} alt="doctor avatar" /> */}
        <ImageInput
          source="file"
          label={
            this.props.record.file ? "Update your avatar" : "Select your avatar"
          }
          accept="image/*"
          placeholder={<p>Drop your photo here</p>}
        >
          <ImageField source="image" title="title" />
        </ImageInput>
      </Fragment>
    )
  }
}
