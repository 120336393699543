import React, { useState, useEffect } from "react"
import compose from "recompose/compose"
import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import ImageIcon from "@material-ui/icons/Image"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CustomerIcon from "@material-ui/icons/PersonAdd"
import Divider from "@material-ui/core/Divider"
import formatDistance from "date-fns/formatDistance"
import { Link } from "react-router-dom"
import { translate } from "react-admin"
import CardIcon from "./CardIcon"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import VideoCallIcon from "@material-ui/icons/VideoCall"
import LinearProgress from "@material-ui/core/LinearProgress"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import ListSubheader from "@material-ui/core/ListSubheader"
import PaymentIcon from "@material-ui/icons/Payment"
import DescriptionIcon from "@material-ui/icons/Description"
import RestoreIcon from "@material-ui/icons/Restore"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"

const styles = theme => ({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
    marginBottom: 20,
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
  },
  value: {
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    // padding: '10px'
  },
  viewMoreBtn: {
    textAlign: "center",
  },
  centerDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  bottomIcon: {
    height: 15,
    width: 15,
  },
})

const DoctorConsults = ({ consults = [], consultStatus, classes, loading }) => {
  const [consultList, setConsultList] = useState(consults)
  const [selectedFilter, setSelectedFilter] = useState("all")

  useEffect(() => {
    if (consults && consults.length) {
      setConsultList(consults)
    }
  }, [consults])

  const handleFilter = value => {
    let newconsults = consults

    if (value === "cash") {
      newconsults = consults.filter(consult => {
        return consult.cardToken || consult.chargeId
      })
    }

    if (value === "insurance") {
      newconsults = consults.filter(consult => {
        return consult.insuranceMemberId && consult.insuranceUrl1
      })
    }
    setConsultList(newconsults)
  }

  return (
    <div className={classes.main}>
      <CardIcon Icon={CustomerIcon} bgColor="#3fcff3" />
      <Card className={classes.card}>
        <Typography className={classes.title} style={{ color: "#3fcff3" }}>
          {consultStatus} Consults
        </Typography>
        <div
          className={
            consultStatus === "Preliminary" ? classes.centerDiv : classes.root
          }
        >
          {consultStatus === "Preliminary" ? (
            <BottomNavigation
              value={selectedFilter}
              onChange={(event, newValue) => {
                setSelectedFilter(newValue)
                handleFilter(newValue)
              }}
              showLabels
              className={classes.centerDiv}
              style={{ padding: 5, fontSize: "12px" }}
            >
              <BottomNavigationAction
                label={<span style={{ fontSize: "12px" }}>All</span>}
                value={"all"}
                icon={<RestoreIcon className={classes.bottomIcon} />}
                style={{ borderRight: "1px solid #3fcff352" }}
              />
              <BottomNavigationAction
                label={<span style={{ fontSize: "12px" }}>Cash</span>}
                value={"cash"}
                icon={<PaymentIcon className={classes.bottomIcon} />}
                style={{ borderRight: "1px solid #3fcff352" }}
              />
              <BottomNavigationAction
                label={<span style={{ fontSize: "12px" }}>Insurance</span>}
                value={"insurance"}
                icon={<DescriptionIcon className={classes.bottomIcon} />}
              />
            </BottomNavigation>
          ) : (
            <> </>
          )}

          <Typography
            variant="headline"
            component="h2"
            className={classes.value}
          >
            {" "}
            {consultList.length}
          </Typography>
        </div>
        <Divider />
        <List style={{ maxHeight: "40vh", overflow: "auto", paddingTop: 0 }}>
          {consultList.map(consult => {
            const timeElapsed = formatDistance(
              new Date(consult.createdAt),
              new Date(),
              {
                includeSeconds: true,
                addSuffix: true,
              }
            )

            return (
              <ListItem
                className={classes.listItem}
                key={consult.id}
                button
                component={Link}
                to={`/Consult/${consult.id}/show`}
              >
                {/* <Avatar>
                                    <ImageIcon />
                                </Avatar> */}
                <ListItemText
                  primary={`${consult.patientname}`}
                  secondary={timeElapsed}
                  className={classes.listItemText}
                />
                {consult.isVideoCallConsult ? (
                  <ListItemIcon>
                    <VideoCallIcon />
                  </ListItemIcon>
                ) : (
                  ""
                )}
              </ListItem>
            )
          })}
          {loading ? (
            <ListItem
              className={classes.listItem}
              key={"1"}
              button
              component={Link}
              to={`/`}
            >
              <ListItemText className={classes.listItemText}>
                <LinearProgress color="primary" />
                <Divider />
                <LinearProgress color="primary" style={{ marginTop: 10 }} />
              </ListItemText>
            </ListItem>
          ) : (
            ""
          )}
          {consultStatus === "Preliminary" ? (
            <ListItem
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
                color: "green",
              }}
              key={".id"}
              button
              component={Link}
              to={`/Consult?filter=%7B"status"%3A"accepted"%7D&order=DESC&page=1&perPage=10&sort=id`}
            ></ListItem>
          ) : null}
        </List>
      </Card>
    </div>
  )
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(DoctorConsults)
