import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const mutationUpdateVideoCall = gql`
  mutation updateVideoCall($id: ID!, $startTime: String){
        updateVideoCall(
          id: $id,
          startTime: $startTime
          lastActive: $startTime
        ){
          id
          lastActive
          startTime
          endTime
          duration
        }
      }
`

const updateVideoCall = async variables => {
  const updateVideoCallResult =  await client.hydrated().then(function (cl) {
      const mutation = cl.mutate({
        mutation: mutationUpdateVideoCall,
        variables: variables,
        fetchPolicy: "no-cache",
      })
      return mutation
    })

  return updateVideoCallResult
}

export default updateVideoCall