import { Auth } from "aws-amplify";

function dataToEncrypt(consultDetails) {
  const fieldsToEncrypt = [
    "patientname",
    "age",
    "email",
    "gender",
    "phone_number",
    "firstname",
    "lastname"
  ]
  const objectToEncrypt = fieldsToEncrypt.reduce((res, i) => {
    if (consultDetails[i]) {
      return { ...res, [i]: consultDetails[i] }
    }
    return res
  }, {})

  return objectToEncrypt
}
export default function encryptConsult(consultDetails) {
  // let firstName = consultDetails.patientname ? consultDetails.patientname.split(' ')[0] : consultDetails.patientname;
  // consultDetails.patientname = consultDetails.patientname ? consultDetails.patientname.split(' ').slice(1).join(' ') : consultDetails.patientname;

  return new Promise(async (resolve, reject) => {
    const data = dataToEncrypt(consultDetails)

    // THIS URL IS FROM PRODUCTION AWS API GATEWAY

    fetch(
      process.env.REACT_APP_ENCRYPT_URL,
      // "https://ggax3opd48.execute-api.us-east-1.amazonaws.com/dev/api/encryptconsult",
      // "https://ij1omrmtl7.execute-api.us-east-1.amazonaws.com/dev/api/encryptconsult",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        headers: {
          'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )
      .then(response => {
        return response.text()
      })
      .then(result => {
        const encryptedFields = JSON.parse(JSON.parse(result).body)
        const finalData = { ...consultDetails, ...encryptedFields }
        resolve(finalData)
      })
      .catch(console.log)
  })
}
