import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./App.css"
import "./form.css"
import { unregister } from "./registerServiceWorker"
require("dotenv").config()

ReactDOM.render(<App />, document.getElementById("root"))
unregister()
