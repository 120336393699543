import React from "react"
import BasicInfo from "./basicInfoPage"
import PracticeInfo from "./practiceInfoPage"
import BankForm from "./bankdetailForm"
import Confirm from "./confirm"
import { connect } from "react-redux"

class Registration extends React.Component {
  constructor() {
    super()
    this.state = {
      step: 1,
      data: {
        fname: "",
        lname: "",
        email: "",
        NPIname: "",
        LicensedState: [],
        DEAname: "",
        PracticeName: "",
        Street: "",
        City: "",
        ResidentState: "",
        ZipCode: "",
        Fax: "",
        Phone: "",
        Accountname: "",
        AccountNumber: "",
        RoutingNumber: "",
      },
      result: {},
    }
  }

  addDoctor = data_fields => {
    const query = `
    mutation up {
      updateDoctor(id: "193d87b9-5751-4f9c-94b2-dca7da25aa88",
      licensedState: [], deaName: "", practiceName: "", street: "", city: "", residentState: "", zipCode: "", fax: "", accountname: "", accountNumber: "",  routingNumber: ""
    ) {
        id
        name
        deaName
      }
    }`
    const variables = data_fields
    const args = {
      type: "DOCTORADD",
      payload: { customType: "adminDash", query, variables },

      meta: {
        fetch: "UPDATE",
        onSuccess: {
          callback: () => {
            // console.log("success");
          },
        },
        onError: {
          callback: ({ error }) => {
            // console.log("error", error)
            this.setState({ error })
          },
        },
        resource: "custom",
      },
    }
    this.props.dispatch(args)
  }

  componentDidMount() {
    const query = `query getDoctor {
      Doctor(id: "193d87b9-5751-4f9c-94b2-dca7da25aa88") {
        id
        name
        enabled
      }
    }`
    const variables = {}
    const args = {
      type: "DOCTORSIGNUP",
      payload: { customType: "adminDash", query, variables },

      meta: {
        fetch: "GET_LIST",
        onSuccess: {
          callback: ({ payload }) => {
            const res = payload.res.data
            this.setState({ result: res.Doctor })
            // console.log(this.state.result);
          },
        },
        onError: {
          callback: ({ error }) => {
            // console.log("error", error)
            this.setState({ error })
          },
        },
        resource: "custom",
      },
    }
    this.props.dispatch(args)
  }

  fieldUpdate = (e, fieldKey) => {
    //console.log(e.target.value);
    let new_data = Object.assign({}, this.state.data)
    {
      fieldKey === "LicensedState"
        ? (new_data[fieldKey] = e)
        : (new_data[fieldKey] = e.target.value)
    }
    this.setState({ data: new_data })
  }
  nextStep = () => {
    this.setState({ step: this.state.step + 1 })
  }

  previousStep = () => {
    this.setState({ step: this.state.step - 1 })
  }

  submitStep = () => {
    this.nextStep()
  }

  showStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <BasicInfo
            data={this.state.data}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            fieldUpdate={this.fieldUpdate}
          />
        )
      case 2:
        return (
          <PracticeInfo
            data={this.state.data}
            nextStep={this.nextStep}
            previousStep={this.previousStep}
            fieldUpdate={this.fieldUpdate}
          />
        )
      case 3:
        return (
          <BankForm
            data={this.state.data}
            previousStep={this.previousStep}
            fieldUpdate={this.fieldUpdate}
            nextStep={this.nextStep}
          />
        )
      case 4:
        return (
          <Confirm
            data={this.state.data}
            addDoctor={this.addDoctor}
            previousStep={this.previousStep}
          />
        )
    }
  }
  render() {
    return (
      <main>
        <span className="progress-step">Step {this.state.step}</span>
        {this.showStep()}
      </main>
    )
  }
}
export default connect()(Registration)
