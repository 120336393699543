import React from "react"
import {
  Create,
  Link,
  SaveButton,
  SimpleForm
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import { DateInput, TimeInput } from 'react-admin-date-inputs';

export const DoctorIcon = null

export const VideoCallCreate = props => (
  // console.log('\ninvoked\n'),
  <Create
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    {...props}
    style={{ marginTop: "3rem" }}
  >

  <SimpleForm
      defaultValue={
        props.permissions === "doctor"
          ? {
              doctorId: localStorage.getItem("userId"),
              createdBy: "doctor",
            }
          : { createdBy: "admin" }
      }
      toolbar={<SaveButton id="chatResponseCreate" style={{ marginTop: 10 }} />}
    >
    <DateInput 
      source="scheduledDateTime" 
      label="Select Date" 
      options={{ 
        format: 'MM/dd/yyyy',
        ampm: false,
        clearable: true,
        disablePast: true
      }} 
    />
    <TimeInput source="scheduledDateTime" label="Select time" options={{ format: 'HH:mm' }} />
    </SimpleForm>
  </Create>
)
