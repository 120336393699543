import React from "react"
import {
  Filter,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  LongTextInput,
  CloneButton,
  CardActions,
  ListButton,
  RefreshButton,
  Link,
  SaveButton,
  Responsive,
  SimpleList,
  ReferenceField,
  SelectInput,
  ReferenceInput
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import { validateText } from "../util/validation"

export const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Firstname" source="firstname" />
      <TextInput label="Lastname" source="lastname" />
      <SelectInput
        label="Role"
        source="role"
        choices={[
          { id: "staff", name: "staff" },
          { id: "nurse", name: "nurse" }
        ]}
      />
      {props && props.permissions && props.permissions === "admin" && <ReferenceInput
          label="Practice"
          source="practiceId"
          reference="Practice"
          // validate={validatePractice}
          id="practiceList"
        >
          <SelectInput optionText="name" />
      </ReferenceInput>}
    </Filter>
  )
}
  
export const ListPracticeUser = ({ permissions, ...props }) =>{ 

  let filter = {};
  if(!permissions) return <></>
  if(permissions === "practiceAdmin"){
    filter.practiceId = localStorage.getItem("adminPracticeId");
  }else if(permissions === "staff" || permissions === "nurse"){
    filter.practiceId = localStorage.getItem("userPracticeId");
  }

  return (
    <List
      {...props}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      filter={filter}
      filters={<CustomFilter permissions={permissions}/>}
      style={{ marginTop: "4rem" }}
      bulkActionButtons={false}
    >
      <Responsive
        small={<SimpleList primaryText={record => record.name} />}
        medium={
          <Datagrid>
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name" />
            <TextField source="role" label="Role" />
            <TextField source="email" label="Email" />
            {permissions === "admin" ? <></>: <EditButton />}
          </Datagrid>
        }
      />
    </List>
  )
      }