import React, { Component, Fragment } from "react"
import { Responsive, Title } from "react-admin"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import { showNotification } from "react-admin"
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import { Auth } from "aws-amplify"
// import DoctorList from "./AllDoctors"
import Appsync from "../../util/client"
import Consults from "./Consults"
import Button from "@material-ui/core/Button"
import logo from "../../Images/dermio-logo.svg"
import "./css/adminDashboard.css"
import decryptConsult from "../../util/decryptConsult"
import decryptDash from "../../util/decryptDash"
import fetchPracticeAdmin from "../../queries/fetchPracticeAdmin"
import fetchPracticeUser from "../../queries/fetchPracticeUser"
import moment from "moment"
import SharePatientPortalLink from "../../Components/SharePatientPortalLink"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const client = Appsync()

const query = gql`
  query adminQuery ($practiceId: ID){
    allAcceptedConsults: allConsults(
      status: { eq: "accepted" },
      filter: {
        practiceId: {eq: $practiceId}        
      },
      limit: null
    ) {
      items {
        id
        status
        patientId
        doctorId
        medication
        allergy
        patientname
        createdAt
        state
      }
    }
    allPendingConsults: allConsults(
      status: { eq: "pending" },
      filter: { 
        practiceId: {eq: $practiceId},
      },
      limit: null
    ) {
      items {
        id
        status
        patientId
        doctorId
        medication
        allergy
        patientname
        createdAt
        state
      }
    }
    allCompleted: allConsults(
      status: { eq: "closed_paid" },
      filter: {
        practiceId: {
          eq: $practiceId,
        },
      },
    ) {
      items {
        id
        status
        patientname
        totalFee
        source
        isVideoCallConsult
        doctorName
        createdAt
      }
      nextToken
    }

  }
`
const loadQuery = gql`
  query adminQuery ($practiceId: ID, $nextToken: String){
    allCompleted: allConsults(
      status: { eq: "closed_paid" },
      filter: {
        practiceId: {
          eq: $practiceId,
        },
      },
      nextToken: $nextToken
    ) {
      items {
        id
        status
        patientname
        doctorName
        createdAt
      }
      nextToken
    }
  }
`

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column", marginTop: "4em" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
}

class Dashboard extends Component {
  state = {
    consults: {
      allAcceptedConsults: [],
      allPendingConsults: [],
    },
    allDoctors: [],
  }

  componentDidMount=()=>{
    this.fetchDashboardData();
  }
  loadMore = async() => {
    if(!this.state.nextToken) return 
    const cl = await client.hydrated();
    const practiceId = localStorage.getItem("adminPracticeId") || localStorage.getItem("userPracticeId");
    const res = await cl.query({
      query: loadQuery,
      variables: {practiceId: practiceId, nextToken: this.state.nextToken},
      fetchPolicy: "no-cache",
    })
    let { items: allCompleted, nextToken } = res.data.allCompleted
    let decryptedCompleted = await decryptDash(allCompleted)
    const existing = this.state.allCompleted
    const decrypt = this.state.decryptedCompleted

    this.setState({
      decryptedCompleted: [...decrypt, ...decryptedCompleted],
      nextToken,
      allCompleted: [...existing, ...allCompleted]
    })

  }
  downloadCsv = () => {
    const { decryptedCompleted, allCompleted } = this.state;

    const list = allCompleted.map((consult) => {
      const patientname = decryptedCompleted.find((i) => i.id == consult.id)['patientname']
      return {
        ...consult, 
        patientname: patientname, 
        createdAt: moment(consult.createdAt).format('L')
      }
    })
    const csv = convertToCSV({
      data: list,
      fields: [
        'status', 'patientname', 'doctorName', 
        'createdAt', 'totalFee', 'source',
        'isVideoCallConsult'
      ],
    });
    downloadCSV(csv, 'paidconsults');
  }

  fetchDashboardData = async() =>{
    Auth.currentSession().then(async result => {
      const roles = result.idToken.payload["cognito:groups"]
      const role = roles[0];
      let practiceId = null;
      // if practice admin user
      if(role && role === "practiceAdmin"){
        practiceId = localStorage.getItem("adminPracticeId");

        if(!practiceId){
          let adminId = result.idToken.payload["sub"];
          const {data: {Admin}} = await fetchPracticeAdmin(adminId);
          if(Admin && Admin.practiceId){
            practiceId = Admin.practiceId;
          }
        }
      }else if(role && (role === "staff" || role === "nurse")){
        practiceId = localStorage.getItem("userPracticeId");
        if(!practiceId){
          const {data: { PracticeUser }} = await fetchPracticeUser(result.idToken.payload["sub"]);
          if(PracticeUser && PracticeUser.practiceId){
            practiceId = PracticeUser.practiceId;
          }
        }
      }

      const cl = await client.hydrated();
      const res = await cl.query({
        query,
        variables: {practiceId: practiceId},
        fetchPolicy: "no-cache",
      })

      // const { items: allDoctors } = res.data.allDoctors
      let { items: allAcceptedConsults } = res.data.allAcceptedConsults
      let { items: allPendingConsults } = res.data.allPendingConsults
      let { items: allCompleted } = res.data.allCompleted
      let { nextToken: nextToken } = res.data.allCompleted

      allAcceptedConsults = await decryptDash(allAcceptedConsults)
      allPendingConsults = await decryptDash(allPendingConsults)
      let decryptedCompleted = await decryptDash(allCompleted)

      allPendingConsults = allPendingConsults.filter(
            consult => {
              if (consult.cardToken || consult.cardDeclined === true) {
                return true
              } else if (
                consult.practiceId === process.env.REACT_APP_PRACTICE_ID &&
                consult.insuranceFile1 != null
              ) {
                return true
              } else {
                return false
              }
            }
      )
      this.setState({
        // allDoctors,
        nextToken,
        decryptedCompleted,
        allCompleted,
        consults: {
          allAcceptedConsults,
          allPendingConsults,
        },
      })
    });
  }

  render() {
    const {
      consults: { allAcceptedConsults, allPendingConsults },
      // allDoctors,
    } = this.state
    const decryptedCompleted = this.state.decryptedCompleted;

    const practiceId = localStorage.getItem("adminPracticeId") || localStorage.getItem("userPracticeId");
    return (
      <Fragment>
        <Title
          title={
            <Link to="/">
              <img
                className="dashboard__img-logo"
                src={logo}
                alt="dermio-logo"
                style={{ paddingTop: "0.3em" }}
              />
            </Link>
          }
        />
        <Responsive
          xsmall={
            <div style={styles.flexColumn}>
              <Consults consults={allPendingConsults} consultStatus="New" />
              <Consults
                consults={allAcceptedConsults}
                consultStatus="Preliminary"
              />
              {/* <DoctorList doctors={allDoctors} /> */}
            </div>
          }
          small={
            <><div style={styles.flexColumn}>
              <Consults consults={allPendingConsults} consultStatus="New" />
              <Consults
                consults={allAcceptedConsults}
                consultStatus="Preliminary"
              />
              {/* <DoctorList doctors={allDoctors} /> */}
            </div>
              <div>
                <SharePatientPortalLink
                  url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                >
                  <div style={styles.shareBtns}>
                    <FacebookShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <FacebookIcon size="32" round />
                    </FacebookShareButton>
                      <TwitterShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                      >
                        <TwitterIcon size="32" round />
                      </TwitterShareButton>
                    <LinkedinShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <LinkedinIcon size="32" round />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <WhatsappIcon size="32" round />
                    </WhatsappShareButton>
                  </div>
                </SharePatientPortalLink>
              </div>
            </>
          }
          medium={
            <div style={{ marginTop: "3em" }}>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <Consults
                    consults={allAcceptedConsults}
                    consultStatus="Preliminary"
                  />
                </div>
                <div style={styles.rightCol}>
                  <Consults consults={allPendingConsults} consultStatus="New" />
                </div>
                <div style={styles.rightCol}>
                  <Consults consults={decryptedCompleted} consultStatus="Recent Completed" >
                    <>
                      {this.state.nextToken ?  <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.loadMore()}
                    >
                      Loadmore
                    </Button> : null}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.downloadCsv()}
                    >
                      Download csv
                    </Button>
                    </>
                  </Consults>
                </div>
              </div>
              {/* <div style={styles.singleCol}>  
</div> */}
              <div style={styles.singleCol}>
                <SharePatientPortalLink
                  url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                >
                  <div style={styles.shareBtns}>
                    <FacebookShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <FacebookIcon size="32" round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <TwitterIcon size="32" round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <LinkedinIcon size="32" round />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <WhatsappIcon size="32" round />
                    </WhatsappShareButton>
                  </div>
                </SharePatientPortalLink>
              </div>

            </div>
          }
          large={
            <div style={{ marginTop: "3em" }}>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <Consults
                    consults={allAcceptedConsults}
                    consultStatus="Preliminary"
                  />
                </div>
                <div style={styles.rightCol}>
                  <Consults consults={allPendingConsults} consultStatus="New" />
                </div>
                <div style={styles.rightCol}>
                  <Consults consults={decryptedCompleted} consultStatus="Recent Completed" >
                    <>
                      {this.state.nextToken ?  <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.loadMore()}
                    >
                      Loadmore
                    </Button> : null}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.downloadCsv()}
                    >
                      Download csv
                    </Button>
                    </>
                  </Consults>
                </div>
                {/* <div style={styles.rightCol}>
   </div> */}
              </div>
              <div style={styles.singleCol}>
                <SharePatientPortalLink
                  url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                >
                  <div style={styles.shareBtns}>
                    <FacebookShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <FacebookIcon size="32" round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <TwitterIcon size="32" round />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <LinkedinIcon size="32" round />
                    </LinkedinShareButton>
                    <WhatsappShareButton
                      url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${practiceId}`}
                    >
                      <WhatsappIcon size="32" round />
                    </WhatsappShareButton>
                  </div>
                </SharePatientPortalLink>
              </div>


            </div>
          }
        />
      </Fragment>
    )
  }
}

export default connect(
  null,
  { push, showNotification }
)(Dashboard)
