import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query fetchPrescriptionURL($input: PrescriptionDetails) {
    prescription(input: $input) {
      url
    }
  }
`

const fetchPrescription = async (consultDetails, doctorDetails) => {
  const {
    pharmacy_NCPDPID,
    patientname,
    gender,
    patientId,
    patientDob: dob,
  } = consultDetails

  const nameArr = patientname.split(" ")
  const patientFName = nameArr[0]
  const patientLName = nameArr.slice(1).reduce((a, b) => a + " " + b)
  const patientGender = gender === "Male" ? "M" : "F"

  // console.log(patientFName);
  // console.log(patientLName);
  // console.log(patientGender);
  // console.log(dob);

  const {
    id: doctorId,
    firstName,
    lastName,
    email,
    npi,
    dea,
    stateLicenseNumber,
    practice: {
      name: clinicName,
      address,
      city,
      state,
      phone: clinicPhone,
      zip: clinicZip,
      fax: clinicFax,
    },
  } = doctorDetails

  // const practiceId = doctorId.slice(0, 30)
  const practiceId = doctorId.slice(0, 29)
  // const practiceId = "3536c751-3176-449d-84c8"

  // console.log(practiceId)

  // debugger;

  const patientDetails = {
    patientId,
    firstName: patientFName,
    // firstName: 'Evil',
    lastName: patientLName,
    // lastName: 'Morty',
    gender: patientGender,
    // gender: 'M',
    patientPharmacyNCPDPID: pharmacy_NCPDPID,
    // patientPharmacyNCPDPID: '9900116',
    dob,
  }

  const prescriberDetails = {
    firstName,
    lastName,
    npi,
    dea,
    email,
    doctorId,
    practiceId,
  }

  if (stateLicenseNumber && stateLicenseNumber.trim()) {
    prescriberDetails.stateLicenseNumber = stateLicenseNumber
  } else {
    prescriberDetails.stateLicenseNumber = ""
  }

  const locationDetails = {
    clinicName,
    addr1: address,
    city,
    state,
    zip: clinicZip,
    phone: clinicPhone,
    fax: clinicFax,
  }

  const input = {
    patient: patientDetails,
    prescriber: prescriberDetails,
    location: locationDetails,
  }

  const variables = {
    input,
  }

  const prescription = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "no-cache" })
  })

  return prescription
}

export default fetchPrescription
