import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
query fetchConsultChats($consultId: String!) {
  allChats: allChatsWithIndex(consultId: $consultId) {
    id
    createdAt
    senderRole
    senderId
    receiverId
    message
    url
    seen
  }
}
`
// // old fetch query
// query fetchConsultChats($consultId: String!) {
//   allChats(filter: { consultId: { eq: $consultId } }) {
//     id
//     createdAt
//     senderRole
//     senderId
//     receiverId
//     message
//     url
//     seen
//   }
// }

const fetchConsultChat = async record => {
  const { id: consultId } = record

  const variables = { consultId }

  const consultChat = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "no-cache" })
  })

  return consultChat
}

export default fetchConsultChat
