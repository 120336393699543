import Appsync from "../util/client"
import { showNotification } from "react-admin"
const gql = require("graphql-tag")
const client = Appsync()

const mutation = gql`
  mutation createConsult(
    $patientId: String!
    $patientname: String!
    $phone_number: String!
    $doctorName: String!
    $doctorId: String!
    $source: String
    $status: String!
    $encryptedPhoneNumber: String!
  ) {
    createDoctorConsult(
      patientId: $patientId
      patientname: $patientname
      phone_number: $phone_number
      doctorName: $doctorName
      doctorId: $doctorId
      source: $source
      status: $status
      encryptedPhoneNumber: $encryptedPhoneNumber
    ) {
      patientId
    }
  }
`

export default async patient => {
  const {
    patientId,
    patientName: patientname,
    phoneNumber: phone_number,
    doctorName,
    doctorId,
    source,
    status,
    encryptedPhoneNumber,
  } = patient

  const variables = {
    patientId,
    patientname,
    phone_number,
    doctorName,
    doctorId,
    source,
    status,
    encryptedPhoneNumber,
  }

  const createdUser = await client.hydrated().then(cl => {
    cl.mutate({ mutation, variables, fetchPolicy: "no-cache" })
    showNotification("Consult created successfully!")
  })

  return createdUser
}
