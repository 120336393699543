import React, { Component } from "react"
import Card from "@material-ui/core/Card"
// import { Responsive, Title } from 'react-admin'
import CardContent from "@material-ui/core/CardContent"
import { Link } from "react-router-dom"
import { Title } from "react-admin"
import { CopyToClipboard } from "react-copy-to-clipboard"
import CodeIcon from "@material-ui/icons/Code"
import Button from "@material-ui/core/Button"
import { showNotification } from "react-admin"
import { connect } from "react-redux"
import Prism from "prismjs"
import logo from "../Images/dermio-logo.svg"
import "prismjs/themes/prism-okaidia.css"

class CopyChatBotScript extends Component {
  state = {
    copied: false,
    clipBoardText: "",
  }

  componentDidMount = () => {
    const practiceId = localStorage.getItem("adminPracticeId") || JSON.parse(localStorage.getItem("doctorDetails")).practiceId
    if (practiceId) {
      const clipBoardText = `<script>
          window.practiceId = '${practiceId}';
          function addScript() {
                  var head = document.getElementsByTagName('head')[0];
                  var script = document.createElement('script');
                  script.src = "https://s3.amazonaws.com/${process.env.REACT_APP_CHAT_BUCKET_NAME}/bundle.js";
                  script.type = 'text/javascript';
                  head.insertBefore(script, document.getElementsByTagName("script")[0]);
              }   
          setTimeout(addScript, 2000)
      </script>`

      this.setState({
        clipBoardText,
      })
    }
  }

  componentDidUpdate = () => {
    Prism.highlightAll()
  }

  onCopy = () => {
    const { showNotification } = this.props

    this.setState({ copied: true })
    showNotification(
      "ChatBot script is copied to clipboard. Please paste the script in your website."
    )
  }

  render() {
    const { clipBoardText } = this.state

    // let preStyles = {
    //     width: '100%',
    //     // maxWidth: '500px',
    //     overflowX: 'auto'
    // }

    // let codeStyles = {
    //     backgroundColor: '#f6f3f3',
    //     border: '1px solid #999',
    //     display: 'block',
    //     padding: '20px',
    // }

    return (
      <div>
        <Title
          title={
            <Link to="/">
              <img
                src={logo}
                alt="dermio-logo"
                style={{ paddingTop: "0.3em" }}
              />
            </Link>
          }
        />
        <Card style={{ marginTop: "4rem" }}>
          <CardContent>
            <div style={{ margin: "1rem", color: "#000" }}>
              <p style={{ fontFamily: "monospace", fontSize: "1.2rem" }}>
                Step 1:
              </p>{" "}
              Copy the chatbot code by clicking the button below.
            </div>

            <pre>
              <code className="language-javascript">{clipBoardText}</code>
            </pre>
            <CopyToClipboard text={clipBoardText} onCopy={() => this.onCopy()}>
              <Button>
                <CodeIcon style={{ paddingRight: "0.5em" }} />
                Copy ChatBot script to clipboard
              </Button>
            </CopyToClipboard>
          </CardContent>
        </Card>

        <Card
          style={{
            marginTop: "20px",
            color: "#000",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent style={{ fontSize: "1rem" }}>
            <div style={{ margin: "1rem" }}>
              <p style={{ fontFamily: "monospace", fontSize: "1.2rem" }}>
                Step 2:
              </p>{" "}
              Ask your web developer to include this chatbot (script) code to
              your website
            </div>
            <img
              src={require("../Images/copy_chatbot_script_help.png")}
              style={{
                marginLeft: "15px",
                maxHeight: "500px",
                maxWidth: "500px",
              }}
              alt=""
            />
          </CardContent>
        </Card>
        <Card
          style={{
            marginTop: "20px",
            color: "#000",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardContent style={{ fontSize: "1rem" }}>
            <div style={{ margin: "1rem" }}>
              <p style={{ fontFamily: "monospace", fontSize: "1.2rem" }}>
                Step 3:
              </p>{" "}
              Now, the chatbot will be available in your website. And you can
              customize the chatbot by clicking on the practice button and then
              the chatbot styling icon.
            </div>
            <img
              src={require("../Images/dermioChatExample.png")}
              style={{
                marginLeft: "15px",
                height: "350px",
                width: "750px",
              }}
              alt=""
            />
            <img
              src={require("../Images/customize_chatbot_help1.png")}
              style={{
                marginLeft: "15px",
                height: "350px",
                width: "750px",
              }}
              alt=""
            />
            <img
              src={require("../Images/customize_chatbot_help2.png")}
              style={{
                marginLeft: "15px",
                height: "350px",
                width: "750px",
              }}
              alt=""
            />
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default connect(
  null,
  {
    showNotification,
  }
)(CopyChatBotScript)
