import React, { Fragment } from "react"
import { Auth } from "aws-amplify"
import { RequireNewPassword } from "aws-amplify-react"
import { I18n, JS, ConsoleLogger as Logger } from "@aws-amplify/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import dermioLogo from "../Images/dermio-logo.svg"
import { showNotification } from "react-admin"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
})

function objectWithProperties(obj, keys) {
  const target = {}
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue
    }
    target[key] = obj[key]
  }
  return target
}

class CustomRequireNewPassword extends RequireNewPassword {
  state = {
    error: "",
  }

  handleChange = e => {
    this.handleInputChange(e)
    this.setState({
      error: "",
    })
  }

  checkContact(user) {
    Auth.verifiedContact(user)
      .then(data => {
        if (!JS.isEmpty(data.verified)) {
          this.changeState("signedIn", user)
        } else {
          user = Object.assign(user, data)
          this.changeState("verifyContact", user)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleSubmit = e => {
    e.preventDefault()

    const user = this.props.authData

    const { password } = this.inputs

    const { requiredAttributes } = user.challengeParam

    const attrs = objectWithProperties(this.inputs, requiredAttributes)

    Auth.completeNewPassword(user, password, attrs)
      .then(user => {
        // this.checkContact(user); // Hiding verify user by email step
        this.changeState("signedIn", user)
      })
      .catch(err => {
        console.error(err)

        if (err.code === "InvalidParameterException") {
          this.setState({
            error: "Password must have a length greater than or equal to 6.",
          })
        }

        showNotification(
          "Password must be atleast 8 characters long.",
          "warning"
        )
      })
  }

  render() {
    const { classes, authData: user, authState } = this.props

    let requiredAttributes =
      user && user.challengeParam ? user.challengeParam.requiredAttributes : []

    if (authState === "requireNewPassword") {
      return (
        <div className={classes.main}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className={classes.hint}>Set a new password</div>
              <div className={classes.error}>{this.state.error}</div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    id="password"
                    label="Password"
                    name="password"
                    margin="normal"
                    type="password"
                    fullWidth={true}
                    required
                    error={this.state.error ? true : false}
                    onChange={this.handleChange}
                  />
                  {requiredAttributes.map(attribute => (
                    <TextField
                      id={attribute}
                      name={attribute}
                      label={attribute}
                      key={attribute}
                      margin="normal"
                      type="text"
                      fullWidth={true}
                      error={this.state.error ? true : false}
                      onChange={this.handleChange}
                    />
                  ))}
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button onClick={() => this.changeState("signIn")}>
                  Back to Sign In
                </Button>
                <Button
                  variant="raised"
                  type="submit"
                  color="primary"
                  className={classes.button}
                  fullWidth
                >
                  Change
                </Button>
              </CardActions>
            </form>
          </Card>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(CustomRequireNewPassword)
