import React, { Fragment } from "react"
import {
  ShowButton,
  List,
  Datagrid,
  Responsive,
  DateField,
  TextField,
  BooleanField,
  SimpleList,
} from "react-admin/lib"

import ArchiveButton from "../../Components/ArchiveButton"

import "../../../node_modules/react-dropzone-component/styles/filepicker.css"

import "font-awesome/css/font-awesome.min.css"

import PatientLinkField from "../../Components/PatientLinkField"
import Pagination from "../../Pagination"
import { DoctorFilter } from "./doctorFilter"

import logo from "../../Images/dermio-logo.svg"
import { Link } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"

import "../../customStyle.css"

const ConsultTitle = () => {
  return (
    <Link to="/">
      <img src={logo} alt="dermio-logo" style={{ paddingTop: "0.3em" }} />
    </Link>
  )
}

// The List component supports the `root`, `header`, `actions` and `noResults` CSS classes. Here we override the `header` and `actions` classes
const listStyles = theme => ({
  root: {
    display: "flex",
  },
  card: {
    position: "relative",
    flex: "1 1 auto",
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "flex-start",
  },
  noResults: { padding: 20 },
})

const PostBulkActionButtons = props => <ArchiveButton {...props} />

export const PostList = props => <List {...props}></List>

export const ConsultList = withStyles(listStyles)(
  ({ permissions, classes, ...props }) => (
    <List
      classes={classes}
      {...props}
      title={<ConsultTitle />}
      filter={{ doctorId: localStorage.getItem("userId") }}
      filters={<DoctorFilter permissions={permissions} />}
      pagination={<Pagination {...props} />}
      bulkActionButtons={<PostBulkActionButtons />}
      filterDefaultValues={{ archived: null, status: "pending" }}
    >
      <Responsive
        small={
          <SimpleList
            primaryText={record => record.status}
            secondaryText={record => record.source}
            tertiaryText={record =>
              new Date(record.createdAt).toLocaleDateString()
            }
            linkType="show"
          />
        }
        medium={
          <Datagrid>
            <TextField source="status" label="Status" />
            <TextField source="source" label="Consult Source" />
            <DateField
              source="createdAt"
              label="Created At"
              showTime
              locales="en-US"
            />
            <BooleanField source="archived" />
            <ShowButton className="consults__showbtn" />
          </Datagrid>
        }
      />
    </List>
  )
)
