import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const mutationCreatePdf = gql`
  mutation createPdfExport(
    $consultId: String
    $patientname: String
    $age: Int
    $gender: String
    $patientDob: String
    $createdAt: String
    $url: String
    $medical_issues: String
    $allergy: String
    $surgeries: String
    $diseaseGroup: String
    $diagnosisDescription: String
    $diseaseTypes: [DiseaseTypeInput]
    $sideEffects: String
    $currentMedicalIssue: String
    $sideEffectsString: String
    $impressionDescription: String
    $skinCareInstructions: String
    $treatmentDescription: String
    $url1: String
    $url2: String
    $url3: String
    $url4: String
    $medication: String
    $impression: String
    $impressionDescription: String
    $plan: String
    $skinCareInstructions: String
    $consultDiagnosisData: [ConsultDiagnosisDataInput]
    $phone_number: String
    $patientAddress: String
    $doctorName: String
  ) {
    createPdfExport(
      input: {
        consultId: $consultId
        patientname: $patientname
        age: $age
        gender: $gender
        patientDob: $patientDob
        createdAt: $createdAt
        url: $url
        medical_issues: $medical_issues
        allergy: $allergy
        surgeries: $surgeries
        diseaseGroup: $diseaseGroup
        diagnosisDescription: $diagnosisDescription
        diseaseTypes: $diseaseTypes
        sideEffects: $sideEffects
        treatmentDescription: $treatmentDescription
        url1: $url1
        url2: $url2
        url3: $url3
        url4: $url4
        medication: $medication
        impression: $impression
        impressionDescription: $impressionDescription
        plan: $plan
        currentMedicalIssue: $currentMedicalIssue
        skinCareInstructions: $skinCareInstructions
        impressionDescription: $impressionDescription
        sideEffectsString: $sideEffectsString
        consultDiagnosisData: $consultDiagnosisData
        phone_number: $phone_number
        patientAddress: $patientAddress
        doctorName: $doctorName
      }
    ) {
      id
    }
  }
`

const createPdf = async data => {
  const variables = data

  const pdf = await client.hydrated().then(function (cl) {
    const mutation = cl
      .mutate({
        mutation: mutationCreatePdf,
        variables,
        fetchPolicy: "no-cache",
      })
      .then(result => {
        // console.log(result);
        return result
      })
      .catch(err => {
        // console.log("error ", err)
      })
    return mutation
  })
  return pdf
}
export default createPdf
