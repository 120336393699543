import React from "react"
import compose from "recompose/compose"
import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CommentIcon from "@material-ui/icons/Comment"
import Divider from "@material-ui/core/Divider"
import { Link } from "react-router-dom"
import { translate } from "react-admin"
import formatDistance from "date-fns/formatDistance"
import CardIcon from "./CardIcon"

const styles = theme => ({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
  },
  value: {
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
})

const Consults = ({ consults = [], consultStatus, classes, children }) => (
  <div className={classes.main}>
    <CardIcon Icon={CommentIcon} bgColor="#3fcff3" />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        {consultStatus} Consults
      </Typography>
      {/* <Typography className={classes.title} color="textSecondary" variant="h3">
        (Today)
      </Typography> */}
      <Typography variant="headline" component="h2" className={classes.value}>
        {" "}
        {consults.length}
      </Typography>
      <Divider />
      <List style={{ maxHeight: "50vh", overflow: "auto" }}>
        {consults.map(consult => {
          const timeElapsed = formatDistance(
            new Date(consult.createdAt),
            new Date(),
            {
              includeSeconds: true,
              addSuffix: true,
            }
          )

          return (
            <ListItem
              className={classes.listItem}
              key={consult.id}
              button
              component={Link}
              to={`/Consult/${consult.id}/show`}
            >
              {/* <Avatar>
                                <ImageIcon />
                            </Avatar> */}
              <ListItemText
                primary={consult.patientname}
                secondary={timeElapsed}
                className={classes.listItemText}
              />
            </ListItem>
          )
        })}
      </List>
      {children}
    </Card>
  </div>
)

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(Consults)
