import React from "react"

import {
  TemplateList,
  AdminTemplateList,
  TemplateEdit,
  TemplateCreate,
} from "./templates"

import {
  ChatResponseList,
  AdminChatResponseList,
  ChatResponseEdit,
  ChatResponseCreate,
} from "./chatResponses"

import {
  PracticeConsultList,
  FullConsultList,
  ConsultShow,
  ConsultCreate,
  ConsultList,
} from "./consults/consultsIndex"

import { DoctorList, DoctorEdit, DoctorCreate } from "./doctors/doctorsIndex"
import { PracticeList, PracticeEdit, PracticeCreate } from "./practices"
import { VideoCallCreate, VideoCallEdit, VideoCallList, VideoCallPage } from "./videoCall/VideoCall"
import { AvailabilityCreate, AvailabilityEdit, AvailabilityList } from "./availability/Availability"
import { CreateAdmin, EditAdmin, ListAdmin } from "./admin/Admin"
import { CreatePracticeUser, EditPracticeUser, ListPracticeUser } from "./practiceUser/PracticeUser"

import ChatBotEdit from "./chatBot"
import ChatIcon from "@material-ui/icons/Chat"
import TemplateIcon from "@material-ui/icons/ChromeReaderMode"
import ConsultIcon from "@material-ui/icons/Assignment"
import DoctorIcon from "@material-ui/icons/PermIdentity"
import PracticeIcon from "@material-ui/icons/LocalHospital"
import VideoCallIcon from "@material-ui/icons/Videocam"
import ScheduleIcon from '@material-ui/icons/WatchLater'
import AdminIcon from '@material-ui/icons/SupervisorAccount'
import PracticeUserIcon from '@material-ui/icons/People'

import { Resource } from "react-admin"

export function resourceList(role, showConsult, isDeactivated) {
  // console.log("role ", role)
  if (role === "admin") {
    const consult = (
      <Resource
        name="Consult"
        list={FullConsultList}
        show={ConsultShow}
        icon={ConsultIcon}
      />
    )
    // const adminChatBotCustomizations = <Resource name="AdminChatBotCustomizations" edit={ChatBotEdit} list={ChatBotList} options={{label: "ChatBotSettings"}} />
    const adminChatBotCustomizations = (
      <Resource name="AdminChatBotCustomizations" edit={ChatBotEdit} />
    )
    return [
      adminChatBotCustomizations,
      consult,
      <Resource
        name="Practice"
        options={{ label: "Practices" }}
        list={PracticeList}
        create={PracticeCreate}
        edit={PracticeEdit}
        icon={PracticeIcon}
        id="practice"
      />,
      <Resource
        name="Admin"
        options={{ label: "Practice Admins" }}
        list={ListAdmin}
        create={CreateAdmin}
        edit={EditAdmin}
        icon={AdminIcon}
      />,
      <Resource
        name="Doctor"
        options={{ label: "Doctors", showConsult: showConsult }}
        list={DoctorList}
        create={DoctorCreate}
        edit={DoctorEdit}
        icon={DoctorIcon}
      />,
      <Resource
        name="PracticeUser"
        options={{ label: "Practice Users" }}
        list={ListPracticeUser}
        // show={ConsultShow}
        // create={CreatePracticeUser}
        // edit={EditPracticeUser}
        icon={PracticeUserIcon}
      />,
      <Resource
        name="Template"
        options={{ label: "Templates" }}
        list={AdminTemplateList}
        create={TemplateCreate}
        edit={TemplateEdit}
        icon={TemplateIcon}
      />,
      <Resource
        name="ChatResponse"
        options={{ label: "Chat Responses" }}
        list={AdminChatResponseList}
        create={ChatResponseCreate}
        edit={ChatResponseEdit}
        icon={ChatIcon}
      />,
    ]
  } else if (role === "doctor") {
    const consult = (
      <Resource
        name="Consult"
        options={{ label: "All Consults" }}
        list={ConsultList}
        show={ConsultShow}
        icon={ConsultIcon}
        create={ConsultCreate}
      />
    )

    if (showConsult || isDeactivated) {
      // CHECK HERE CAREFULLY
      return [
        consult,
        <Resource
          name="Doctor"
          options={{ label: "Doctors", showConsult: showConsult }}
          edit={DoctorEdit}
        />,
        <Resource
          name="Practice"
          options={{ label: "Practices" }}
          edit={PracticeEdit}
          id="practice"
        />,
        <Resource
          name="Template"
          options={{ label: "Templates" }}
          list={TemplateList}
          create={TemplateCreate}
          edit={TemplateEdit}
          icon={TemplateIcon}
        />,
        <Resource
          name="ChatResponse"
          options={{ label: "Chat Responses" }}
          list={ChatResponseList}
          create={ChatResponseCreate}
          edit={ChatResponseEdit}
          icon={ChatIcon}
        />,
        <Resource
          name="VideoCall"
          options={{ label: "Video Calls" }}
          list={VideoCallList}
          create={VideoCallCreate}
          edit={VideoCallEdit}
          icon={VideoCallIcon}
          show={VideoCallPage}
        />,
        <Resource
          name="Availability"
          options={{ label: "Availability" }}
          list={AvailabilityList}
          create={AvailabilityCreate}
          edit={AvailabilityEdit}
          icon={ScheduleIcon}
        />,
      ]
    }

    return [
      <Resource
        name="Doctor"
        options={{ label: "Doctors", showConsult: showConsult }}
        edit={DoctorEdit}
      />,
      <Resource
        name="Practice"
        options={{ label: "Practices" }}
        edit={PracticeEdit}
        id="practice"
      />,
      //  <Resource
      //  name="User" options={{label: "PracticeUsers"}}
      //  list={PracticeUserList}
      //  create={PracticeUserCreate} edit={PracticeUserEdit}  />,
      <Resource
        name="Template"
        options={{ label: "Templates" }}
        list={TemplateList}
        create={TemplateCreate}
        edit={TemplateEdit}
        icon={TemplateIcon}
      />,
      <Resource
        name="ChatResponse"
        options={{ label: "Chat Responses" }}
        list={ChatResponseList}
        create={ChatResponseCreate}
        edit={ChatResponseEdit}
        icon={ChatIcon}
      />,
    ]
  } else if(role === "practiceAdmin"){
    const consult = (
      <Resource
        name="Consult"
        list={PracticeConsultList}
        show={ConsultShow}
        icon={ConsultIcon}
      />
    )
    return [
      consult,
      <Resource
        name="PracticeUser"
        options={{ label: "Practice Users" }}
        list={ListPracticeUser}
        create={CreatePracticeUser}
        edit={EditPracticeUser}
        icon={PracticeUserIcon}
      />,
      <Resource
        name="Doctor"
        options={{ label: "Doctors", showConsult: showConsult }}
        list={DoctorList}
        create={DoctorCreate}
        edit={DoctorEdit}
        icon={DoctorIcon}
      />,
      <Resource
        name="Practice"
        options={{ label: "Practices" }}
        // list={PracticeList}
        // create={PracticeCreate}
        edit={PracticeEdit}
        icon={PracticeIcon}
        id="practice"
      />,
      <Resource
        name="Admin"
        options={{ label: "Practice Admins" }}
        // list={ListAdmin}
        // create={CreateAdmin}
        edit={EditAdmin}
        icon={AdminIcon}
      />
    ];
  } else if(role === "nurse" || role === "staff"){
    const consult = (
      <Resource
        name="Consult"
        list={PracticeConsultList}
        show={ConsultShow}
        icon={ConsultIcon}
      />
    )
    return [
      consult,
      <Resource
        name="PracticeUser"
        options={{ label: "Practice Users" }}
        // list={ListPracticeUser}
        // create={CreatePracticeUser}
        edit={EditPracticeUser}
        icon={PracticeUserIcon}
      />
      // <Resource
      //   name="Doctor"
      //   options={{ label: "Doctors", showConsult: showConsult }}
      //   list={DoctorList}
      //   create={DoctorCreate}
      //   edit={DoctorEdit}
      //   icon={DoctorIcon}
      // />
    ];
  } else {
    return []
  }
}
