import React, { Component, Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Typography, Switch } from "@material-ui/core"
import { BooleanInput, TextInput } from "react-admin"
import fetchPractice from "../queries/fetchPractice"

const styles = theme => ({
  typography: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
})

class DoctorEnableSwitch extends Component {
  state = {
    label: "",
    practiceStripeId: null
  }

  componentDidMount = () => {
    const { record } = this.props
    
    if(record && record.practiceId){
      // console.log("record ",record)
      fetchPractice(record.practiceId).then((res)=>{
        const {data: { Practice }} = res;
        // console.log(Practice)
        if(Practice && Practice.stripeId){
          // console.log(Practice.stripeId)
          this.setState({practiceStripeId: Practice.stripeId});
        }
      }).catch(console.log);
    }

    if (record.enabled) {
      return this.setState({
        label: "Enabled",
      })
    }

    this.setState({
      label: "Disabled",
    })
  }

  onChange = e => {
    if (e.target.checked) {
      return this.setState({
        label: "Enabled",
      })
    }

    this.setState({
      label: "Disabled",
    })
  }

  render() {
    const { classes, record } = this.props
    const { label, practiceStripeId } = this.state
    return (record.stripeId || practiceStripeId) ? (
      <BooleanInput onChange={this.onChange} label={label} source="enabled" />
    ) : (
      <Fragment>
        <div>
          <Switch disabled />
          <span>Disabled</span>
        </div>
        <Typography
          variant="button"
          color="primary"
          className={classes.typography}
        >
          <strong>NOTE: </strong>Doctors can be enabled only when all their
          required details are present.
        </Typography>
      </Fragment>
    )
  }
}

DoctorEnableSwitch.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DoctorEnableSwitch)
