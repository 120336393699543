import React, { useState, useEffect, Fragment } from "react"
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Link,
  TextInput,
  Filter,
  DateField,
  BooleanField,
  ShowButton,
  showNotification,
  CardActions, 
  RefreshButton
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import Pagination from "../Pagination"
import { ListItem, List as MaterialList, Paper } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import gql from "graphql-tag"
import Appsync from "../util/client";
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import setInviteStatus from '../queries/updateInviteStatus';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Typography from "@material-ui/core/Typography"
import getCurrentTimezoneName from "../util/getCurrentTimezoneName"


const client = Appsync()

const ActionsRefresh = props => (
  <CardActions>
      <RefreshButton />
  </CardActions>
);

export const DoctorFilter = props => {
  // console.log("doctor filter", props);
  return (
    <Filter {...props}>
      <TextInput label="Status" source="callStatus" />
      <TextInput label="Date" source="scheduledDateTime" />
    </Filter>
  )
}

const filterFunc = () => {
  return {
    doctorId: localStorage.getItem("userId"),
  }
}

export const CustomDateField = (props)=>{
  return(
    <div style={{paddingTop: 10, paddingBottom: 10}}>
      <p>{props.record && moment(props.record.scheduledDateTime).format("LLL")}</p>
      <label style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 12, fontWeight: "100"}}>{getCurrentTimezoneName()}</label>
    </div>
  )
}

export const VideoCallList = props => {
  // console.log("DoctorList ", props);

  

  const [data, setData] = useState(null);

  // console.log(props, "props")
  // console.log(data, "data")
  // const notCompletedList = todayCallList ? todayCallList.find(call => call.callStatus !== "completed") : null;
  // console.log(notCompletedList, "notCompleted")
  return (
    <Fragment>
    

      <div style={{ paddingTop: '50px' }}>
        <Typography><h3 style={{ color: "#3fcff3", paddingBottom: 2 }}>All video calls</h3></Typography>
        <List
          {...props}
          title={
            <Link to="/">
              <img
                className="dashboard__img-logo"
                src={logo}
                alt="dermio-logo"
                style={{ paddingTop: "0.3em" }}
              />
            </Link>
          }
          filters={<DoctorFilter />}
          filter={filterFunc()}
          pagination={<Pagination {...props} />}
          bulkActionButtons={false}
          actions={<ActionsRefresh />}
        >
          <Datagrid>
            <CustomDateField source="scheduledDateTime" label={"Video call Date & Time"} showTime />
            <TextField source="consultName" />
            <TextField source="inviteStatus" />
            <TextField source="callStatus" />
            {/* <EditButton /> */}
            <ShowButton source="More" />
          </Datagrid>
        </List>
      </div>
    </Fragment>
  )
}

