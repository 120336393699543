import React, { Fragment } from "react"
import "./css/chat.css"
import classNames from "classnames"
import "font-awesome/css/font-awesome.min.css"
import avatar from "./images/stock-avatar.png"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import fetchChatResponses from "../queries/fetchChatResponses"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import formatDistance from "date-fns/formatDistance"
import AwsImageComponent from "../Components/AwsImageComponent"
import AwsFileComponent from "../Components/AwsFileComponent"
import { Responsive } from "react-admin/lib"
import Storage from "@aws-amplify/storage"

import LinearProgress from "@material-ui/core/LinearProgress"
import Snackbar from "@material-ui/core/Snackbar"

import InsertPhotoIcon from "@material-ui/icons/InsertPhoto"
import AttachFileIcon from "@material-ui/icons/AttachFile"

import FileUpload from "../Components/FileUpload/FileUpload"
import DocumentUpload from "../Components/FileUpload/DocumentUpload"
import moment from "moment"

import { createChatImgMsgMutation } from "../queries/createChatMutation"

Storage.configure({
  bucket: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_BUCKET_REGION,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
})

export default withStyles(theme => ({
  chat: {
    display: "flex",
    flexFlow: "column nowrap",
    overflow: "hidden",
    flex: "1 1 50%",
    minWidth: "25rem",
    backgroundColor: "#f6f3f3 !important",

    // [theme.breakpoints.down("sm")]: {
    //   margin: "0 2rem 3rem 2rem",
    // },
  },
}))(
  class Messenger extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        items: [],
        height: window.innerHeight,
        value: "",
        selected: "1",
        text: "",
        chatMessage: "",
        url:
          "https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg",
        imgUrl: "",
        fileUrl: "",
        isUploadingImg: false,
        isFileUploadOpen: false,
        isImageUploadOpen: false,
        showChatMessage: true,
        messages: [],
        showError: false,
      }
      // this.handleClick=this.handleClick.bind(this);
    }

    componentWillReceiveProps = props => {
      const { chatMsgs } = props
      if (chatMsgs && chatMsgs.length) {
        this.setState({ messages: chatMsgs })
      }
    }

    componentDidMount = async () => {
      // console.log("mounted ");
      // To enable auto-scroll, anytime a message is sent.
      const observer = new MutationObserver(this.scrollToBottom)
      const config = { attributes: true, childList: true }
      observer.observe(this.chatMessagesContainer, config)

      // Auto scroll during initial loading.
      // this.chatMessagesContainer.scrollTop = this.chatMessagesContainer.scrollHeight;

      const doctorId = localStorage.getItem("userId")

      try {
        const response = await fetchChatResponses(doctorId)
        // console.log(response);
        // console.log(response.data.allChatResponses.items);
        const items = response.data.allChatResponses.items.concat(
          response.data.allAdminChatResponses.items
        )

        this.setState({
          items,
        })
      } catch (e) {
        console.error(e)
      }

      // if (localStorage.getItem('doctorProfPic')) {//Important to check tomorrow
      //   // console.log("localStorage.getItem('doctorProfPic')  ",localStorage.getItem('doctorProfPic') );
      //   this.setState({ url: localStorage.getItem('doctorProfPic') });
      // } else {
      let docDetails = localStorage.getItem("doctorDetails")

      let docProfilePicStr =
        JSON.parse(docDetails) && JSON.parse(JSON.parse(docDetails).file)
      // let docProfilePicUrl = 'https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg'

      if (docProfilePicStr && docProfilePicStr.s3 && docProfilePicStr.s3.key) {
        // console.log('\n\nDOC\n\n', docProfilePicStr.s3.key.split('/')[1])
        Storage.get(docProfilePicStr.s3.key.split("/")[1], { expires: 3600 })
          .then(result => {
            localStorage.setItem("doctorProfPic", result)
            this.setState({ url: result })
          })
          .catch(err => console.log(err))
      }
      // }
      window.addEventListener("resize", this._onResize)
    }

    scrollToBottom = () => {
      // console.log("scrolledToBottom");
      this.chatMessagesContainer.scrollTop = this.chatMessagesContainer.scrollHeight
    }

    componentWillUnMount() {
      window.removeEventListener("resize", this._onResize)
    }

    handleMessageInput(e) {
      const { value: chatMessage } = e.target

      this.setState({
        chatMessage,
      })
    }

    handleChange = event => {
      const value = event.target.value
      this.setState({
        text: value,
      })
    }

    handleClick = event => {
      const value = event.target.value
      const template = this.state.items.filter(i => i.id === value)[0]
      this.setState({
        chatMessage: template.body,
        selected: value,
      })
    }

    getAwsImageFileComponent = message => {
      const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(message.url)
      const imageExtensions = [".jpg", ".gif", ".png", ".jpeg", "tiff"]
      const isImage = imageExtensions.includes(`.${extension}`)
      if (isImage) {
        return (
          <AwsImageComponent
            src={message.url}
            componentFrom="chat"
            onClick={url => {
              this.setState({ imgUrl: url })
            }}
          />
        )
      } else {
        return (
          <AwsFileComponent
            src={message}
            extension={extension}
            componentFrom="chat"
            onClick={url => {
              this.setState({ fileUrl: url })
            }}
          />
        )
      }
    }

    handleFileUploadOpen = () => {
      this.setState({
        chatMessage: "",
        isFileUploadOpen: true,
        isImageUploadOpen: false,
        errorMsg: "",
        showChatMessage: false,
      })
    }

    handleFileUploadClose = () => {
      this.setState(
        {
          chatMessage: "",
          isFileUploadOpen: false,
          errorMsg: "",
          showChatMessage: true,
        },
        () => this.scrollToBottom()
      )
    }

    handleImageUploadOpen = () => {
      this.setState({
        chatMessage: "",
        isImageUploadOpen: true,
        isFileUploadOpen: false,
        errorMsg: "",
        showChatMessage: false,
      })
    }

    handleImageUploadClose = () => {
      this.setState(
        {
          chatMessage: "",
          isImageUploadOpen: false,
          errorMsg: "",
          showChatMessage: true,
        },
        () => this.scrollToBottom()
      )
    }

    sendChatImage = async pics => {
      // console.log("files ",pics)
      const { id, doctorId, patientname } = this.props.consultRecord
      const { messages } = this.state
      // console.log(this.props)

      this.setState(
        {
          isUploadingImg: true,
          isImageUploadOpen: false,
          isFileUploadOpen: false,
          showChatMessage: true,
          errorMsg: "",
        },
        () => this.scrollToBottom()
      )

      try {
        const res = await createChatImgMsgMutation(
          pics,
          id,
          doctorId,
          patientname
        )

        const updatedMessages = [...messages, res.data.createChat]

        this.setState(
          {
            chatMessage: "",
            messages: updatedMessages,
            isImageUploadOpen: false,
            isUploadingImg: false,
            showChatMessage: true,
            errorMsg: "",
            showError: false,
          },
          () => this.scrollToBottom()
        )
      } catch (e) {
        console.error(e) // Todo: Display error message to the user
        this.setState(
          {
            isUploadingImg: false,
            showChatMessage: true,
            showError: true,
            errorMsg:
              "Sorry! Something went wrong while uploading your image. Please try again.",
            sendButton: false,
          },
          () => this.scrollToBottom()
        )
      }
    }

    render() {
      const { messages, height } = this.state

      let {
        chatMessage,
        isUploadingImg,
        isImageUploadOpen,
        isFileUploadOpen,
        showChatMessage,
        showError,
        errorMsg,
      } = this.state

      const {
        consultRecord,
        chatMsgs,
        sendChatMessage,
        permissions,
        classes,
        isSendingText,
      } = this.props

      const sortedChatMsgs = messages.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt)
      })

      let isConsultClosed = false
      if (consultRecord && consultRecord.status) {
        console.log(consultRecord)
        isConsultClosed =
          consultRecord.status === "closed_unpaid" ||
          consultRecord.status === "closed_paid"
            ? true
            : false
      }

      let isMoreThan14Days = false
      if (isConsultClosed && consultRecord.createdAt) {
        const actualChatCloseDateUnix = moment(consultRecord.createdAt)
          .add(14, "days")
          .unix()
        const todayDateUnix = moment().unix()
        console.log("todayDateUnix ", todayDateUnix)
        console.log("actualChatCloseDateUnix ", actualChatCloseDateUnix)
        if (todayDateUnix > actualChatCloseDateUnix) {
          isMoreThan14Days = true
        }
      }

      // console.log(sortedChatMsgs)
      // let sortedChatMsgs = [
      // {
      //   senderRole: "doctor",
      //   message: "Hello P",
      //   createdAt: `${new Date().getUTCDate()}`,
      // },
      // {
      //   senderRole: "patient",
      //   message: "Hello P",
      //   createdAt: `${new Date().getUTCDate()}`,
      // },
      // {
      //   senderRole: "doctor",
      //   message: "Hello P",
      //   createdAt: `${new Date().getUTCDate()}`,
      // },
      // {
      //   senderRole: "doctor",
      //   message: "Hello P",
      //   createdAt: `${new Date().getUTCDate()}`,
      // },
      // {
      //   senderRole: "patient",
      //   message: "Hello P",
      //   createdAt: `${new Date().getUTCDate()}`,
      // },
      // ]

      const style = {
        position: "relative",
      }

      return (
        <Card style={style} className={classes.chat}>
          {
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={showError}
              autoHideDuration={6000}
              onClose={() => {
                this.setState({ showError: false, errorMsg: "" })
              }}
              message={errorMsg}
            />
          }
          <Responsive
            small={<div></div>}
            medium={
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  width: "100%",
                  zIndex: 100,
                  textAlign: "center",
                }}
              >
                <h1
                  style={{
                    background: "white",
                    color: "#3fcef3",
                    marginBottom: 30,
                    fontSize: "x-large",
                    padding: "10px",
                    boxShadow: "0px 2px 6px gray",
                    // textShadow: "3px 1px 2px #d0d0d0",
                  }}
                >
                  Chatroom
                </h1>
              </div>
            }
          />

          <div
            style={{
              position: "relative",
              minHeight: "60vh",
              color: "black",
            }}
          >
            <div
              className="chat__messages"
              tabIndex={-1}
              ref={el => (this.chatMessagesContainer = el)}
            >
              {isImageUploadOpen && (
                <FileUpload
                  handleUpload={pics => this.sendChatImage(pics)}
                  goBack={() => this.handleImageUploadClose()}
                />
              )}

              {isFileUploadOpen && (
                <DocumentUpload
                  handleUpload={files => this.sendChatImage(files)}
                  goBack={() => this.handleFileUploadClose()}
                />
              )}

              {showChatMessage &&
                sortedChatMsgs.map((message, index) => {
                  let isImage = false
                  let isFile = false
                  if (message.url) {
                    const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(
                      message.url
                    )
                    isImage = [
                      ".jpg",
                      ".gif",
                      ".png",
                      ".jpeg",
                      "tiff",
                    ].includes(`.${extension}`)
                    isFile = [
                      ".txt",
                      ".pdf",
                      ".excel",
                      ".word",
                      ".csv",
                    ].includes(`.${extension}`)
                  }

                  return (
                    <div
                      key={index}
                      className={classNames(
                        "chat__message",
                        {
                          "chat__message--me":
                            message.senderRole === "doctor" ? true : false,
                        },
                        {
                          "chat__message--image":
                            message.url && !isFile ? true : false,
                        },
                        { "chat__message--doctor--send--image": isImage },
                        { "chat__message--doctor--send--file": isFile }
                      )}
                    >
                      {message.senderRole === "doctor" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            style={{
                              height: 50,
                              width: 50,
                              borderRadius: "50%",
                            }}
                            src={this.state.url}
                            alt="Doctor"
                          />
                        </div>
                      ) : null}

                      <div className="chat__message-body">
                        {message.message ? (
                          <div>
                            <p className="chat__message-text">
                              {message.message}
                            </p>
                            <p className="chat__message-time">{`\n${formatDistance(
                              new Date(message.createdAt),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}`}</p>
                          </div>
                        ) : (
                          this.getAwsImageFileComponent(message)
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
            {showChatMessage && permissions === "doctor" ? (
              !isUploadingImg && !isMoreThan14Days ? (
                <div className="chat__actions">
                  <textarea
                    className="chat__message-input"
                    value={this.state.chatMessage}
                    onChange={e => this.handleMessageInput(e)}
                    placeholder={
                      isSendingText
                        ? "please wait, sending message..."
                        : "Write your message..."
                    }
                    rows="2"
                    disabled={isSendingText}
                  />
                  <div className="chat__template-select">
                    <Select
                      value={this.state.selected}
                      onChange={this.handleClick}
                      style={{ width: "90px" }}
                    >
                      <MenuItem value="1" disabled="true">
                        Select
                      </MenuItem>
                      {this.state.items.map(i => (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div>
                    {!this.state.chatMessage ? (
                      <div className="chat__image-upload">
                        <span className="chat__btn chat__btn--image">
                          <InsertPhotoIcon
                            fontSize="large"
                            style={{ color: "black" }}
                            onClick={() => this.handleImageUploadOpen()}
                          />
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {!this.state.chatMessage ? (
                      <div className="chat__image-upload">
                        <span className="chat__btn chat__btn--image">
                          <AttachFileIcon
                            fontSize="large"
                            style={{ color: "black" }}
                            onClick={() => this.handleFileUploadOpen()}
                          />
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="chat__message-upload">
                    <button
                      className="chat__btn chat__btn--text"
                      onClick={e => {
                        this.setState({
                          chatMessage: "",
                        })
                        return sendChatMessage(e, chatMessage.trim())
                      }}
                      hidden={!chatMessage.length}
                    >
                      <i
                        className="fa fa-chevron-circle-right fa-5x chat__btn_inner"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              ) : (
                <Fragment>
                  {isConsultClosed ? (
                    <div
                      style={{
                        padding: 10,
                        backgroundColor: "#ffffff",
                        borderTop: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                    >
                      The consult is closed.
                    </div>
                  ) : (
                    <div>
                      Uploading file. Please wait...
                      <LinearProgress
                        classes={{
                          colorPrimary: classes.linearColorPrimary,
                          barColorPrimary: classes.linearBarColorPrimary,
                        }}
                      />
                    </div>
                  )}
                </Fragment>
              )
            ) : null}
            <div
              style={{
                height: "100%",
                width: this.state.imgUrl ? "100%" : "0%",
                position: "absolute",
                // top:0,
                bottom: 0,
                left: 0,
                zIndex: 101,
                backgroundColor: "#2a2a2a",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  right: 15,
                  top: 15,
                  color: "white",
                  fontSize: 25,
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.setState({
                    imgUrl: "",
                  })
                }
              >
                X
              </span>
              <img
                src={this.state.imgUrl}
                style={{
                  maxHeight: "90%",
                  maxWidth: "100%",
                }}
              />
            </div>

            <div
              style={{
                height: "100%",
                width: this.state.fileUrl ? "100%" : "0%",
                position: "absolute",
                // top:0,
                bottom: 0,
                left: 0,
                zIndex: 101,
                backgroundColor: "#2a2a2a",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  position: "absolute",
                  right: 15,
                  top: 15,
                  color: "white",
                  fontSize: 25,
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.setState({
                    fileUrl: "",
                  })
                }
              >
                X
              </span>
              <iframe
                src={this.state.fileUrl}
                frameborder="0"
                style={{ width: "100%", height: "100%", paddingTop: 50 }}
              />
            </div>
          </div>
        </Card>
      )
    }
  }
)
