import Appsync from "../util/client"
import gql from "graphql-tag"

const client = Appsync()

const query = gql`
query allVideoCalls(
  $doctorId: String,
  $consultId: ID
){
  allVideoCalls(
    doctorId: $doctorId,
    filter:{
      consultId:{
        eq: $consultId
      }
    }
  ){
    items{
      id 
      doctorId
      patientId
      consultId
      scheduledDateString
      callStatus
      createdAt
      scheduledDateTime
      doctorName
      consultName
      startTime
      endTime
      duration
      lastActive
    }
  }
}
`

const fetchVideoCallDetails = async (docId, conId) => {
  // console.log(videocallId)
  const variables = { consultId: conId, doctorId: docId }

  const videoCallDetails = await client.hydrated().then(function (cl) {
    return cl.query({ query, variables, fetchPolicy: "network-only" })
  })

  return videoCallDetails
}

export default fetchVideoCallDetails