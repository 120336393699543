import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync();

const updateInviteStatus = gql`
  mutation updateVideoCall($id: ID!, $inviteStatus: String, $consultId: ID!, $consultStatus: String){
    updateVideoCall(
      id: $id,
      inviteStatus: $inviteStatus
    ){
      id
      inviteStatus
      callStatus
    }

    updateConsult(
      id: $consultId,
      status: $consultStatus
    ){
      id
    }
  }
`

const setInviteStatus = async (id, value, consultId) => {
  console.log(value, id, "===")
  const variables = {
    id: id,
    inviteStatus: value,
    consultStatus: value === "accepted" ? "accepted" : "declined",
    consultId
  }
  const updateStatus = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: updateInviteStatus,
      variables: variables,
      fetchPolicy: "no-cache",
    })
    return mutation
  })

  return updateStatus
}

export default setInviteStatus;