import Amplify, { Auth } from "aws-amplify"
import Appsync from "../../../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query allPendingConsults($id: ID!) {
    allPendingConsultsNearDocResult: allConsults(
      status: { eq: "pending" }
      filter: { source: { contains: "dermio.com" } }
    ) {
      items {
        id
        status
        source
        patientId
        doctorId
        patientname
        state
        createdAt
        cardToken
        isVideoCallConsult
        practiceId
        insuranceFile1 {
          key
        }
      }
    }
    allPendingDoctorConsultsResult: allDoctorConsults(
      status: "pending"
      filter: {}
      doctorId: $id
    ) {
      items {
        id
        status
        patientId
        doctorId
        patientname
        state
        createdAt
        cardToken
        isVideoCallConsult
        practiceId
        insuranceFile1 {
          key
        }
      }
    }
  }
`

const fetchPendingConsults = async doctorId => {
  //   console.log(doctorId)
  const variables = { id: doctorId }
  //   console.log("variables ", variables)
  const doctorDetails = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "network-only" })
  })

  return doctorDetails
}

export default fetchPendingConsults
