import React from "react"

export default class Confirm extends React.Component {
  confirm = () => {
    this.props.addDoctor(this.props.data)
  }
  render() {
    return (
      <div>
        <h2>Please Confirm entered details</h2>
        <ul>
          {Object.keys(this.props.data).map(key => {
            return <li>{`${key} : ${this.props.data[key]}`}</li>
          })}
        </ul>
        <button className="btn -primary pull-right" onClick={this.confirm}>
          Confirm
        </button>

        <button
          className="btn -primary pull-left"
          onClick={this.props.previousStep}
        >
          back
        </button>
      </div>
    )
  }
}
