import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query fetchTemplates($doctorId: ID!) {
    allTemplates(filter: { doctorId: { eq: $doctorId } }) {
      items {
        id
        createdBy
        plan
        impression
        impressionDescription
        sideEffectsString
        skinCareInstructions
      }
    }
    allAdminTemplates: allTemplates(filter: { createdBy: { eq: "admin" } }) {
      items {
        id
        createdBy
        plan
        impression
        impressionDescription
        sideEffectsString
        skinCareInstructions
      }
    }
  }
`

const fetchTemplate = async doctorId => {
  const variables = { doctorId }

  const template = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables, fetchPolicy: "network-only" })
  })

  return template
}

export default fetchTemplate
