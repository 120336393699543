import React, { Component } from "react"
import { addField } from "react-admin"

class ConstructDoctorName extends Component {
  componentDidUpdate = () => {
    // console.log('sahasjnkadnfan', this.props);
    const name = `${this.props.formData.firstName} ${this.props.formData.lastName}`
    this.props.input.onChange(name)
  }

  render() {
    return null
  }
}

export default addField(ConstructDoctorName)
