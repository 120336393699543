import React from "react"
import states from "../staticData/USstates"
export default class PracticeInfo extends React.Component {
  nextStep = e => {
    e.preventDefault()
    this.props.nextStep()
  }

  // renderOptions = (type, name, value, index) =>{
  //     // const isChecked = () => {
  //     //     if (type == 'radio') return value == this.props.fieldValues[name];
  //     //     if (type == 'checkbox') return this.props.fieldValues[name].indexOf(value) >= 0
  //     //         return false;
  //     // }
  //     return (
  //         <label key={index}>
  //           <input type={type} name={name} value={value} /> {value}
  //         </label>
  //       )
  // }

  render() {
    return (
      <div>
        <h2>Practice Information</h2>
        <ul className="form-fields">
          <li>
            <label>Practice Name</label>
            <input
              type="text"
              ref="PracticeName"
              defaultValue={this.props.data["PracticeName"]}
              onChange={event => {
                this.props.fieldUpdate(event, "PracticeName")
              }}
            />
          </li>
          <li>
            <label>Street</label>
            <input
              type="text"
              ref="Street"
              defaultValue={this.props.data["Street"]}
              onChange={event => {
                this.props.fieldUpdate(event, "Street")
              }}
            />
          </li>
          <li>
            <label>City</label>
            <input
              type="text"
              ref="City"
              defaultValue={this.props.data["City"]}
              onChange={event => {
                this.props.fieldUpdate(event, "City")
              }}
            />
          </li>
          <select
            onChange={e => {
              this.props.fieldUpdate(e, "ResidentState")
              //console.log(this.props.data);
            }}
          >
            <option value={null}>select one</option>
            {states.map((state, key) => {
              return <option key={key}>{state}</option>
            })}
          </select>
          <li>
            <label>Zip Code</label>
            <input
              type="text"
              ref="ZipCode"
              defaultValue={this.props.data["ZipCode"]}
              onChange={event => {
                this.props.fieldUpdate(event, "ZipCode")
              }}
            />
          </li>
          <li>
            <label>Fax</label>
            <input
              type="text"
              ref="Fax"
              defaultValue={this.props.data["Fax"]}
              onChange={event => {
                this.props.fieldUpdate(event, "Fax")
              }}
            />
          </li>
          <li>
            <label>Phone</label>
            <input
              type="text"
              ref="Phone"
              defaultValue={this.props.data["Phone"]}
              onChange={event => {
                this.props.fieldUpdate(event, "Phone")
              }}
            />
          </li>
          <li className="form-footer">
            <button className="btn -primary pull-right" onClick={this.nextStep}>
              Save &amp; Continue
            </button>
            {"\t"}
            <button
              className="btn -primary pull-left"
              onClick={this.props.previousStep}
            >
              back
            </button>
          </li>
        </ul>
      </div>
    )
  }
}
