import React from "react"
import {
  Filter,
  List,
  Datagrid,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  ImageField,
  ImageInput,
  SelectArrayInput,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  DisabledInput,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  NumberInput,
  DateInput,
  FormDataConsumer,
  CardActions,
  RefreshButton,
  Link,
  SelectInput,
} from "react-admin"
import logo from "./Images/dermio-logo.svg"
import { ColorInput } from "react-admin-color-input"
import CustomEditToolbar from "./Components/CustomEditToolbar"
import DermioImage from "./Components/PracticeImage"
import BotAvatar from "./Components/BotAvatar"
import UserAvatar from "./Components/UserAvatar"
import welcomeMsgImg from "./Images/Customized_welcome_message.png"
import finalMsgImg from "./Images/Customized_ending_message.png"

import {
  validateFirstName,
  validateEmail,
  validatePhone,
  validateRequiredTextField,
  validateRequiredTextFieldState,
  validateRequiredNumberField,
  validateZipCode,
  validateRequiredPhoneNumField,
  validateAltPhone,
  validateRequiredAddressField,
  validateRequiredPositiveNumberField,
} from "./util/validation"

const CustomEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    {/* <RefreshButton basePath={basePath} record={data} /> */}
  </CardActions>
)

const ChatBotEdit = props => {
  const redirect = (basePath, id, data) => {
    return `/AdminChatBotCustomizations/${data.id}`
  }
  const { permissions } = props

  return (
    <Edit
      actions={<CustomEditActions />}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      {...props}
      style={{ marginTop: "4rem" }}
    >
      <TabbedForm
        toolbar={<CustomEditToolbar />}
        submitOnEnter={false}
        redirect={redirect}
      >
        <FormTab label="Dermio Info">
          <TextInput source="name" />
          <TextInput source="email" type="email" validate={validateEmail} />
          <TextInput source="phone" validate={validateRequiredPhoneNumField} />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Note: Must be 10 digits only.
          </p>
          <TextInput source="website" validate={validateRequiredTextField} />
          <TextInput
            source="address"
            validate={validateRequiredAddressField}
            label="Address Line"
          />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Ex: 9200 Calumet Avenue, Suite N203
          </p>
          <TextInput source="city" validate={validateRequiredTextField} />
          <TextInput source="state" validate={validateRequiredTextFieldState} />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Note: State should only be written in it's shortest form. Eg: New
            York as NY
          </p>
          <TextInput source="zip" validate={validateZipCode} />
          <TextInput
            source="altPhone"
            validate={validateAltPhone}
            defaultValue=""
          />
          <TextInput source="fax" validate={validateRequiredTextField} />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Note: Must be 10 digits. Can also contain an optional extension.
          </p>
          <NumberInput
            source="consultationFee"
            label="Consultation Fee"
            validate={validateRequiredPositiveNumberField}
          />
        </FormTab>
        <FormTab label="ChatBot Messages">
          {/* <NumberInput source="consultationFee" label="Consultation Fee" validate={validateRequiredNumberField}/> */}
          <div style={{ height: "auto", width: "100vw" }}>
            <LongTextInput
              source="chatBotWelcomeMsg"
              label="Welcome message. For example: Hello! I'm Felecia. Let's have a chat."
            />
            <h4 style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: 15 }}>
              Example image:{" "}
            </h4>
            <img
              src={welcomeMsgImg}
              style={{
                height: "400px",
                width: "400px",
                marginTop: "10px",
                boxShadow: "2px 2px 14px 1px #202420",
                borderRadius: 10,
              }}
            />
          </div>
          <div style={{ height: "auto", width: "100vw", marginTop: 25 }}>
            <LongTextInput
              source="chatBotEndingMsg"
              label="Ending message after patient completes consult details. For example: Thank you for submitting payment details."
            />
            <h4 style={{ color: "rgba(0, 0, 0, 0.54)", marginTop: 15 }}>
              Example image:{" "}
            </h4>
            <img
              src={finalMsgImg}
              style={{
                height: "400px",
                width: "400px",
                marginTop: "10px",
                boxShadow: "2px 2px 14px 1px #202420",
                borderRadius: 10,
                marginBottom: 15,
              }}
            />
          </div>
        </FormTab>
        <FormTab label="ChatBot Styling">
          <TextInput source="chatbotDisplayName" label="ChatBot Display Name" />
          <NumberInput source="width" label="ChatBot Width" />
          <SelectInput
            source="chatbotPosition"
            label="ChatBot Position"
            choices={[
              { id: "left", name: "Bottom-Left" },
              { id: "right", name: "Bottom-Right" },
            ]}
          />
          <p
            style={{
              color: "rgba(3, 3, 3, 0.74)",
              fontSize: 15,
              width: "100%",
            }}
          >
            Note: By default ChatBot's shown in the Bottom-Right side.
          </p>
          <ColorInput source="headerBgColor" label="Header Background Color" />
          <ColorInput source="headerFontColor" label="Header Font Color" />
          <NumberInput source="headerFontSize" label="Header Font Size" />
          <DermioImage permissions={permissions} />
          <TextInput source="fontFamilyUrl" label="Font Family Url " />
          <p
            style={{
              color: "rgba(3, 3, 3, 0.74)",
              fontSize: 15,
              width: "100%",
            }}
          >
            Ex: https://fonts.googleapis.com/css?family=Open+Sans
          </p>
          <TextInput source="fontFamily" label="Font Family Name" />
          <NumberInput source="bubbleFontSize" label="Font Size" />
          <ColorInput source="background" label="Body Background Color" />
          <BooleanInput source="hideBotAvatar" label="Hide Bot Avatar" />
          <BooleanInput source="hideUserAvatar" label="Hide User Avatar" />
          <BooleanInput source="hideSubmitButton" label="Hide Submit Button" />
          <ColorInput source="botBubbleColor" label="Bot Bubble Color" />
          <ColorInput source="botFontColor" label="Bot Font Color" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.hideBotAvatar && <BotAvatar {...rest} />
            }
          </FormDataConsumer>
          <ColorInput source="userBubbleColor" label="User Bubble Color" />
          <ColorInput source="userFontColor" label="User Font Color" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.hideUserAvatar && <UserAvatar {...rest} />
            }
          </FormDataConsumer>
          <ColorInput
            source="avatarBackground"
            label="Avatar Background Color"
          />
          <ColorInput source="footerFontColor" label="Footer Font Color" />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.hideSubmitButton && (
                <ColorInput
                  source="submitButtonBackground"
                  label="Submit Button Color"
                  {...rest}
                />
              )
            }
          </FormDataConsumer>
          <TextInput source="userInputMessage" label="User Input Message" />
          <ColorInput
            source="chatBotIconBackground"
            label="ChatBot Icon Color"
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default ChatBotEdit
