import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query allVideoCalls(
    $doctorId: String,
    $consultId: ID
  ){
      completed: allVideoCalls(
        doctorId: $doctorId,
        filter:{
          consultId: {eq: $consultId},
          callStatus: {eq: "completed"}
        }
      ){
        items{
          id
          scheduledDateTime
        }
      }

      pending: allVideoCalls(
        doctorId: $doctorId,
        filter:{
          consultId: {eq: $consultId},
          inviteStatus: {eq: "invited"}
        }
      ){
        items{
          id
          scheduledDateTime
        }
      }
    }
`

const fetchDoctorConsultVideoCall = async variables => {
  const videocallsDetails = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })

  return videocallsDetails
}

export default fetchDoctorConsultVideoCall
