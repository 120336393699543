import React, { Fragment } from "react"
import dermioStockLogo from "../Images/dermio-logo.svg"
import { ImageField, ImageInput } from "react-admin"
import AwsImageComponent from "./AwsImageComponent"

const PracticeImage = ({ record, permissions }) => {
  const styles = {
    height: "100px",
    width: "100px",
    display: "block",
  }

  return (
    <Fragment>
      {
        // console.log("record.file ",record.file)
      }
      {record.file ? (
        <AwsImageComponent
          style={styles}
          src={`https://${record.file.bucket}.s3.us-east-1.amazonaws.com/${record.file.key}`}
          componentFrom="practiceLogo"
          alt="practice logo"
        />
      ) : (
        <img style={styles} src={dermioStockLogo} alt="practice logo" />
      )}
      <ImageInput
        source="file"
        label={record.file ? "Update practice logo" : "Select practice logo"}
        accept="image/*"
        placeholder={<p>Drop your photo here</p>}
      >
        <ImageField source="image" title="title" />
      </ImageInput>
    </Fragment>
  )
}

export default PracticeImage
