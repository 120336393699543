import React from "react"

import { Auth } from "aws-amplify"
import { TextField as MaterialTextField } from "@material-ui/core"
import "../../../node_modules/react-dropzone-component/styles/filepicker.css"

import { Card, Button, Checkbox, FormControlLabel } from "@material-ui/core"
import "font-awesome/css/font-awesome.min.css"
import createDoctorConsult from "../../queries/createConsult"
import { fetchDoctorDetails } from "../../queries/fetchDoctorDetails"
import "../../customStyle.css"

import encryptConsult from "../../util/encryptConsult"

export const ConsultIcon = null

export class ConsultCreate extends React.Component {
  state = {
    phoneNumber: "",
    patientName: "",
    doctorName: "",
    doctorId: "",
    doctorWebsite: "",
    checked: false,
  }

  componentDidMount = async () => {
    const doctorDetails = JSON.parse(localStorage.getItem("doctorDetails"))

    if (doctorDetails) {
      this.setState({
        doctorName: doctorDetails.name,
        doctorId: doctorDetails.id,
        doctorWebsite: doctorDetails.practice && doctorDetails.practice.website,
      })
    }
    // Get doctor Details
    // Auth.currentAuthenticatedUser().then(async (user) => {
    //   if (user) {
    //     console.log("user.attributes ",user.attributes)
    //     this.setState({
    //       doctorName: user.attributes.name,
    //       doctorId: user.attributes.sub
    //     });

    //     // Get practice website
    //     try {
    //       const doctorDetails = await fetchDoctorDetails(user.attributes.sub)
    //       if (doctorDetails) {
    //         this.setState({ doctorWebsite: doctorDetails.practice.website });
    //       }
    //     } catch (e) {
    //       console.error(e);
    //     }
    //   }
    // });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  createConsult() {
    let {
      patientName,
      doctorName,
      doctorId,
      phoneNumber,
      doctorWebsite,
      checked,
    } = this.state
    let website = "dermio.com"
    function validateName(fullname) {
      let splittedName = fullname.trim().split(" ")
      const names = splittedName.filter(name => {
        if (name.length > 0) {
          return true
        }
      })

      if (names.length >= 2) {
        return true
      } else {
        return false
      }
    }

    if (doctorWebsite && checked) {
      website = doctorWebsite
    }

    if (phoneNumber && patientName && doctorId) {
      let phoneExtension = "+1"
      phoneNumber = phoneNumber.match(/[0-9]/gi).join("")
      if (
        phoneNumber &&
        validateName(patientName) &&
        (phoneNumber.length === 10 && Number(phoneNumber[0]))
      ) {
        Auth.signUp({
          username: `${phoneExtension}${phoneNumber}`,
          password: "mD@19911",
          attributes: {
            name: patientName,
            phone_number: `${phoneExtension}${phoneNumber}`,
          },
        })
          .then(async data => {
            const encryptedResult = await encryptConsult({
              patientname: patientName,
              phone_number: phoneNumber,
            })
            createDoctorConsult({
              patientName: encryptedResult.patientname,
              encryptedPhoneNumber: encryptedResult.phone_number,
              phoneNumber: phoneNumber,
              patientId: data.userSub,
              doctorName: doctorName,
              doctorId: doctorId,
              source: website,
              status: "pending",
            }).then(() => {
              window.alert("Successfully created a consult!")
              this.setState({
                phoneNumber: "",
                patientName: "",
                checked: false,
              })
            })
          })
          .catch(err => {
            console.error("Error: On creating the patient at appsync", err)
            window.alert(
              err.code === "UsernameExistsException"
                ? "Phone number already exists"
                : "Something went wrong. Please try again later.",
              "warning"
            )
          })
      } else {
        window.alert("Please fill out the fields correctly", "warning")
      }
    } else {
      window.alert("Please fill out the fields", "warning")
    }
  }

  render() {
    return (
      <Card
        style={{
          marginTop: "4rem",
          display: "flex",
          flexDirection: "column",
          padding: 20,
        }}
      >
        <MaterialTextField
          id="patientName"
          type="text"
          placeholder="Name"
          helperText="Note: Please enter patient's full name."
          style={{ width: "15rem", marginBottom: 25 }}
          onChange={e =>
            this.setState({
              patientName: e.target.value,
            })
          }
          value={this.state.patientName}
        />
        <MaterialTextField
          id="phoneNumber"
          type="text"
          helperText="Note: Must be 10 digits only"
          placeholder="Phone number"
          style={{ width: "15rem", marginBottom: 25 }}
          onChange={e =>
            this.setState({
              phoneNumber: e.target.value,
            })
          }
          value={this.state.phoneNumber}
        />
        {/* <MaterialTextField
          id="website"
          type="text"
          helperText="Note: Patient will contact you through this website only"
          placeholder="Doctor website"
          style={{ width: "15rem", marginBottom: 25 }}
          onChange={e =>
            this.setState({
              doctorWebsite: e.target.value
            })
          }
          value={this.state.doctorWebsite}
        /> */}
        {this.state.doctorWebsite && (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                onChange={this.handleChange("checked")}
                value="checked"
                color="primary"
              />
            }
            label={`Get consult through "${this.state.doctorWebsite}" (Make sure you have configured our ChatBot in this website).`}
          />
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ width: "6rem" }}
          className="btn"
          onClick={this.createConsult.bind(this)}
          id="consultCreateBtn"
        >
          Submit
        </Button>
      </Card>
    )
  }
}
