import React, { useState } from "react"
import {
  Create,
  SimpleForm,
  TextInput,
  Link,
  SaveButton,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  REDUX_FORM_NAME,
  AutocompleteInput
} from "react-admin"
import { change } from 'redux-form'
import LinearProgress from '@material-ui/core/LinearProgress'
import logo from "../Images/dermio-logo.svg"
import { validateFirstName, validateLastName, validatePractice, validateEmail, validateRequiredPhoneNumField } from "../util/validation"
import getPractice from "../queries/fetchPractice"

export const CreateAdmin = props => {

  const [isPracticeChanged,  setPracticeChanged] = useState(false);
  const [loading,  setLoading] = useState(false);

  const getPracticeName = async (formData, dispatch ) => {
    // console.log("here")
    setPracticeChanged(false);

    const { data, error } = await getPractice(formData.practiceId)
    if(error){
      console.log(error)
      setLoading(false)
    }
    if(data && data.Practice &&  data.Practice.id){
      dispatch(change(REDUX_FORM_NAME, 'practiceName', data.Practice.name));
      setLoading(false)
    }

  }

  return (
    <Create
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      {...props}
      style={{ marginTop: "4rem" }}
    >
      <SimpleForm
        toolbar={<SaveButton id="adminCreate" style={{ marginTop: 10 }} />}
      >
        <TextInput source="firstName" validate={validateFirstName} />
        <TextInput source="lastName" validate={validateLastName} />
        <TextInput source="email" type="email" validate={validateEmail} parse={s => s.toLowerCase() }/>
        <TextInput source="phone" validate={validateRequiredPhoneNumField} />
       <><ReferenceInput
            label="Practice"
            source="practiceId"
            reference="Practice"
            validate={validatePractice}
            id="practiceList"
            filterToQuery={searchText =>{
              console.log(searchText)
              if(searchText.length > 3){
                return { name: searchText }
              }else{
                return {}
              }
            }}>
            <AutocompleteInput 
                options={{
                  suggestionsContainerProps: {
                      disablePortal: true,
              }}}
              optionText="name"
              allowEmpty={true}
              />
          </ReferenceInput>
          <p
              style={{
                width: "90%",
                fontSize: "10px",
                marginTop: 18,
                marginBottom: 18,
                color: "#aeaeae",
              }}
            >
              To clear practice: cmd+a or ctlr+a then backspace
            </p>
          </>
      </SimpleForm>
    </Create>
  )
}
