import React, { Component } from "react"
import Storage from "@aws-amplify/storage"
import DescriptionIcon from '@material-ui/icons/Description'
import formatDistance from "date-fns/formatDistance"

Storage.configure({
  bucket: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_BUCKET_REGION,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
})

class AwsImageComponent extends Component {
  state = {
    loading: true,
    url: "",
  }

  componentDidMount = () => {
    const key = this.props.src.url.split("/").pop()
    Storage.get(key, { level: "public", expires: 2000 })
      .then(result => {
        // console.log("key "+key+" result: ",result)
        this.setState({ loading: false, url: result })
      })
      .catch(err => console.log(err))
  }

  render() {
    const { loading, url } = this.state
    const { onClick, componentFrom, styles, alt, src, extension } = this.props

    if (loading) {
      return <h6>loading...</h6>
    }

    if (componentFrom === "chat") {
      return (
        <div style={{height: "100%", padding: 5, cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} onClick={()=>onClick(url)}>
          <DescriptionIcon 
            style={{color: "black", fontSize: 60}}
            onClick={()=>onClick(url)}
          />
          <span className="chat__message-time" style={{paddingBottom:0}}>{extension}</span>
          <span className="chat__message-time" style={{color: "#757575", paddingTop: 10}}>{formatDistance(new Date(src.createdAt), new Date(),
                            {
                              addSuffix: true,
                            }
                          )}</span>
        </div>
      )
    }
  }
}

export default AwsImageComponent
