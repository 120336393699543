import React from "react"
export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = { file: "", imagePreviewUrl: "" }
  }

  _handleSubmit(e) {
    e.preventDefault()
    let prom = new Promise((resolve, reject) => {
      this.props.data["profilePic"] = this.state.file
      this.refs.btn.setAttribute("disabled", "disabled")
      setTimeout(() => {
        resolve()
      }, 3000)
    })

    // console.log(this.props.data["profilePic"]);
    prom.then(() => {
      document.getElementById("test").innerHTML =
        "Profile pic uploaded successfully"
      setTimeout(() => {
        document.getElementById("test").innerHTML = ""
      }, 2000)
    })
  }

  _handleImageChange(e) {
    e.preventDefault()

    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      })
    }

    reader.readAsDataURL(file)
  }

  render() {
    let { imagePreviewUrl } = this.state
    let $imagePreview = null
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} />
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      )
    }

    return (
      <div className="previewComponent">
        <form onSubmit={e => this._handleSubmit(e)}>
          <input
            className="fileInput"
            type="file"
            onChange={e => this._handleImageChange(e)}
          />
          <button
            className="submitButton"
            ref="btn"
            type="submit"
            onClick={e => this._handleSubmit(e)}
          >
            Upload Image
          </button>
        </form>
        <div className="imgPreview">{$imagePreview}</div>
      </div>
    )
  }
}
