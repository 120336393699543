import React, { Component, cloneElement } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import MuiAppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import withWidth from "@material-ui/core/withWidth"
import compose from "recompose/compose"
import { toggleSidebar as toggleSidebarAction } from "ra-core"
import { Link } from "react-router-dom"
import { Headroom, LoadingIndicator, UserMenu } from "ra-ui-materialui"
import gql from "graphql-tag"
import { Auth } from "aws-amplify"
import Appsync from "../util/client"
import "./customStyles.css"

const client = Appsync()

const query = gql`
  query doctorDetails($id: ID!) {
    doctorDetails: Doctor(id: $id) {
      id
      practiceId
      enabled
    }
  }
`

const styles = theme => ({
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: "0.5em",
    marginRight: "0.5em",
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
})

class AppBar extends Component {
  state = {
    doctorDetails: {},
  }

  async componentDidMount() {
    let doctorId = localStorage.getItem("userId")

    if (!doctorId) {
      try {
        const currentSessionRes = await Auth.currentSession()

        doctorId = currentSessionRes.idToken.payload["sub"]
      } catch (e) {
        console.error(e)
      }
    }

    const variables = {
      id: doctorId,
    }

    try {
      const cl = await client.hydrated()
      const res = await cl.query({ query, variables, fetchPolicy: "no-cache" })
      const { doctorDetails } = res.data

      this.setState({
        doctorDetails,
      })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const {
      children,
      classes,
      className,
      logout,
      open,
      title,
      toggleSidebar,
      userMenu,
      width,
      ...rest
    } = this.props

    const { doctorDetails } = this.state

    let appBarItemsArr = [
      <MenuItem key="1">
        <Link
          className="appbar__profile-link"
          to={`/Doctor/${doctorDetails.id}`}
          style={{ width: "100%", height: "100%" }}
        >
          Profile
        </Link>
      </MenuItem>,
      <MenuItem key="2">
        <Link
          className="appbar__profile-link"
          to={`/Practice/${doctorDetails.practiceId}`}
          style={{ width: "100%", height: "100%" }}
        >
          Practice
        </Link>
      </MenuItem>,
      <MenuItem key="3">
        <Link
          className="appbar__profile-link"
          to={`/copyChatBotScript`}
          style={{ width: "100%", height: "100%" }}
        >
          Chatbot
        </Link>
      </MenuItem>,
      <MenuItem key="4">
        <a
          className="appbar__profile-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://dashboard.stripe.com"
          style={{ width: "100%", height: "100%" }}
        >
          Bank details
        </a>
      </MenuItem>,
    ]

    if (!doctorDetails.enabled) {
      appBarItemsArr.splice(0, 1)
      appBarItemsArr.splice(0, 2)
    }

    return (
      <Headroom>
        <MuiAppBar
          className={className}
          color="secondary"
          position="static"
          {...rest}
        >
          <Toolbar
            disableGutters
            variant={width === "xs" ? "regular" : "dense"}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleSidebar}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
            <LoadingIndicator />
            {cloneElement(userMenu, { logout, random: null }, appBarItemsArr)}
          </Toolbar>
        </MuiAppBar>
      </Headroom>
    )
  }
}

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
}

AppBar.defaultProps = {
  userMenu: <UserMenu />,
}

const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles),
  withWidth()
)

export default enhance(AppBar)
