import Amplify, { Auth } from "aws-amplify"
import Appsync from "../../../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query doctorDetails($id: ID!) {
    doctorDetails: Doctor(id: $id) {
      id
      practiceId
      npi
      dea
      stateLicenseNumber
      state
      firstName
      lastName
      name
      email
      licensedStates
      phone
      file
      deaName
      residentState
      street
      city
      enabled
      fax
      stripeId
      videocallFee
      canPrescribe
      prescribeInfoDisplayed
      optedOut
      practice {
        id
        name
        email
        website
        address
        city
        state
        zip
        phone
        fax
        consultationFee
      }
    }
  }
`

const fetchDoctor = async doctorId => {
//   console.log(doctorId)
  const variables = { id: doctorId }
//   console.log("variables ", variables)
  const doctorDetails = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })

  return doctorDetails
}

export default fetchDoctor
