import React, { Component, cloneElement } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { Auth } from "aws-amplify"
import MuiAppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import MenuIcon from "@material-ui/icons/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import withWidth from "@material-ui/core/withWidth"
import compose from "recompose/compose"
import { toggleSidebar as toggleSidebarAction } from "ra-core"
import { Link } from "react-router-dom"
import { Headroom, LoadingIndicator, UserMenu } from "ra-ui-materialui"
import "./customStyles.css"
import fetchPracticeAdmin from "../queries/fetchPracticeAdmin"

const styles = theme => ({
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: "0.5em",
    marginRight: "0.5em",
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
})

class AppBar extends Component {

  state = {
    role: null,
    userId: null,
    practiceId: null
  }

  async componentDidMount() {
    try {
      const currentSessionRes = await Auth.currentSession()
      const role = currentSessionRes.idToken.payload["cognito:groups"][0]
      const userId = currentSessionRes.idToken.payload.sub
      this.setState({
        role,
        userId
      });
      if(role && role === "practiceAdmin"){
        // console.log("role ",role);
        const {data: { Admin }} = await fetchPracticeAdmin(currentSessionRes.idToken.payload["sub"]);
        if(Admin && Admin.practiceId){
          // console.log("setting adminPracticeId")
          this.setState({practiceId: Admin.practiceId});
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const {
      children,
      classes,
      className,
      logout,
      open,
      title,
      toggleSidebar,
      userMenu,
      width,
      ...rest
    } = this.props
    // console.log(this.props)
    const { role, userId, practiceId } = this.state;

    let appBarItemsArr = [
      <MenuItem key="1">
        <Link
          className="appbar__profile-link"
          to={`/AdminChatBotCustomizations/adminChatBotCustomizations`}
        >
          Chatbot
        </Link>
      </MenuItem>,
    ]

    if(role === "practiceAdmin"){
      appBarItemsArr = [
        <MenuItem key="1">
          <Link
            className="appbar__profile-link"
            to={`/Admin/${userId}`}
          >
            Profile
          </Link>
        </MenuItem>,
        <MenuItem key="2">
          <Link
            className="appbar__profile-link"
            to={`/Practice/${practiceId}`}
          >
            Practice
          </Link>
      </MenuItem>,
      <MenuItem key="3">
      <Link
        className="appbar__profile-link"
        to={`/copyChatBotScript`}
      >
        Chatbot
      </Link>
    </MenuItem>,
      ]
    }else if(role === "nurse" || role === "staff"){
      appBarItemsArr = [
        <MenuItem key="1">
          <Link
            className="appbar__profile-link"
            to={`/PracticeUser/${userId}`}
          >
            Profile
          </Link>
        </MenuItem>
      ]
    }

    return (
      <Headroom>
        <MuiAppBar
          className={className}
          color="secondary"
          position="static"
          {...rest}
        >
          <Toolbar
            disableGutters
            variant={width === "xs" ? "regular" : "dense"}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleSidebar}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.title}
              id="react-admin-title"
            />
            <LoadingIndicator />
            {cloneElement(userMenu, { logout, random: null }, appBarItemsArr)}
          </Toolbar>
        </MuiAppBar>
      </Headroom>
    )
  }
}

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
}

AppBar.defaultProps = {
  userMenu: <UserMenu />,
}

const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles),
  withWidth()
)

export default enhance(AppBar)
