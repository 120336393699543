import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
// import Typography from "@material-ui/core/Typography"

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  hidden: {
    display: "none",
  },
})

const StatusButton = ({
  classes,
  isDisabled,
  isHidden,
  handleClick,
  btnText,
  cardDeclined,
}) => {
  if (btnText === "Finalize Consult (Paid)") {
    return (
      <Fragment>
        {/* {isDisabled && (
          <Typography color="error">
            {cardDeclined
              ? "The patient's card has insufficient funds (or) declined. You can finalize this consult as paid once they re-enter their card details."
              : "Sorry! You cannot finalize this consult as paid because the patient has not given their credit card details yet."}
          </Typography>
        )} */}
        <Button
          variant="contained"
          color="primary"
          // className={classes.button}
          className={isHidden ? classes.hidden : classes.button}
          disabled={isDisabled}
          onClick={handleClick}
        >
          {btnText}
        </Button>
      </Fragment>
    )
  }

  return (
    <Button
      variant="contained"
      color="primary"
      // className={classes.button}
      className={isHidden ? classes.hidden : classes.button}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {btnText}
    </Button>
  )
}

StatusButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  isHidden: PropTypes.func, // only for 'Accept' button
  btnText: PropTypes.string.isRequired,
}

export default withStyles(styles)(StatusButton)
