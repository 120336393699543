import React from "react"
import compose from "recompose/compose"
import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import avatar from "../../Chat/images/stock-avatar.png"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CommentIcon from "@material-ui/icons/Comment"
import NotificationsIcon from '@material-ui/icons/Notifications'
import Divider from "@material-ui/core/Divider"
import { Link } from "react-router-dom"
import { translate } from "react-admin"
import CardIcon from "./CardIcon"


const styles = theme => ({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
    marginBottom: 20,
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
  },
  value: {
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
})

const VideoCallFeeNotification = ({ doctor , classes }) => (
  <div className={classes.main}>
    <CardIcon Icon={NotificationsIcon} bgColor="#3fcff3" />
    <Card className={classes.card}>
      <Typography className={classes.title} style={{ color: "#3fcff3" }}>
        Notifications
      </Typography>
      <Divider />
      <List style={{ maxHeight: "50vh", overflow: "auto" }}>
            <ListItem
              button
              component={Link}
              to={`/Doctor/${doctor.id}`}
            >
              <ListItemText
                primary={
                  <span>
                    You haven't updated your videocall fee yet. Please update videocall fee and set up your availability.
                  </span>
                }
                secondary={"Click here to update"}
                className={classes.listItemText}
                style={{ paddingRight: 0 }}
              />
            </ListItem>

      </List>
    </Card>
  </div>
)

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(VideoCallFeeNotification)
