import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Confirm } from "react-admin"
import Button from "@material-ui/core/Button"

import { archiveConsult } from "../consults/archiveAction"

class ArchiveButton extends Component {
  state = {
    isOpen: false,
  }

  handleClick = () => {
    this.setState({ isOpen: true })
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false })
  }

  handleConfirm = () => {
    const { basePath, selectedIds, archiveConsult } = this.props

    for (let i = 0; i < selectedIds.length; i++) {
      archiveConsult(selectedIds[i], {}, basePath)
      this.setState({ isOpen: true })
    }
  }

  render() {
    return (
      <Fragment>
        <Button
          varant="raised"
          label="Archive"
          color="primary"
          onClick={this.handleClick}
        >
          Archive
        </Button>
        <Confirm
          isOpen={this.state.isOpen}
          title="Archive Consults"
          content="Are you sure you want to archive these consults?"
          onConfirm={this.handleConfirm}
          onClose={this.handleDialogClose}
        />
      </Fragment>
    )
  }
}

export default connect(
  undefined,
  { archiveConsult }
)(ArchiveButton)
