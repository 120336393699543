import React, { useState, useEffect } from "react"
import {
  Filter,
  SelectInput,
  SearchInput,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  BooleanInput,
} from "react-admin/lib"
import "../../../node_modules/react-dropzone-component/styles/filepicker.css"

import "font-awesome/css/font-awesome.min.css"
import gql from "graphql-tag"
import Appsync from "../../util/client"

const client = Appsync()

const query = gql`
  query allPracticeDoctors($practiceId: String){
    allPracticeDoctors(
      filter:{},
      practiceId: $practiceId
    ){
      items{
        id,
        name
      }
    }
  }
`;

export const DoctorFilter = props => (
  <Filter {...props}>
    <TextInput label="Consult Source" source="source" />
    <SearchInput label="First Name" source="firstname" placeholder="Firstname" alwaysOn />
    <SearchInput label="Last Name" source="lastname" placeholder="Lastname" alwaysOn />
    <SelectInput
      label="Status"
      source="status"
      alwaysOn
      choices={[
        { id: "pending", name: "Pending" },
        { id: "accepted", name: "Accepted" },
        { id: "closed_paid", name: "Closed (Paid)" },
        { id: "closed_unpaid", name: "Closed (UnPaid)" },
        { id: "closed_insurance", name: "Closed (Insurance)" },
      ]}
    />
    <BooleanInput label="Archived" source="archived" alwaysOn />
  </Filter>
)
export const PracticeAdminFilter = props => {
  const [doctorsList, setDoctorsList] = useState([]);
  // const filter = {practiceId : localStorage.getItem("adminPracticeId") };
  
  useEffect(()=>{
    const variables = {practiceId : localStorage.getItem("adminPracticeId") };

    client.hydrated().then((cl)=>{
      cl.query({ query, variables, fetchPolicy: "no-cache" }).then((res)=>{
        const { allPracticeDoctors } = res.data;
        // console.log(res)
        if(allPracticeDoctors && allPracticeDoctors.items){
          setDoctorsList(allPracticeDoctors.items);
        }
      }).catch((error)=>{
        console.log(error);
      });
    }).catch((error)=>{
        console.log(error);
      });
  },[]);

  return(
  <Filter {...props}>
    <SearchInput label="First Name" source="firstname" placeholder="Firstname" />
    <SearchInput label="Last Name" source="lastname" placeholder="Lastname" />
    <SelectInput
      label="Status"
      source="status"
      alwaysOn
      choices={[
        { id: "pending", name: "Pending" },
        { id: "accepted", name: "Accepted" },
        { id: "closed_paid", name: "Closed (Paid)" },
        { id: "closed_unpaid", name: "Closed (UnPaid)" },
       { id: "closed_insurance", name: "Closed (Insurance)" },
      ]}
    />
    <SelectInput
      label="Doctor"
      source="doctorId"
      alwaysOn
      choices={doctorsList}
    />
    {/* <ReferenceInput
          label="Doctor"
          alwaysOn
          source="doctorId"
          reference="Doctor"
          filter={filter}
          id="doctorSelect"
        >
      <SelectInput optionText="firstName"/>
    </ReferenceInput>  */}
  </Filter>
)}
