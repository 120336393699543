import React, { Component } from "react"
import Button from "@material-ui/core/Button"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { showNotification, fetchStart, fetchEnd } from "react-admin"
import { push } from "react-router-redux"
import updateConsults from "./queries/updateConsults"
import Modal from "./Components/ConfirmationModal"

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

class Prescribe extends Component {
  state = {
    prescriptionURL: "",
    prescribed: false,
    record: {},
    isModalOpen: false,
  }

  componentDidMount() {
    const prescriptionURL = localStorage.getItem("prescriptionURL")
    const record = JSON.parse(localStorage.getItem("consultDetails"))

    const { prescribed } = record

    if (prescribed) {
      return this.setState({ prescriptionURL, record, prescribed })
    }

    return this.setState({ prescriptionURL, record })
  }

  handleClick = async () => {
    const { push, showNotification, fetchStart, fetchEnd } = this.props

    const { id: consultId } = this.state.record

    fetchStart()
    // await Promise.resolve('testing')
    // await Promise.reject('testing')
    await updateConsults(this.state.record, null, null, true)
      .then(data => {
        // console.log('results of query: ', data);

        localStorage.removeItem("consultDetails")
        localStorage.removeItem("prescriptionURL")

        showNotification("Your Prescription has been successful")
        this.setState(() => {
          return {
            prescriptionURL: "",
            record: {},
            prescribed: true,
          }
        })

        // this.props.push('/prescribe', { state: {prescriptionURL: prescriptionURL} });
        push(`/Consult/${consultId}/show`)
      })
      .catch(e => {
        // console.log(e);
        localStorage.removeItem("consultDetails")
        localStorage.removeItem("prescriptionURL")
        // showNotification('Something went wrong. Please try again later.', 'warning');
        push(`/Consult/${consultId}/show`)
      })
      .finally(fetchEnd)
  }

  handleModalOpen = () => {
    this.setState({
      isModalOpen: true,
    })
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  showConsultPage = () => {
    const { push } = this.props

    const { id: consultId } = this.state.record

    localStorage.removeItem("consultDetails")
    localStorage.removeItem("prescriptionURL")

    push(`/Consult/${consultId}/show`)
  }

  render() {
    const { prescriptionURL, prescribed, isModalOpen } = this.state
    const { classes } = this.props

    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          left: 0,
          right: 0,
          bottom: 10,
          top: 0,
        }}
      >
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          src={prescriptionURL}
        ></iframe>
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.showConsultPage()}
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.handleModalOpen()}
          >
            {/* {prescribed ? 'Completed' : 'Complete'} */}
            Complete Prescription
          </Button>
          <Modal
            isOpen={isModalOpen}
            handleOpen={this.handleModalOpen}
            handleConfirmation={this.handleClick}
            handleClose={this.handleModalClose}
          />
        </div>
      </div>
    )
  }
}

export default connect(
  null,
  {
    showNotification,
    fetchStart,
    fetchEnd,
    push,
  }
)(withStyles(styles)(Prescribe))
