import React from "react"
import { Auth } from "aws-amplify"
import { SignIn } from "aws-amplify-react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles"
import dermioLogo from "../Images/dermio-logo.svg"
import "./style.css"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey["A700"],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
})

class CustomSignIn extends SignIn {
  state = {
    error: "",
    load: false
  }

  handleChange = e => {
    this.handleInputChange(e)
    this.setState({
      error: "",
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ load: true });
    const { username, password } = this.inputs
    const lowerCasedEmail = username.toLowerCase();
    Auth.signIn(lowerCasedEmail, password)
      .then(user => {
        this.setState({ load: false });
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.changeState("requireNewPassword", user)
        } else {
          this.changeState("signedIn")
        }
      })
      .catch(err => {
        console.error("SignIn", err)

        if (err.code === "UserNotFoundException") {
          this.setState({
            error: "User does not exist. Please enter the correct username.",
            load: false
          })
        }

        if (err.code === "NotAuthorizedException") {
          this.setState({
            error: "Incorrect username or password.",
            load: false
          })
        }
      })
  }

  render() {
    const { classes, authState } = this.props
    if (authState === "signIn") {
      return (
        <div className={classes.main}>
          <Card className={classes.card} id="signIn">
            <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className={classes.hint}>SIGN IN TO YOUR ACCOUNT</div>
              <div className={classes.error}>{this.state.error}</div>
              {this.state.load ? <div className={classes.error} style={{ color: '#25c3ea' }}>
                <CircularProgress color={'#25c3ea'} size={20} /> &nbsp; Signing in</div> : null}
              <div className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    id="username"
                    name="username"
                    label="Email"
                    margin="normal"
                    type="email"
                    fullWidth={true}
                    required
                    error={this.state.error ? true : false}
                    onChange={this.handleChange}
                  />
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    margin="normal"
                    type="password"
                    fullWidth={true}
                    required
                    error={this.state.error ? true : false}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button onClick={() => this.changeState("forgotPassword")}>
                  Forgot Password
                </Button>
                <Button
                  id="loginSubmit"
                  variant="raised"
                  type="submit"
                  color="primary"
                  className={classes.button}
                  fullWidth
                  onChange={this.handleInputChange}
                >
                  Submit
                </Button>
              </CardActions>
            </form>
          </Card>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(CustomSignIn)
