import React, {useState ,useEffect} from "react"
import {
  Create,
  Link,
  SaveButton,
  SimpleForm,
  ArrayInput, 
  SimpleFormIterator,
  TextInput,
  AutocompleteInput,
  BooleanInput
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import { TimeInput } from 'react-admin-date-inputs'
import { Redirect } from "react-router-dom"
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper'
// import { TimePicker } from "@material-ui/pickers";
import { validateText, validatePractice } from "../util/validation"
import getCurrentTimezoneName from "../util/getCurrentTimezoneName"

export const DoctorIcon = null

export const AvailabilityCreate = props => {
  // console.log(props)
  return (
  // console.log('\ninvoked\n'),
  <Create
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    {...props}
    style={{ marginTop: "3rem" }}
  >

  <SimpleForm
      defaultValue={
        props.permissions === "doctor"
          ? {
              doctorId: localStorage.getItem("userId"),
              createdBy: "doctor",
            }
          : { createdBy: "admin" }
      }
      toolbar={<SaveButton id="chatResponseCreate" style={{ marginTop: 10 }} />}
    >
    <Paper style={{ padding: 10 }}>
      <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15, color: "#3fcff3", fontWeight: "600" }}>
            Most common available time. 
      </p>
      <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 13, marginTop: 10 }}>
            This setting applies to all upcoming selected Day.
      </p>
      <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 13, marginTop: 10 }}> 
        Ex: If you set Monday available slots this applies to all upcoming mondays.
      </p> 
     </Paper>
     <AutocompleteInput source="day" choices={[
          { id: 'Monday', name: 'Monday' },
          { id: 'Tuesday', name: 'Tuesday' },
          { id: 'Wednesday', name: 'Wednesday' },
          { id: 'Thursday', name: 'Thursday' },
          { id: 'Friday', name: 'Friday' },
          { id: 'Saturday', name: 'Saturday' },
          { id: 'Sunday', name: 'Sunday' },
        ]} 
        validate={validateText}
      />
      <BooleanInput label="Active Status" source="isActive" />
      <ArrayInput source="slots" validate={validatePractice}>
          <SimpleFormIterator>
            <TimeInput source="from" label="From" options={{ clearable: true, ampm: true }} validate={validateText}/>
            <TimeInput source="to" label="To" options={{ clearable: true, ampm: true  }} validate={validateText}/>
          </SimpleFormIterator>
      </ArrayInput>
      <Paper style={{ padding: 10 }}>
       <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
          Timezone: {getCurrentTimezoneName()}
        </p>
      </Paper>
       {/* <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
          Note: You can edit unavailable days later. 
        </p>
      */}
    </SimpleForm>
  </Create>
)
}
