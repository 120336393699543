import React from "react"
import StateSearch from "./statesSelector"
import ImageComponent from "./imageUpload"
let data = {}
export default class BasicInfo extends React.Component {
  constructor() {
    super()
  }
  nextStep = e => {
    e.preventDefault()
    this.props.nextStep()
  }

  // renderOptions = (type, name, value, index) =>{
  //     // const isChecked = () => {
  //     //     if (type == 'radio') return value == this.props.fieldValues[name];
  //     //     if (type == 'checkbox') return this.props.fieldValues[name].indexOf(value) >= 0
  //     //         return false;
  //     // }
  //     return (
  //         <label key={index}>
  //           <input type={type} name={name} value={value} /> {value}
  //         </label>
  //       )
  // }

  render() {
    return (
      <div>
        <h2>Basic Details</h2>
        <ul className="form-fields">
          <li>
            <label>First Name</label>
            <input
              type="text"
              ref="fname"
              defaultValue={this.props.data["fname"]}
              onChange={event => {
                this.props.fieldUpdate(event, "fname")
              }}
            />
          </li>
          <li>
            <label>Last Name</label>
            <input
              type="text"
              ref="lname"
              defaultValue={this.props.data["lname"]}
              onChange={event => {
                this.props.fieldUpdate(event, "lname")
              }}
            />
          </li>
          <li>
            <li>
              <label>Upload Profile Photo</label>
              <ImageComponent data={this.props.data} />
            </li>
            <label>Email</label>
            <input
              type="email"
              ref="email"
              defaultValue={this.props.data["email"]}
              onChange={event => {
                this.props.fieldUpdate(event, "email")
              }}
            />
          </li>
          <li>
            <label>Licensed state</label>
            <StateSearch
              data={this.props.data}
              fieldUpdate={this.props.fieldUpdate}
            />
          </li>
          <li>
            <label>NPI Number</label>
            <input
              type="text"
              ref="NPIname"
              defaultValue={this.props.data["NPIname"]}
              onChange={event => {
                this.props.fieldUpdate(event, "NPIname")
              }}
            />
          </li>
          <li>
            <label>DEA Number</label>
            <input
              type="text"
              ref="DEAname"
              defaultValue={this.props.data["DEAname"]}
              onChange={event => {
                this.props.fieldUpdate(event, "DEAname")
              }}
            />
          </li>
          <li>
            <textarea
              name="Biography"
              form="usrform"
              placeholder="include educational credentials"
            />
          </li>
          <li className="form-footer">
            <button className="btn -primary pull-right" onClick={this.nextStep}>
              Save &amp; Continue
            </button>
          </li>
        </ul>
      </div>
    )
  }
}
