import React, { useState, useEffect, Fragment } from "react"
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Link,
  TextInput,
  Filter,
  DateField,
  BooleanField,
  ShowButton,
  showNotification
} from "react-admin"
import logo from "../../Images/dermio-logo.svg"
import { ListItem, List as MaterialList, Paper } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommentIcon from '@material-ui/icons/Comment';
import Button from '@material-ui/core/Button';
import gql from "graphql-tag"
import Appsync from "../../util/client";
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import setInviteStatus from '../../queries/updateInviteStatus';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ClearAllIcon from '@material-ui/icons/ClearAll';



import compose from "recompose/compose"
import Card from "@material-ui/core/Card"
import Avatar from "@material-ui/core/Avatar"
import ImageIcon from "@material-ui/icons/Image"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CustomerIcon from "@material-ui/icons/PersonAdd"
import Divider from "@material-ui/core/Divider"
import formatDistance from "date-fns/formatDistance"
import { translate } from "react-admin"
import CardIcon from "./CardIcon"
import getCurrentTimezoneName from "../../util/getCurrentTimezoneName"



export const AcceptedVideoCalls = props => {

  // console.log(props, "props")
  const { todayCallList } = props;
  return (
    <div style={{
      paddingTop: '0px',
      flex: "1",
      marginRight: "1em",
      marginTop: 20,
      marginBottom: 20,
    }}>
      <Card style={{
        overflow: "inherit",
        textAlign: "right",
        padding: 16,
      }}>
        <CardIcon Icon={VideoCallIcon} bgColor="#3fcff3" />
        <Typography><h4 style={{ color: "#3fcff3", textDecoration: "none" }}>Today's video calls</h4>
          <br />
        </Typography>
        <Divider />
        {todayCallList ?
          <MaterialList
          >
            {todayCallList && todayCallList.map((videocall) => {
              return (
                videocall.inviteStatus == "accepted" && videocall.callStatus == "upcoming" &&
                  moment(moment(videocall.scheduledDateTime), "hh:mm:ss A").add(0, 'seconds').add(15, 'minutes') > moment() ?
                  <div key={videocall.id}><ListItem key={videocall.id}>
                    <ListItemText primary={`${videocall.consultName}`} />
                    <ListItemText
                      primary={`${moment(videocall.scheduledDateTime).format("LT")}`} 
                      secondary={getCurrentTimezoneName()}
                    />
                    <Button variant="outlined" onClick={() => props.push(`/VideoCall/${videocall.id}/show`)}  >
                      Join <VideoCallIcon fontSize="large" color="primary" /></Button>
                  </ListItem>
                    <Divider /></div>
                  : videocall.callStatus == "completed" || videocall.inviteStatus == "invited" || videocall.inviteStatus == "declined" || moment(moment(videocall.scheduledDateTime), "hh:mm:ss A").add(0, 'seconds').add(15, 'minutes') < moment() ? null :
                    <h4 style={{ padding: '20px', color: 'gray' }}> No more accepted video calls today... </h4>
              )
            })}
          </MaterialList> : <h4 style={{ padding: '20px', color: 'gray' }}> No video calls today... </h4>}
      </Card>
    </div>
  )
}

export const PendingVideoCalls = (props) => {
  // console.log(props, "props upcomingVideoList")
  const { upcomingCallList } = props;
  return (
    <div style={{
      paddingTop: '30px',
      flex: "1",
      marginRight: "1em",
      marginTop: 20,
      marginBottom: "50px",
    }}>
      <Card style={{
        overflow: "inherit",
        textAlign: "right",
        padding: 16,
      }}>
        <CardIcon Icon={VideoCallIcon} bgColor="#3fcff3" />
        <Typography><h4 style={{ color: "#3fcff3", textDecoration: "none" }}>Upcoming video calls</h4>
          <br />
        </Typography>
        <Divider />
        {upcomingCallList ? <MaterialList
        // {...props}
        // pagination={<Pagination {...props} />}
        >
          {upcomingCallList && upcomingCallList.map((videocall) => {
            // console.log(videocall)
            return (
              <div key={videocall.id}><ListItem key={videocall.id} >
                <ListItemText primary={`${videocall.consultName}`} />
                <ListItemText primary={`${moment(videocall.scheduledDateTime).format('LLL')}`} />
                <ListItemText primary={`${videocall.inviteStatus}`} />
                <ListItemText><ActionButtonComponent source="inviteStatus" status={videocall.inviteStatus} id={videocall.id} consultId={videocall.consultId} fetchDashboardData={props.fetchDashboardData}/></ListItemText>
                <Button onClick={() => props.push(`/VideoCall/${videocall.id}`)} >Show more</Button>
              </ListItem>
                <Divider /></div>
            )
          })}
        </MaterialList> : <h4 style={{ padding: '20px', color: 'gray' }}> No upcoming video calls ... </h4>}
      </Card>
    </div>
  )
}


const ActionButtonComponent = ({ id, status, consultId, fetchDashboardData }) => {
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  function handleClick(id, value, consultId) {
    setInviteStatus(id, value, consultId)
      .then((res) => {
        setSnackbarOpen(true);
        fetchDashboardData();
      })
      .catch((err) => {
        console.log(err)
      });
  }

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const AcceptButton = () => {
    return <Button variant="outlined" size="small" style={{ backgroundColor: "green" }} onClick={() => handleClick(id, "accepted", consultId)} >Accept </Button>
  }

  const DisabledAcceptButton = () => { return <Button variant="outlined" size="small" disabled >Accept </Button> }

  const DenyButton = () => { return <Button variant="outlined" size="small" style={{ backgroundColor: "red" }} color="default" onClick={() => handleClick(id, "declined", consultId)}>Decline </Button> }

  const DisabledDenyButton = () => { return <Button variant="outlined" size="small" disabled >Decline </Button> }

  const SnackBar = () => {
    return <Snackbar
      open={isSnackbarOpen}
      onClose={() => setSnackbarOpen(false)}
      TransitionComponent={SlideTransition}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">Consult status updated.</span>}
    />
  }

  if (status == "invited") {
    return (
      <div>
        <AcceptButton />&nbsp;
        <DenyButton />
        <SnackBar />
      </div>
    )
  } else if (status == "accepted") {
    return (
      <div>
        <DisabledAcceptButton />&nbsp;
        <DenyButton />
        <SnackBar />
      </div>)
  } else {
    return (
      <div>
        <AcceptButton />&nbsp;
        <DisabledDenyButton />
        <SnackBar />
      </div>
    )
  }

}
