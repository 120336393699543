import React, { Fragment } from "react"
import { ListButton, RefreshButton } from "react-admin"
import StatusButtons from "../StatusButtons"
import AdminConsultBtns from "./AdminConsultBtns"
import PrescribeButton from "./PrescribeButton"
import LoadingSpinner from "./LoadingSpinner"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

export const NameTextFieldWithPadding = ({ source, record = {} }) => (
  <span>{record[source]}</span>
)

NameTextFieldWithPadding.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

NameTextFieldWithPadding.defaultProps = {
  addLabel: true,
}

const styles = theme => ({
  root: {},
})

class ConsultBtns extends React.Component {
  render() {
    const {
      permissions,
      basePath,
      showChatBtn,
      changeConsultStatus,
      consultStatus,
      isLoading,
      classes,
      generatePdf,
      consultDetails,
      consultRecordObj,
      didVideoCall,
      doctorDetails,
      pendingVideoCall,
    } = this.props

    const record = consultDetails;

    if (permissions === "doctor") {
      return record && !isLoading && consultDetails ? (
        <StatusButtons
          record={record}
          showChatBtn={showChatBtn}
          changeConsultStatus={changeConsultStatus}
          consultStatus={consultStatus}
          generatePdf={generatePdf}
          basePath={basePath}
          consultDetails={consultDetails}
          didVideoCall={didVideoCall}
          doctorDetails={doctorDetails}
          pendingVideoCall={pendingVideoCall}
        />
      ) : (
        <LoadingSpinner />
      )
    } else {
      return (
        <div className={classes.root}>
          {/* data is null initially */}
          {record && (
            <AdminConsultBtns record={record} showChatBtn={showChatBtn} />
          )}
          <ListButton basePath={basePath} record={record} />
          {/* <RefreshButton basePath={basePath} record={record} /> */}
        </div>
      )
    }
  }
}
export default withStyles(styles)(ConsultBtns)
