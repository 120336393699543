import React from "react"
import { SaveButton, Toolbar } from "react-admin"

const CustomEditToolbar = props => {
  // console.log('SGHOW', props);
  // props.record.phone = props.record.phone && (props.record.phone.length === 10 || props.record.phone.length === 12) && props.record.phone.split('')[0] === '+' && props.record.phone.split('')[0] === '1' ? props.record.phone : `+1${props.record.phone}`

  if (props && props.record && props.record.phone) {
    props.record.phone = props.record.phone.trim()
    if (props.record.altPhone) {
      props.record.altPhone = props.record.altPhone.trim()
    }
  }

  return (props.hideActions) ? (
    // <Toolbar {...props}>
    //   <SaveButton />
    // </Toolbar>
    <></>
  ) : (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )
}

export default CustomEditToolbar
