import React from "react"
import { Auth } from "aws-amplify"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import formatDistance from "date-fns/formatDistance"
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import { Card, Button } from "@material-ui/core"
import Storage from "@aws-amplify/storage"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import { Responsive } from "react-admin/lib"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import moment from "moment"
import Modal from "@material-ui/core/Modal"

import Form from "../../Components/Form"
import ConsultBtns from "../../Components/ConsultBtns"
import Chat from "../../Chat/chat"
import PatientDetails from "../../PatientDetails"
import createPdfMutation from "../../queries/createPdfQuery"

import PdfAddressModal from "../../Components/PdfAddressModal"
import PrescribeButton from "../../Components/PrescribeButton"
import "../../../node_modules/react-dropzone-component/styles/filepicker.css"

import decryptConsult from "../../util/decryptConsult"
import Appsync from "../../util/client"

import "font-awesome/css/font-awesome.min.css"

import updateVideoCallEndTime from "../../queries/updateEndTime.js"
import fetchDoctorConsultVideoCall from "../../queries/fetchDoctorConsultVideoCall"
import fetchVideoCallDetails from "../../queries/fetchVideocallDetails"
import { fetchDoctorDetails } from "../../queries/fetchDoctorDetails"
import Chip from "@material-ui/core/Chip"
import getCurrentTimezoneName from "../../util/getCurrentTimezoneName"

const client = Appsync()

const query = gql`
  query doctorConsults($patientId: String!, $doctorId: ID!) {
    allPatientConsults: allConsults(
      status: { ne: "pending" }
      filter: { patientId: { eq: $patientId }, doctorId: { eq: $doctorId } }
    ) {
      items {
        id
        patientname
        createdAt
      }
    }
  }
`

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  hidden: {
    display: "none",
  },
})

class MainShowPage extends React.Component {
  constructor() {
    super()

    this.state = {
      allPatientConsults: [],
      fetchingAllPatientConsults: true,
      consultStatus: {
        accepted: false,
        closedPaid: false,
        closedUnPaid: false,
        declined: false,
        closedInsurance: false,
      },
      role: null,
      isChatBtnShown: false,
      isPrescribeBtnShown: false,
      loading: false,
      diagnosisSaved: false,
      showPdfGenBtn: false,
      showDownloadBtn: false,
      checkedMedications: {},
      checkedDiseaseTypes: {},
      showGeneratePdfBtn: true,
      data: {},
      impression: null,
      plan: null,
      showPdfDownload: false,
      consultDetails: false,
      openPdfAddressModal: false,
      patientAddress: "",
      didVideoCall: false,
      doctorDetails: null,
      pendingVideoCall: null,
      showModal: false,
      videoCallId: null,
      isFetchingConsult: true
    }
  }

  componentDidMount = async () => {
    try {
      const currentSessionRes = await Auth.currentSession()
      const role = currentSessionRes.idToken.payload["cognito:groups"][0]

      this.setState({
        role,
      })
    } catch (e) {
      console.error(e)
    }
  }

  fetchVideoCall() {
    // console.log(this.props.consultRecordObj.record.id)
    fetchVideoCallDetails(
      this.props.consultRecordObj.record.doctorId,
      this.props.consultRecordObj.record.id
    )
      .then(res => {
        // console.log(moment('2020-03-30T17:46:24.239Z').add(1, 'hours') < moment(), 'res')

        if (
          res.data.allVideoCalls.items[0].startTime &&
          res.data.allVideoCalls.items[0].lastActive &&
          res.data.allVideoCalls.items[0].callStatus == "upcoming" &&
          moment(res.data.allVideoCalls.items[0].scheduledDateTime).add(
            1,
            "hours"
          ) < moment()
        ) {
          this.setState({
            showModal: true,
            videoCallId: res.data.allVideoCalls.items[0].id,
          })
        }
      })
      .catch(err => console.log(err, "err"))
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.consultRecordObj.record !== undefined &&
      nextProps.consultRecordObj.isLoading === false &&
      this.state.consultDetails === false
    ) {
      this.fetchDoctorConsultVideoCalls()
      this.fetchDoctorDetails()
      this.decryptConsults(nextProps.consultRecordObj.record)
      if (nextProps.consultRecordObj.record.isVideoCallConsult) {
        this.fetchVideoCall()
      }
    }
  }

  decryptConsults = (record) => {
    if (
      record !== undefined &&
      this.state.consultDetails === false
    ) {
      // this.fetchDoctorConsultVideoCalls();
      // this.fetchDoctorDetails();
      // console.log("componentDidUpdate ", ++i)
      decryptConsult(record).then(
        async decryptedConsult => {
          if (this.state.role === "admin") {
            return this.setState({
              consultDetails: decryptedConsult,
              fetchingAllPatientConsults: false,
              isFetchingConsult: false
            })
          }

          let doctorId = localStorage.getItem("userId")

          if (!doctorId) {
            try {
              const currentSessionRes = await Auth.currentSession()

              doctorId = currentSessionRes.idToken.payload["sub"]
            } catch (e) {
              console.error(e)
            }
          }

          const variables = {
            doctorId,
            patientId: this.props.consultRecordObj.record.patientId,
          }

          let allPatientConsults = []

          // try {
          //   const cl = await client.hydrated()
          //   const res = await cl.query({
          //     query,
          //     variables,
          //     fetchPolicy: "no-cache",
          //   })

          //   allPatientConsults = await Promise.all(
          //     res.data.allPatientConsults.items.map(async patientConsult => {
          //       let result = await decryptConsult(patientConsult)
          //       return result
          //     })
          //   )
          // } catch (e) {
          //   console.error(e)

          //   return this.setState({
          //     fetchingAllPatientConsults: false,
          //   })
          // }
          // console.log("decryptedConsult ",decryptedConsult)
          return this.setState(() => ({
            consultDetails: decryptedConsult,
            allPatientConsults,
            fetchingAllPatientConsults: false,
            isFetchingConsult: false
          }))
        }
      )
    }
  }

  markCompleted = () => {
    const variables = {
      id: this.state.videoCallId,
      endTime: moment(),
      callStatus: "completed",
    }
    updateVideoCallEndTime(variables)
      .then(response => {
        window.location.reload()
      })
      .catch(console.log)
  }

  markIncompleted = () => {
    const variables = {
      id: this.state.videoCallId,
      endTime: moment(),
      callStatus: "incompleted",
    }
    updateVideoCallEndTime(variables)
      .then(response => {
        window.location.reload()
      })
      .catch(console.log)
  }

  fetchDoctorConsultVideoCalls = async () => {
    // console.log(this.props)
    if (
      this.props.consultRecordObj.record !== undefined &&
      this.state.consultDetails === false
    ) {
      // console.log("called")
      let doctorId = localStorage.getItem("userId")

      if (!doctorId) {
        try {
          const currentSessionRes = await Auth.currentSession()
          doctorId = currentSessionRes.idToken.payload["sub"]
        } catch (e) {
          console.error(e)
        }
      }

      // fetch Doctor-Consult had chated videocall
      const videocallVariables = {
        doctorId,
        consultId: this.props.consultRecordObj.record.id,
      }
      // console.log(videocallVariables)
      fetchDoctorConsultVideoCall(videocallVariables)
        .then(response => {
          const { data } = response
          // console.log(response)
          if (
            data &&
            data.completed &&
            data.completed.items &&
            data.completed.items.length
          ) {
            this.setState({ didVideoCall: true })
          }
          if (
            data &&
            data.pending &&
            data.pending.items &&
            data.pending.items.length
          ) {
            this.setState({ pendingVideoCall: data.pending.items })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  fetchDoctorDetails = async () => {
    let doctorId = localStorage.getItem("userId")

    if (!doctorId) {
      try {
        const currentSessionRes = await Auth.currentSession()
        doctorId = currentSessionRes.idToken.payload["sub"]
      } catch (e) {
        console.error(e)
      }
    }
    fetchDoctorDetails(doctorId)
      .then(response => {
        // console.log("response ", response)
        this.setState({ doctorDetails: response })
      })
      .catch(error => {
        console.log(error)
      })
  }

  showChatBtn = () => {
    this.setState({
      isChatBtnShown: true,
    })
  }

  showPrescribeBtn = () => {
    this.setState({
      isPrescribeBtnShown: true,
    })
  }

  changePdfGenBtn = boolean => {
    this.setState({
      showPdfGenBtn: boolean,
    })
  }

  handleLoadingSpinner = loaderState => {
    this.setState({
      loading: loaderState,
    })
  }

  handleGetSignedUrl = url => {
    if (url == null) {
      return null
    } else {
      return Storage.get(url.split("/").pop(), { expires: 1000 })
        .then(result => {
          return result
        })
        .catch(err => {
          return err
        })
    }
  }

  handleDataChange = (data, generatePdf) => {
    this.setState(
      {
        ...data,
      },
      () => {
        generatePdf && generatePdf()
      }
    )
  }

  handlePdfExport = startDownload => {
    this.setState({ showDownloadBtn: false, showGeneratePdfBtn: false })
    const record = this.state.consultDetails
      ? this.state.consultDetails
      : this.props.consultRecordObj
    // console.log(record)
    const {
      impression,
      plan,
      impressionDescription,
      sideEffectsString,
      skinCareInstructions,
      consultDiagnosisData,
      patientAddress,
      doctorDetails,
    } = this.state
    // console.log("patientAddress ", patientAddress)
    const today = new Date().toDateString()
    // const promises = [
    //   this.handleGetSignedUrl(record.url1),
    //   this.handleGetSignedUrl(record.url2),
    //   this.handleGetSignedUrl(record.url3),
    //   this.handleGetSignedUrl(record.url4),
    // ]
    const that = this
    // Promise.all(promises).then(result => {
    const variables = {
      consultId: record.id,
      patientname: record.patientname,
      age: record.age,
      gender: record.gender,
      patientDob: record.patientDob,
      createdAt: String(today),
      url: record.url,
      currentMedicalIssue: record.currentMedicalIssue,
      medical_issues: record.medical_issues,
      medication: record.medication,
      allergy: record.allergy,
      surgeries: record.surgeries,
      plan: plan,
      impression: impression,
      impressionDescription: impressionDescription,
      sideEffectsString: sideEffectsString,
      skinCareInstructions: skinCareInstructions,
      url1: record.url1,
      url2: record.url2,
      url3: record.url3,
      url4: record.url4,
      consultDiagnosisData: consultDiagnosisData,
      phone_number: record.phone_number,
      patientAddress: patientAddress,
      doctorName: doctorDetails && doctorDetails.name,
    }
    // console.log(variables)
    createPdfMutation(variables)
      .then(response => {
        // console.log("response ",response)
        const result =
          response && response.data && response.data.createPdfExport
        if (result) {
          // if Address changed  generate pdf and start download
          if (startDownload) {
            that.handlePdfAddressModalClose()
            that.handlePdfDownload(record)
          }
          that.setState({ showDownloadBtn: true, showGeneratePdfBtn: true })
        }
      })
      .catch(error => {
        console.error(error)
      })
    // })
  }

  handlePdfDownload = record => {
    Storage.get(`pdfs/${record.id}.pdf`, { expires: 350 })
      .then(result => {
        window.open(result)
      })
      .catch(err => console.log(err))
  }

  changeConsultStatus = () => {
    return {
      consultAccepted: boolFlag =>
        this.setState({ consultStatus: { accepted: boolFlag } }),
      consultClosedPaid: boolFlag =>
        this.setState({ consultStatus: { closedPaid: boolFlag } }),
      consultClosedUnpaid: boolFlag =>
        this.setState({ consultStatus: { closedUnPaid: boolFlag } }),
      consultDeclined: boolFlag =>
        this.setState({ consultStatus: { declined: boolFlag } }),
      consultClosedInsurance: boolFlag =>
        this.setState({ consultStatus: { closedInsurance: boolFlag } }),
    }
  }

  handlePdfAddressModalClose = () => {
    this.setState({ openPdfAddressModal: false })
  }

  handleConsultAddressInputChange = address => {
    this.setState({ patientAddress: address })
  }

  render() {
    const consultRecordObj = this.state.consultDetails
      ? { record: this.state.consultDetails, isLoading: false }
      : this.props.consultRecordObj
    // console.log("render:consultRecordObj ", consultRecordObj)
    const {
      isChatBtnShown,
      loading,
      fetchingAllPatientConsults,
      allPatientConsults,
      role,
      openPdfAddressModal,
      pendingVideoCall,
      isFetchingConsult
    } = this.state

    const { classes, permissions } = this.props

    let pronoun = "their"

    if (consultRecordObj.record) {
      pronoun =
        consultRecordObj.record.gender === "Male"
          ? "his"
          : consultRecordObj.record.gender === "Female"
            ? "her"
            : "their"
    }

    let hideActions = false
    if (
      permissions === "admin" ||
      permissions === "practiceAdmin" ||
      permissions === "nurse" ||
      permissions === "staff"
    ) {
      hideActions = true
    }

    return (
      <Responsive
        small={
          <div className={classes.wrapper}>
            <ExpansionPanel
              elevation={5}
              className={classes.rootSm}
              defaultExpanded
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    style={{
                      color: "#3fcff3",
                    }}
                  >
                    {consultRecordObj &&
                      consultRecordObj.record &&
                      consultRecordObj.record.isVideoCallConsult
                      ? "Videocall"
                      : ""}{" "}
                    Patient Information
                  </Typography>
                </div>
              </ExpansionPanelSummary>
              <Divider />
              {pendingVideoCall && pendingVideoCall.length ? (
                <Card style={{ margin: 10, padding: 10 }}>
                  {pendingVideoCall.map(videocall => {
                    return (
                      <Typography variant="subtitle1" color="textSecondary">
                        Requested date and time for videocall:{" "}
                        <span style={{ color: "#000000", marginLeft: 10 }}>
                          {" "}
                          {moment(videocall.scheduledDateTime).format("LLL")} (
                          {getCurrentTimezoneName()})
                        </span>
                        {moment(videocall.scheduledDateTime) < moment() ? (
                          <span style={{ color: "red", marginLeft: 5 }}>
                            (Date and Time expired)
                          </span>
                        ) : (
                          ""
                        )}
                      </Typography>
                    )
                  })}
                </Card>
              ) : (
                ""
              )}
              <ExpansionPanelDetails className={classes.patientDetailsCardSm}>
                {<ConsultBtns
                  {...this.props}
                  {...consultRecordObj}
                  permissions={permissions}
                  showChatBtn={this.showChatBtn}
                  changeConsultStatus={this.changeConsultStatus}
                  consultStatus={this.state.consultStatus}
                  isLoading={isFetchingConsult}
                  generatePdf={() =>
                    this.handlePdfExport(consultRecordObj.record)
                  }
                  consultDetails={this.state.consultDetails}
                  consultRecordObj={consultRecordObj}
                  didVideoCall={this.state.didVideoCall}
                  doctorDetails={this.state.doctorDetails}
                  pendingVideoCall={pendingVideoCall}
                />}
                <PatientDetails
                  record={consultRecordObj.record}
                  isFetching={fetchingAllPatientConsults}
                  role={role}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {consultRecordObj.record && isChatBtnShown ? (
              <ExpansionPanel elevation={5} className={classes.rootSm}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#3fcff3",
                      }}
                    >
                      Chatroom
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails
                  className={classes.patientDetailsCardSm}
                  style={{
                    padding: 0,
                  }}
                >
                  <Chat
                    record={consultRecordObj.record}
                    permissions={permissions}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : null}
            {/* {role === "doctor" &&
              consultRecordObj.record &&
              !fetchingAllPatientConsults && (
                <ExpansionPanel elevation={5} className={classes.rootSm}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography
                        variant="caption"
                        style={{
                          color: "#3fcff3",
                        }}
                      >
                        {!allPatientConsults.length
                          ? `You have not yet treated ${consultRecordObj.record.patientname.toUpperCase()} or ${pronoun} family members before`
                          : allPatientConsults.length === 1 &&
                            consultRecordObj.record.status !== "pending"
                            ? `There are no other consults of ${consultRecordObj.record.patientname.toUpperCase()} or ${pronoun} family members treated by you`
                            : `Previous consults of
${consultRecordObj.record.patientname.toUpperCase()} and ${pronoun} family members treated by you`}{" "}
                      </Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails
                    className={classes.patientDetailsCardSm}
                  >
                    <List style={{ maxHeight: "50vh", overflow: "auto" }}>
                      {allPatientConsults
                        .filter(({ id }) => id !== consultRecordObj.record.id)
                        .map(({ createdAt, patientname, id }) => {
                          const timeElapsed = formatDistance(
                            new Date(createdAt),
                            new Date(),
                            {
                              includeSeconds: true,
                              addSuffix: true,
                            }
                          )

                          return (
                            <ListItem
                              className={classes.listItem}
                              key={id}
                              button
                              component={Link}
                              to={`/Consult/${id}/show`}
                              target="__blank"
                              rel="noopener noreferrer"
                            >
                              <ListItemText
                                primary={patientname}
                                secondary={timeElapsed}
                                className={classes.listItemText}
                              />
                            </ListItem>
                          )
                        })}
                    </List>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )} */}
            {consultRecordObj.record &&
              (consultRecordObj.record.status !== "pending" ||
                this.state.consultStatus.accepted) ? (
              <ExpansionPanel
                elevation={5}
                className={classes.rootSm}
                defaultExpanded
              >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        color: "#3fcff3",
                      }}
                    >
                      Prescribe
                    </Typography>
                  </div>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails className={classes.patientDetailsCardSm}>
                  <Form
                    record={consultRecordObj.record}
                    changePdfGenBtn={this.changePdfGenBtn}
                    handleDataChange={this.handleDataChange}
                    generatePdf={this.handlePdfExport}
                    hideActions={hideActions}
                  />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {this.state.showDownloadBtn ||
                      consultRecordObj.record.hasPdf ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() =>
                          // this.handlePdfDownload(consultRecordObj.record)
                          this.setState({ openPdfAddressModal: true })
                        }
                      >
                        Export Pdf
                        <PictureAsPdfIcon className={classes.rightIcon} />
                      </Button>
                    ) : null}
                        
                    {!hideActions &&
                      this.state.doctorDetails &&
                      this.state.doctorDetails.dea &&
                      this.state.doctorDetails.practice && 
                      !this.state.doctorDetails.practice.disabledPrescription ? (
                      <PrescribeButton
                        isShown={
                          (consultRecordObj.record.status !== "pending" ||
                            this.state.consultStatus.accepted)
                        }
                        record={consultRecordObj.record}
                        showLoadingSpinner={this.handleLoadingSpinner}
                        isLoading={loading}
                      />
                    ) : (
                      ""
                    )}
                    <PdfAddressModal
                      isOpen={openPdfAddressModal}
                      handleOpen={() => { }}
                      handleConfirmation={() => { }}
                      handleClose={this.handlePdfAddressModalClose}
                      isLoading={loading}
                      handlePdfDownload={() => {
                        this.handlePdfDownload(consultRecordObj.record)
                      }}
                      handleInputAddressChange={
                        this.handleConsultAddressInputChange
                      }
                      handlePdfExport={this.handlePdfExport}
                    />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : null}
            <Modal
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
              }}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              open={this.state.showModal}
            // onClose={() => setOpenModal(false)}
            >
              <div
                style={{
                  backgroundColor: "white",
                  border: "1px solid #000",
                }}
              >
                <Typography color="textSecondary" gutterBottom>
                  <h3>
                    Looks like you're done video call with this consult but
                    forgot to mark completed.
                  </h3>
                  <h4>
                    * Video call fee will added only if video call status is
                    completed.
                  </h4>
                  <h3>
                    Are you you want to mark the video call as completed ?
                  </h3>
                </Typography>
                &nbsp;
                <span>
                  &nbsp;
                  <Button
                    style={{ backgroundColor: "green" }}
                    fontSize="small"
                    onClick={this.markCompleted}
                  >
                    Mark completed
                  </Button>
                </span>
                &nbsp;&nbsp;
                <span>
                  <Button
                    style={{ backgroundColor: "red" }}
                    fontSize="small"
                    onClick={this.markIncompleted}
                  >
                    cancel
                  </Button>
                </span>
              </div>
            </Modal>
          </div>
        }
        medium={
          <div className={classes.wrapper}>
            <div className={classes.rootMd}>
              <Card className={classes.patientDetailsCardMd}>
                {pendingVideoCall && pendingVideoCall.length ? (
                  <Card style={{ margin: 10, padding: 10 }}>
                    {pendingVideoCall.map(videocall => {
                      return (
                        <Typography variant="subtitle1" color="textSecondary">
                          Requested date and time for videocall:{" "}
                          <span style={{ color: "#000000", marginLeft: 10 }}>
                            {" "}
                            {moment(videocall.scheduledDateTime).format(
                              "LLL"
                            )}{" "}
                            ({getCurrentTimezoneName()})
                          </span>
                          {moment(videocall.scheduledDateTime) < moment() ? (
                            <span style={{ color: "red", marginLeft: 5 }}>
                              (Date and Time expired)
                            </span>
                          ) : (
                            ""
                          )}
                        </Typography>
                      )
                    })}
                  </Card>
                ) : (
                  ""
                )}
                {<ConsultBtns
                  {...this.props}
                  {...consultRecordObj}
                  permissions={permissions}
                  showChatBtn={this.showChatBtn}
                  changeConsultStatus={this.changeConsultStatus}
                  consultStatus={this.state.consultStatus}
                  isLoading={isFetchingConsult}
                  generatePdf={() =>
                    this.handlePdfExport(consultRecordObj.record)
                  }
                  consultDetails={this.state.consultDetails}
                  consultRecordObj={consultRecordObj}
                  didVideoCall={this.state.didVideoCall}
                  doctorDetails={this.state.doctorDetails}
                  pendingVideoCall={pendingVideoCall}
                />}
                <PatientDetails
                  record={consultRecordObj.record}
                  isFetching={fetchingAllPatientConsults}
                  role={role}
                />
              </Card>
              {consultRecordObj.record && isChatBtnShown ? (
                <Chat
                  record={consultRecordObj.record}
                  permissions={permissions}
                />
              ) : null}
            </div>
            {/* {role === "doctor" &&
              consultRecordObj.record &&
              !fetchingAllPatientConsults && (
                <Card
                  className={classes.margin}
                  style={{ padding: 10, textAlign: "center" }}
                >
                  <h4>
                    {!allPatientConsults.length
                      ? `You have not yet treated ${consultRecordObj.record.patientname.toUpperCase()} or ${pronoun} family members before`
                      : allPatientConsults.length === 1 &&
                        consultRecordObj.record.status !== "pending"
                        ? `There are no other consults of ${consultRecordObj.record.patientname.toUpperCase()} or ${pronoun} family members treated by you`
                        : `Previous consults of
                ${consultRecordObj.record.patientname.toUpperCase()} and ${pronoun} family members treated by you`}
                  </h4>
                  <List style={{ maxHeight: "50vh", overflow: "auto" }}>
                    {allPatientConsults
                      .filter(({ id }) => id !== consultRecordObj.record.id)
                      .map(({ createdAt, patientname, id }) => {
                        const timeElapsed = formatDistance(
                          new Date(createdAt),
                          new Date(),
                          {
                            includeSeconds: true,
                            addSuffix: true,
                          }
                        )

                        return (
                          <ListItem
                            className={classes.listItem}
                            key={id}
                            button
                            component={Link}
                            to={`/Consult/${id}/show`}
                            target="__blank"
                            rel="noopener noreferrer"
                          >
                            <ListItemText
                              primary={patientname}
                              secondary={timeElapsed}
                              className={classes.listItemText}
                            />
                          </ListItem>
                        )
                      })}
                  </List>
                </Card>
              )} */}
            {consultRecordObj.record &&
              (consultRecordObj.record.status !== "pending" ||
                this.state.consultStatus.accepted) ? (
              <div className={classes.margin}>
                <Form
                  record={consultRecordObj.record}
                  changePdfGenBtn={this.changePdfGenBtn}
                  handleDataChange={this.handleDataChange}
                  generatePdf={this.handlePdfExport}
                  hideActions={hideActions}
                />

                <Card
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 55,
                    marginTop: 50,
                    marginBottom: 20,
                  }}
                >
                  {this.state.showDownloadBtn ||
                    consultRecordObj.record.hasPdf ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() =>
                        // this.handlePdfDownload(consultRecordObj.record)
                        this.setState({ openPdfAddressModal: true })
                      }
                    >
                      Export Pdf
                      <PictureAsPdfIcon className={classes.rightIcon} />
                    </Button>
                  ) : null}
                  {consultRecordObj.record.consultDiagnosisData &&
                    !consultRecordObj.record.hasPdf ? (
                    <Chip
                      variant="outlined"
                      size="medium"
                      label="Save the consult impression once again to generate PDF"
                    />
                  ) : null}

                  {!hideActions &&
                    this.state.doctorDetails &&
                    this.state.doctorDetails.dea &&
                    this.state.doctorDetails.practice && 
                    !this.state.doctorDetails.practice.disabledPrescription ? (
                    <PrescribeButton
                      isShown={
                        consultRecordObj.record.status !== "pending" ||
                        this.state.consultStatus.accepted
                      }
                      record={consultRecordObj.record}
                      showLoadingSpinner={this.handleLoadingSpinner}
                      isLoading={loading}
                    />
                  ) : (
                    ""
                  )}
                </Card>
                <PdfAddressModal
                  isOpen={openPdfAddressModal}
                  handleOpen={() => { }}
                  handleConfirmation={() => { }}
                  handleClose={this.handlePdfAddressModalClose}
                  isLoading={loading}
                  handlePdfDownload={() => {
                    this.handlePdfDownload(consultRecordObj.record)
                  }}
                  handleInputAddressChange={
                    this.handleConsultAddressInputChange
                  }
                  handlePdfExport={this.handlePdfExport}
                />
              </div>
            ) : null}
            <Modal
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
              }}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
              open={this.state.showModal}
            // onClose={() => setOpenModal(false)}
            >
              <div
                style={{
                  backgroundColor: "white",
                  border: "1px solid #000",
                }}
              >
                <Typography color="textSecondary" gutterBottom>
                  <h3>
                    Looks like you're done video call with this consult but
                    forgot to mark completed.
                  </h3>
                  <h4>
                    * Video call fee will added only if video call status is
                    completed.
                  </h4>
                  <h3>
                    Are you you want to mark the video call as completed ?
                  </h3>
                </Typography>
                &nbsp;
                <span>
                  &nbsp;
                  <Button
                    style={{ backgroundColor: "green" }}
                    fontSize="small"
                    onClick={this.markCompleted}
                  >
                    Mark completed
                  </Button>
                </span>
                &nbsp;&nbsp;
                <span>
                  <Button
                    style={{ backgroundColor: "red" }}
                    fontSize="small"
                    onClick={this.markIncompleted}
                  >
                    cancel
                  </Button>
                </span>
              </div>
            </Modal>
          </div>
        }
      />
    )
  }
}

export default withStyles(styles)(MainShowPage)
