import React from "react"
import {
  ReferenceInput,
  FormDataConsumer,
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  Link,
  SaveButton,
  AutocompleteInput,
  BooleanInput
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validatePractice,
  validateRequiredPhoneNumField,
} from "../util/validation"

export const DoctorIcon = null

export const DoctorCreate = props => (
  // console.log('\ninvoked\n'),
  <Create
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    {...props}
    style={{ marginTop: "3rem" }}
  >
    <TabbedForm
      submitOnEnter={false}
      validate={validatePhone}
      toolbar={<SaveButton id="doctorCreate" style={{ marginTop: 10 }} />}
      redirect={`/Doctor`}
      defaultValue={
        props.permissions === "practiceAdmin"
          ? {
            practiceId: localStorage.getItem("adminPracticeId")
          }
          : {}
      }
    >
      <FormTab label="Basic Info">
        <TextInput source="firstName" validate={validateFirstName} />
        <TextInput source="lastName" validate={validateLastName} />
        <TextInput source="email" type="email" validate={validateEmail} parse={s => s.toLowerCase()} />
        <TextInput source="phone" validate={validateRequiredPhoneNumField} />
        <div>
          <BooleanInput label="Is certified assistant?" source="isAssistant" />
          <p
            style={{
              width: "90%",
              fontSize: "12px",
              marginTop: 10,
              marginBottom: 18,
              color: "#aeaeae",
            }}
          >
            Ex:<span style={{ marginLeft: 2, color: "#828282" }}>Physician assistant or Nurse practitioner</span>
          </p>
        </div>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => {
            if (formData && formData.isAssistant) {
              return <div>
                <TextInput source="assistantTitle" label="Assistant title" />
                <p
                  style={{
                    width: "90%",
                    fontSize: "12px",
                    marginTop: 10,
                    marginBottom: 18,
                    color: "#aeaeae",
                  }}
                >
                  Ex: PA-C
                </p>
              </div>;
            } else {
              return <></>;
            }
          }}
        </FormDataConsumer>
        {props.permissions != "practiceAdmin" &&
          <>
            <ReferenceInput
              label="Practice"
              source="practiceId"
              reference="Practice"
              validate={validatePractice}
              id="practiceList"
              filterToQuery={searchText => {
                // console.log(searchText)
                if (searchText.length > 3) {
                  return { name: searchText }
                } else {
                  return {}
                }
              }}>
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    disablePortal: true,
                  }
                }}
                optionText="name"
                allowEmpty={true}
              />
            </ReferenceInput>
            <p
              style={{
                width: "90%",
                fontSize: "10px",
                marginTop: 18,
                marginBottom: 18,
                color: "#aeaeae",
              }}
            >
              To clear practice: cmd+a or ctlr+a then backspace
            </p>
          </>
        }
      </FormTab>
    </TabbedForm>
  </Create >
)
