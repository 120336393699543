import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
    query fetchAdmin($id: ID!) {
        Admin(id: $id){
          id
          firstName
          lastName
          name
          enabled
          practiceId
          email
          phone
        }
    }
`

const fetchAdmin = async practiceAdminId => {
  // console.log(practiceAdminId)
  const variables = { id: practiceAdminId }
  //  console.log("variables ",variables)
  const practiceAdminDetails = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })

  return practiceAdminDetails
}

export default fetchAdmin
