import React from "react"
import { Link } from "react-admin"

import FullNameField from "./FullNameField"

const PatientLinkField = props => (
  <Link to={`/Consult/${props.record.id}/show`}>
    <FullNameField {...props} />
  </Link>
)

PatientLinkField.defaultProps = {
  source: "patientname",
  label: "Patient Name",
  addLabel: true,
}

export default PatientLinkField
