import React from "react"
import "font-awesome/css/font-awesome.min.css"

const LoadingSpinner = () => (
  <div>
    <i className="fa fa-spinner fa-spin" /> Please wait...
  </div>
)

export default LoadingSpinner
