import React, { useEffect, useState } from "react"
import compose from "recompose/compose"
import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CustomerIcon from "@material-ui/icons/PersonAdd"
import Divider from "@material-ui/core/Divider"
import { Link } from "react-router-dom"
import { translate } from "react-admin/lib"
import { GET_LIST, UPDATE, CREATE } from "react-admin"
import { connect } from "react-redux"
import CardIcon from "./CardIcon"
import Registration from "../../doctorLogin/index"
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
  main: {
    flex: "1",
    marginLeft: "1em",
    marginTop: 20,
  },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
  },
  value: {
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    paddingRight: 0,
  },
})

const DoctorList = ({ doctors, translate, classes }) => {
  
  const [showWithDetails, setShowWithDetails]= useState(false);
  const [doctorList, setDoctorList] = useState(doctors);

  useEffect(()=>{
    setDoctorList(doctors);
    // console.log(doctors)
  },[doctors]);

  const handleShowWithAllDetails = ()=>{
    const newShowDetails = !showWithDetails;
    // console.log(newShowDetails)
    if(newShowDetails){
      let newdoctors = doctors.filter((doctor)=>((doctor.stripeId != null || "") ||((doctor.practice && doctor.practice.stripeId) != null || "") ) );
      setDoctorList(newdoctors);
    }else{
      setDoctorList(doctors);
    }

    setShowWithDetails(!showWithDetails);
  }

  return (
  <div className={classes.main}>
    <CardIcon Icon={CustomerIcon} bgColor="#fd9710" />
    <Card className={classes.card}>
      <Typography className={classes.title} color="textSecondary">
        New Doctors
      </Typography>
      <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", flexDirection:"row"}}>
      <FormControlLabel
            control={
              <Switch
                checked={showWithDetails}
                onChange={()=>{handleShowWithAllDetails()}}
                value={showWithDetails}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                color= {showWithDetails? "primary" : "default"}
                size="small"
              />
            }
            label="With all information"
            style={{color: "grey"}}
          />
      <Typography variant="headline" component="h2" className={classes.value}>
        {doctorList.length}
      </Typography>
      </div>
      <Divider />
      <List style={{ maxHeight: "50vh", overflow: "auto" }}>
        {doctorList.map(doctor => (
          <ListItem
            button
            to={`/Doctor/${doctor.id}`}
            component={Link}
            key={doctor.id}
          >
            {/* <Avatar
                            src={`${record.avatar}?size=32x32`}
                            className={classes.avatar}
                        /> */}
            <ListItemText
              primary={`${doctor.name}`}
              className={classes.listItemText}
            />
          </ListItem>
        ))}
      </List>
    </Card>
  </div>
)}

const enhance = compose(
  withStyles(styles),
  translate
)

export default connect()(enhance(DoctorList))
