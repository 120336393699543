import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const mutationCreateUnavailability = gql`
mutation createUnavailability(
  $doctorId: String,
  $day: String,
  $datetime: String,
  $dateTimeString: String
  $slots: TimeInput
){
  createUnavailability(
    doctorId: $doctorId,
    day: $day,
    datetime: $datetime,
    dateTimeString:$dateTimeString
    slots: $slots
  ){
    id
  }
}
`

const createUnavailability= async variables => {
  const createUnavailabilityResult = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: mutationCreateUnavailability,
      variables: variables,
      fetchPolicy: "no-cache",
    })
    return mutation
  })

  return createUnavailabilityResult
}

export default createUnavailability