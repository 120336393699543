import React from "react"
import pure from "recompose/pure"

const FullNameField = ({ record = {}, size }) => {
  let firstName = record.patientname
  return (
    <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
      {firstName}
    </div>
  )
}

const PureFullNameField = pure(FullNameField)

export default PureFullNameField
