import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const mutationUpdateVideoCall = gql`
  mutation updateVideoCall($id: ID!, $endTime: String, $callStatus: String){
        updateVideoCall(
          id: $id,
          endTime: $endTime
          callStatus: $callStatus
        ){
          id
          lastActive
          startTime
          endTime
          duration
          callStatus
        }
      }
`

const updateVideoCall = async variables => {
  const updateVideoCallResult = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdateVideoCall,
      variables: variables,
      fetchPolicy: "no-cache",
    })
    return mutation
  })

  return updateVideoCallResult
}

export default updateVideoCall