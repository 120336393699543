import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import LoadingSpinner from "../Components/LoadingSpinner"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    width: "100%",
  },
})

class PdfAddressModal extends Component {
  state = {
    value: "",
  }

  handleOnChange = event => {
    this.setState({ value: event.target.value })
    this.props.handleInputAddressChange(event.target.value)
  }

  render() {
    const {
      classes,
      isOpen,
      handleClose,
      handleConfirmation,
      isLoading,
      handlePdfDownload,
      handleInputAddressChange,
      handlePdfExport
    } = this.props
    const { value } = this.state
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onBackdropClick={() => {
            this.setState({ value: "" })
            return handleClose()
          }}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="subheading" id="simple-modal-description">
              Add an additional information
              </Typography>
            <HighlightOffIcon style={{ position: "absolute", top: 10, right: 10, backgroundColor: "primary" }} onClick={() => { this.setState({ value: "" }); return handleClose() }} />
            <div>
              <TextField
                id="closedUnpaidReason"
                label="additional info"
                placeholder="eg: patient address or other info"
                multiline
                className={classes.textField}
                margin="normal"
                rowsMax="4"
                onChange={this.handleOnChange}
                value={value}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!isLoading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!value}
                    className={classes.button}
                    onClick={() => {
                      this.setState({
                        value: "",
                      })

                      return handlePdfExport(true)
                    }}
                  >
                    Add and dowload PDF
                    </Button>
                ) : (
                    <LoadingSpinner />
                  )}
                <Typography variant="subheading" id="simple-modal-description" style={{ textAlign: "center" }}>(Or)</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => { this.setState({ value: "" }); return handlePdfDownload() }}
                >
                  Download PDF
                  </Button>
              </div>
            </div>
            <PdfAddressModalWrapped />
          </div>
        </Modal>
      </div>
    )
  }
}

PdfAddressModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

// We need an intermediary variable for handling the recursive nesting.
const PdfAddressModalWrapped = withStyles(styles)(PdfAddressModal)

export default PdfAddressModalWrapped
