import React, { Component } from "react"
import { ShowController } from "react-admin/lib"
import { Link } from "react-router-dom"
import KinesisVideoCall from "./KinesisVideoCall"
import logo from "../Images/dermio-logo.svg"

const ConsultTitle = () => {
  return (
    <Link to="/">
      <img src={logo} alt="dermio-logo" style={{ paddingTop: "0.3em" }} />
    </Link>
  )
}


class VideoCallPage extends Component {
  render() {
    return (
      <ShowController title={<ConsultTitle />} {...this.props}>
        {controllerProps => {

          if (!controllerProps.record) return <h3> Loading </h3>

          return (
            <KinesisVideoCall consultRecordObj={controllerProps} {...this.props} />
          )
        }}
      </ShowController>
    )
  }
}

export default VideoCallPage
