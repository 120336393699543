import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
} from "react-admin"
import { Auth } from "aws-amplify"

export default (type, params) => {
  if (type === AUTH_LOGOUT) {
    console.log("LOGOUT ON ERROR CAN BE POSSIBLE!!", "authProvider.js")

    return Auth.signOut().then(res => {
      localStorage.removeItem("userId")
      localStorage.removeItem("doctorDetails")
      localStorage.removeItem("userPracticeId")
      localStorage.removeItem("adminPracticeId")
      window.location.href = "/"
    })
  }

  if (type === AUTH_GET_PERMISSIONS) {
    return Auth.currentSession().then(res => {
      const roles = res.idToken.payload["cognito:groups"]
      // console.log("roles", roles)
      const role = roles[0]
      return role
    })
  }
  if (type === AUTH_CHECK) {
    return Auth.currentSession().then(res => {
      // console.log("res", res)
      const sub = res.idToken.payload["sub"]
      localStorage.setItem("userId", sub)
    })
  }
  if (type === AUTH_ERROR) {
    return Auth.currentSession()
  }
  return Promise.reject("Unkown method")
}
