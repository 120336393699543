import React, { Fragment } from "react"
import { ImageField, ImageInput } from "react-admin"
import AwsImageComponent from "./AwsImageComponent"

const UserAvatar = ({ record, permissions }) => {
  const styles = {
    height: "100px",
    width: "100px",
    display: "block",
  }

  let url = ""

  if (record.userAvatarFile) {
    url = `https://${record.userAvatarFile.bucket}.s3.us-east-1.amazonaws.com/${record.userAvatarFile.key}`
  }

  return (
    <Fragment>
      {record.userAvatarFile && (
        <AwsImageComponent
          style={styles}
          src={url}
          componentFrom="practiceLogo"
          alt="user Avatar"
        />
      )}
      <ImageInput
        source="userAvatarFile"
        label={
          record.userAvatarFile ? "Update user avatar" : "Select user avatar"
        }
        accept="image/*"
        placeholder={<p>Drop your photo here</p>}
      >
        <ImageField source="image" title="title" />
      </ImageInput>
    </Fragment>
  )
}

export default UserAvatar
