import React, { Component } from "react"
import StatusButton from "../Components/StatusButton"

class AdminConsultBtns extends Component {
  componentDidMount = () => {
    const { record, showChatBtn } = this.props
    // console.log(this.props);

    if (record.status !== "pending") {
      showChatBtn()
    }
  }

  render() {
    const { record } = this.props

    return <StatusButton isDisabled={true} btnText={record.status} />
  }
}

export default AdminConsultBtns
