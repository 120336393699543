import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query fetchChatResponses($doctorId: ID!) {
    allChatResponses(filter: { doctorId: { eq: $doctorId } }) {
      items {
        id
        name
        body
        createdBy
      }
    }
    allAdminChatResponses: allChatResponses(
      filter: { createdBy: { eq: "admin" } }
    ) {
      items {
        id
        name
        body
        createdBy
      }
    }
  }
`

const fetchChatResponses = async doctorId => {
  const variables = { doctorId }

  const chatResponse = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })

  return chatResponse
}

export default fetchChatResponses
