import React from "react"
import {
  Filter,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  LongTextInput,
  CloneButton,
  CardActions,
  ListButton,
  RefreshButton,
  Link,
  SaveButton,
  Responsive,
  SimpleList,
  ReferenceField
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import { validateText } from "../util/validation"

export const CustomFilter = props => (
    <Filter {...props}>
      <TextInput label="Name" source="name" />
    </Filter>
  )
  
export const ListAdmin = ({ permissions, ...props }) => (
    <List
      {...props}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
    //   filter={filterFunc()}
      filters={<CustomFilter />}
      style={{ marginTop: "4rem" }}
      bulkActionButtons={false}
    >
      <Responsive
        small={<SimpleList primaryText={record => record.name} />}
        medium={
          <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="practiceName" label="Practice" />
            {/* <ReferenceField label="Practice" source="practiceId" reference="Practice">
                <TextField source="name" />
            </ReferenceField> */}
            <EditButton />
          </Datagrid>
        }
      />
    </List>
  )