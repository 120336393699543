import React, { Component, Fragment } from "react"
import { Responsive, Title } from "react-admin"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import { showNotification } from "react-admin"
import { Link } from "react-router-dom"
import { Auth } from "aws-amplify"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import gql from "graphql-tag"
import Typography from "@material-ui/core/Typography"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import DoctorConsults from "./DoctorConsults"
import Notifications from "./Notifications"
import SharePatientPortalLink from "../../Components/SharePatientPortalLink"
import Appsync from "../../util/client"
import logo from "../../Images/dermio-logo.svg"
import "./css/docDashboard.css"
import decryptDash from "../../util/decryptDash"
import moment from "moment"
import { AcceptedVideoCalls, PendingVideoCalls } from "./DashBoardVideoCall"
import VideoCallFeeNotification from "./VideoCallFeeNotification"
import updatePrescribe from "../../queries/updatePrescribe"
import fetchDoctorDetails from "./query/fetchDoctorDetails"
import fetchPendingConsults from "./query/fetchPendingConsults"

import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"

const ExpansionPanel = withStyles({
  root: {
    margin: "1rem 0",
  },
  expanded: {},
})(MuiExpansionPanel)

const client = Appsync()
const sortConsults = consults => {
  return consults.sort((a, b) => {
    return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0
  })
}

const query = gql`
  query doctorConsults(
    $id: ID!
    $receiverId: String!
    $scheduledDateString: String
    $scheduledDateTime: String
    $doctorId: String
  ) {
    allAcceptedConsultsByDoc: allDoctorConsults(
      status: "accepted",
      filter: {},
      doctorId: $id
    ) {
      items {
        id
        status
        patientId
        doctorId
        patientname
        createdAt
        state
        isVideoCallConsult
        cardToken
        chargeId
        insuranceMemberId
        insuranceUrl1
      }
    }
    allDoctorConsultsUnPaid: allDoctorConsults(
      status: "closed_unpaid",
      filter: {
        createdAt:{ge: "${moment()
          .subtract(1, "months")
          .format()}"}
      },
        doctorId: $id 
    ) {
      items {
        id
        status
        patientId
        doctorId
        patientname
        state
        createdAt
        cardToken
        isVideoCallConsult
      }
    }
    allDoctorConsultsPaid: allDoctorConsults(
      status: "closed_paid",
      filter: {
        createdAt:{ge: "${moment()
          .subtract(1, "months")
          .format()}"}
      },
        doctorId: $id 
    ) {
      items {
        id
        status
        patientId
        doctorId
        patientname
        state
        createdAt
        cardToken
        isVideoCallConsult
      }
    }
    allDoctorConsultsInsurance: allDoctorConsults(
      status: "closed_insurance",
      filter: {
        createdAt:{ge: "${moment()
          .subtract(1, "months")
          .format()}"}
      },
        doctorId: $id 
    ) {
      items {
        id
        status
        patientId
        doctorId
        patientname
        state
        createdAt
        cardToken
        isVideoCallConsult
      }
    }
    allChatsWithReceiverIdIndex(
      seen: false,
      senderRole: "patient",
      receiverId: $receiverId
    ) {
      url
      id
      message
      consultId
      senderRole
      receiverRole
      createdAt
      name
      read
    }
    todayVideoCalls:   allVideoCalls(
      doctorId: $doctorId,
      filter:{
        scheduledDateString:{eq: $scheduledDateString}
      }
    ){
      items{
          id
      doctorId
      endTime
      consultId
      createdAt
      patientId
      callStatus
      scheduledDateString
      scheduledDateTime
      consultName
      doctorName
      actualDateTime
      inviteStatus
    }
    }
    upcomingVideoCalls:  allVideoCalls(
      doctorId: $doctorId,
      filter:{
        scheduledDateTime:{
          ge: $scheduledDateTime
        }
      }
    ){
      items{
          id
      doctorId
      endTime
      consultId
      createdAt
      patientId
      callStatus
      scheduledDateString
      scheduledDateTime
      consultName
      doctorName
      actualDateTime
      inviteStatus
    }
    }
  }
`

const styles = {
  flex: { display: "flex", marginTop: 10 },
  flexColumn: { display: "flex", flexDirection: "column", marginTop: "2em" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
  shareBtns: {
    display: "flex",
    justifyContent: "center",
    // marginTop: "2rem",
  },
  modal: {
    backgroundColor: "white",
    padding: "10px",
    justifyContent: "center",
    borderRadius: "15px",
    margin: "10%",
  },
}

class Dashboard extends Component {
  state = {
    consults: {
      allAcceptedConsultsByDoc: [],
      allPendingConsultsNearDoc: [],
      allDoctorConsultsClosed: [],
    },
    doctorDetails: {},
    unseenMsgs: [],
    todayVideoCalls: [],
    upcomingVideoCalls: [],
    prescribeInfoDisplayed: null,
    allPendingConsultsNearDoc: [],
    isFetchingPendingConsults: false,
    isFetchingAcceptedConsults: false,
  }

  componentDidMount = () => {
    this.fetchDashboardData()
  }

  fetchDashboardData = async () => {
    // console.log("hererere")
    this.setState({ enabled: false })
    let doctorId = localStorage.getItem("userId")
    // doctorId = "ddf4f8fc-bb1a-48a1-affe-11abd6fa7d7e"
    if (!doctorId) {
      try {
        const currentSessionRes = await Auth.currentSession()

        doctorId = currentSessionRes.idToken.payload["sub"]
      } catch (e) {
        console.error(e)
      }
    }

    let current = new Date().toISOString()
    let d = new Date()
    d.setDate(d.getDate() - 30)
    let previous = d.toISOString()
    const variables = {
      id: doctorId,
      receiverId: doctorId,
      limit: 50,
      status: "accepted",
      startDate: previous,
      endDate: current,
      scheduledDateString: moment().format("DD/MM/YYYY"),
      scheduledDateTime: moment(),
      doctorId,
    }

    // *********Fetch doctor details first********
    const doctorDetailsResult = await fetchDoctorDetails(doctorId)
    const { doctorDetails } = doctorDetailsResult.data
    // console.log("doctorDetails ", doctorDetails)
    const {
      practice: practiceDetails,
      enabled: isDoctorEnabled,
      licensedStates,
    } = doctorDetails

    if (Object.keys(practiceDetails).some(k => practiceDetails[k] === null)) {
      return this.props.push(`/Practice/${practiceDetails.id}`)
    }

    if (isDoctorEnabled) {
      localStorage.setItem("doctorDetails", JSON.stringify(doctorDetails))
      // Should set doctordetails here
      this.setState({
        doctorDetails,
        prescribeInfoDisplayed: doctorDetails.prescribeInfoDisplayed,
        isFetchingPendingConsults: true,
        isFetchingAcceptedConsults: true,
      })

      // *********Fetch all pending consults************
      const allPendingConsultsResult = await fetchPendingConsults(doctorId)
      const {
        allPendingConsultsNearDocResult,
        allPendingDoctorConsultsResult,
      } = allPendingConsultsResult.data

      this.fetchAcceptedClosedConsults(variables)

      let allPendingConsultsNearDoc = []
      let allPendingDoctorConsults = []

      if (licensedStates) {
        if (
          allPendingDoctorConsultsResult.items &&
          allPendingDoctorConsultsResult.items.length
        ) {
          allPendingDoctorConsults = allPendingDoctorConsultsResult.items
          allPendingDoctorConsults = allPendingDoctorConsults.filter(
            consult => {
              if (consult.cardToken || consult.cardDeclined === true) {
                return true
              } else if (
                consult.practiceId === process.env.REACT_APP_PRACTICE_ID &&
                consult.insuranceFile1 != null
              ) {
                return true
              } else {
                return false
              }
            }
          )
        }

        allPendingConsultsNearDoc = allPendingConsultsNearDocResult.items.filter(
          consult => {
            if (
              licensedStates.indexOf(consult.state) !== -1 &&
              (consult.cardToken || consult.cardDeclined === true)
            ) {
              return true
            } else if (
              consult.doctorId &&
              consult.doctorId.startsWith("dermio-") &&
              consult.insuranceFile1 != null
            ) {
              return true
            }
          }
        )
        // console.log("dermio:consults ", allPendingConsultsNearDoc)

        /*Opt out from dermio condition check if optedOut:true set dermio consults array to []*/

        if (doctorDetails && doctorDetails.optedOut) {
          allPendingConsultsNearDoc = []
        }

        if (allPendingDoctorConsults && allPendingDoctorConsults.length) {
          allPendingConsultsNearDoc = [
            ...allPendingDoctorConsults,
            ...allPendingConsultsNearDoc,
          ]
          // console.log("allPendingConsultsNearDoc ", allPendingConsultsNearDoc)
          allPendingConsultsNearDoc = await decryptDash(
            allPendingConsultsNearDoc
          )

          this.setState({
            allPendingConsultsNearDoc: sortConsults(allPendingConsultsNearDoc),
            isFetchingPendingConsults: false,
          })
        } else {
          // console.log("esle part")
          // console.log("allPendingConsultsNearDoc ", allPendingConsultsNearDoc)
          allPendingConsultsNearDoc = await decryptDash(
            allPendingConsultsNearDoc
          )
          allPendingConsultsNearDoc = [...allPendingConsultsNearDoc]
          this.setState({
            allPendingConsultsNearDoc: sortConsults(allPendingConsultsNearDoc),
            isFetchingPendingConsults: false,
          })
        }
      }
    } else {
      if (
        doctorDetails.firstName &&
        doctorDetails.lastName &&
        doctorDetails.dea &&
        // doctorDetails.npi &&
        doctorDetails.phone &&
        doctorDetails.email &&
        doctorDetails.licensedStates.length > 0
      ) {
        this.props.push(`/Doctor/${doctorDetails.id}/?fieldsFilled`)
      } else {
        this.props.push(`/Doctor/${doctorDetails.id}/?fieldsNotFilled`)
      }
    }
  }

  fetchAcceptedClosedConsults = async variables => {
    try {
      const cl = await client.hydrated()
      const res = await cl.query({
        query,
        variables,
        fetchPolicy: "no-cache",
      })

      // console.log(res, "********main response*********")

      let _a = res.data.todayVideoCalls.items
      let _b = res.data.upcomingVideoCalls.items
      // console.log(_a, _b, "List videoCalls")
      if (_a && _a.length) {
        _a = _a.filter(videocall => {
          return (
            videocall.inviteStatus == "accepted" &&
            videocall.callStatus == "upcoming" &&
            moment(moment(videocall.scheduledDateTime), "hh:mm:ss A")
              .add(0, "seconds")
              .add(15, "minutes") > moment()
          )
        })
      }

      this.setState({
        todayVideoCalls: _a,
        upcomingVideoCalls: _b,
      })

      let {
        items: allAcceptedConsultsByDoc,
      } = res.data.allAcceptedConsultsByDoc
      let allDoctorClosedPaid = []
      let allDoctorClosedUnPaid = []
      let allDoctorClosedInsurance = []
      let allDoctorClosedConsult = []
      allAcceptedConsultsByDoc = await decryptDash(allAcceptedConsultsByDoc)
      allDoctorClosedPaid = await decryptDash(
        res.data.allDoctorConsultsPaid.items
      )
      allDoctorClosedInsurance = await decryptDash(
        res.data.allDoctorConsultsInsurance.items
      )
      // console.log("allDoctorClosedPaid", allDoctorClosedPaid)

      allDoctorClosedUnPaid = await decryptDash(
        res.data.allDoctorConsultsUnPaid.items
      )

      // console.log("allDoctorClosedUnPaid", allDoctorClosedUnPaid)

      allDoctorClosedConsult = [
        ...allDoctorClosedUnPaid,
        ...allDoctorClosedPaid,
        ...allDoctorClosedInsurance,
      ]

      // console.log("allDoctorClosedConsult",allDoctorClosedConsult)

      const consults = {
        allAcceptedConsultsByDoc: sortConsults(allAcceptedConsultsByDoc),
        allDoctorClosedConsult: sortConsults(allDoctorClosedConsult),
      }

      let unseenMsgs = res.data.allChatsWithReceiverIdIndex.filter(chat =>
        allAcceptedConsultsByDoc.some(consult => consult.id === chat.consultId)
      )

      // mostRecentClosedPaidConsults = await Promise.all(
      //   mostRecentClosedPaidConsults.map(async pendingConsult => {
      //     let result = await decryptConsult(pendingConsult)
      //     return result
      //   })
      // )

      // mostRecentClosedUnPaidConsults = await Promise.all(
      //   mostRecentClosedUnPaidConsults.map(async pendingConsult => {
      //     let result = await decryptConsult(pendingConsult)
      //     return result
      //   })
      // )

      this.setState({
        consults,
        // doctorDetails,
        unseenMsgs,
        isFetchingAcceptedConsults: false,
      })
    } catch (e) {
      console.error(e)
      this.setState({ isFetchingAcceptedConsults: false })
    }
  }

  updatePrescribeStatus = async status => {
    let doctorId = localStorage.getItem("userId")
    const variables = {
      id: doctorId,
      canPrescribe: status,
      prescribeInfoDisplayed: true,
    }
    await updatePrescribe(variables).then(res =>
      this.setState({ prescribeInfoDisplayed: true })
    )
  }

  HoldAndChargeNotification = () => {
    return (
      <ExpansionPanel elevation={5}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <Typography variant="subtitle2">
              Notice: Payment transaction
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails>
          <Typography variant="caption">
            To ensure a successful payment transaction, we have implemented
            Stripe's "Hold and Charge" flow. We hold the respective fee amount
            in a given consult card once added, which will be on hold for 7
            days. You will be able to charge in that time period. Stripe will
            automatically release the held amount of the consult if it's not
            charged within 7 days.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }

  render() {
    const {
      doctorDetails: { enabled, videocallFee },
      consults: {
        allAcceptedConsultsByDoc,
        // allPendingConsultsNearDoc,
        allDoctorClosedConsult,
      },
      unseenMsgs,
      todayVideoCalls,
      upcomingVideoCalls,
      allPendingConsultsNearDoc,
      isFetchingAcceptedConsults,
      isFetchingPendingConsults,
    } = this.state
    // console.log("render:allPendingConsultsNearDoc ",allPendingConsultsNearDoc)
    return enabled ? (
      <Fragment>
        <Title
          title={
            <Link to="/">
              <img
                className="dashboard__img-logo"
                src={logo}
                alt="dermio-logo"
                style={{ paddingTop: "0.3em" }}
              />
            </Link>
          }
        />

        {!this.state.prescribeInfoDisplayed ? (
          <div>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={true}
            >
              <div style={styles.modal}>
                <Typography variant="subheading" id="simple-modal-description">
                  Please confirm you want E-prescribe. You will be charged $26
                  per month?
                </Typography>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.updatePrescribeStatus(true)}
                  >
                    Confirm
                  </Button>{" "}
                  &nbsp;
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.updatePrescribeStatus(false)}
                  >
                    Deny
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        ) : null}

        <Responsive
          xsmall={
            <div style={styles.flexColumn}>
              {this.HoldAndChargeNotification()}
              {todayVideoCalls && todayVideoCalls.length ? (
                <AcceptedVideoCalls
                  todayCallList={todayVideoCalls}
                  {...this.props}
                />
              ) : (
                ""
              )}
              <DoctorConsults
                consults={allPendingConsultsNearDoc}
                consultStatus="New"
                loading={isFetchingPendingConsults}
              />
              <Notifications messages={unseenMsgs} />
              <DoctorConsults
                consults={allAcceptedConsultsByDoc}
                consultStatus="Preliminary"
                loading={isFetchingAcceptedConsults}
              />
              {!videocallFee ? (
                <VideoCallFeeNotification doctor={this.state.doctorDetails} />
              ) : (
                ""
              )}
              {/*<PendingVideoCalls
                    upcomingCallList={upcomingVideoCalls}
                    fetchDashboardData={this.fetchDashboardData}
                    {...this.props}
                  />
                */}
              <div style={styles.singleCol}>
                {Object.keys(this.state.doctorDetails).length && (
                  <SharePatientPortalLink
                    url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                  >
                    <div style={styles.shareBtns}>
                      <FacebookShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <FacebookIcon size="32" round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <TwitterIcon size="32" round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <LinkedinIcon size="32" round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <WhatsappIcon size="32" round />
                      </WhatsappShareButton>
                    </div>
                  </SharePatientPortalLink>
                )}
              </div>
            </div>
          }
          small={
            <Fragment>
              <div style={styles.flexColumn}>
                {this.HoldAndChargeNotification()}
                {todayVideoCalls && todayVideoCalls.length ? (
                  <AcceptedVideoCalls
                    todayCallList={todayVideoCalls}
                    {...this.props}
                  />
                ) : (
                  ""
                )}
                <DoctorConsults
                  consults={allPendingConsultsNearDoc}
                  consultStatus="New"
                  loading={isFetchingPendingConsults}
                />
                <Notifications messages={unseenMsgs} />
                <DoctorConsults
                  consults={allAcceptedConsultsByDoc}
                  consultStatus="Preliminary"
                  loading={isFetchingAcceptedConsults}
                />
                {!videocallFee ? (
                  <VideoCallFeeNotification doctor={this.state.doctorDetails} />
                ) : (
                  ""
                )}
                {/*<PendingVideoCalls
                    upcomingCallList={upcomingVideoCalls}
                    fetchDashboardData={this.fetchDashboardData}
                    {...this.props}
                  />
                */}
                {Object.keys(this.state.doctorDetails).length && (
                  <SharePatientPortalLink
                    url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                  >
                    <div style={styles.shareBtns}>
                      <FacebookShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <FacebookIcon size="32" round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <TwitterIcon size="32" round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <LinkedinIcon size="32" round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <WhatsappIcon size="32" round />
                      </WhatsappShareButton>
                    </div>
                  </SharePatientPortalLink>
                )}
              </div>
            </Fragment>
          }
          medium={
            <div style={{ marginTop: "3em" }}>
              {this.HoldAndChargeNotification()}
              {todayVideoCalls && todayVideoCalls.length ? (
                <AcceptedVideoCalls
                  todayCallList={todayVideoCalls}
                  {...this.props}
                />
              ) : (
                ""
              )}
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <DoctorConsults
                    consults={allAcceptedConsultsByDoc}
                    consultStatus="Preliminary"
                    loading={isFetchingAcceptedConsults}
                  />
                </div>
                <div style={styles.rightCol}>
                  <DoctorConsults
                    consults={allPendingConsultsNearDoc}
                    consultStatus="New"
                    loading={isFetchingPendingConsults}
                  />
                </div>
              </div>
              <div style={styles.singleCol}>
                <Notifications messages={unseenMsgs} />
              </div>
              {!videocallFee ? (
                <VideoCallFeeNotification doctor={this.state.doctorDetails} />
              ) : (
                ""
              )}
              <div style={styles.rightCol}>
                <DoctorConsults
                  consults={allDoctorClosedConsult}
                  consultStatus="Completed (1month)"
                  loading={isFetchingAcceptedConsults}
                />
              </div>
              {/* <div style={styles.flex}>
                <div style={styles.leftCol}> */}
              {/* </div>
                <div style={styles.rightCol}> */}
              {/*<PendingVideoCalls
                    upcomingCallList={upcomingVideoCalls}
                    fetchDashboardData={this.fetchDashboardData}
                    {...this.props}
                  />
                */}
              {/* </div>
              </div> */}
              <div style={styles.singleCol}>
                {Object.keys(this.state.doctorDetails).length && (
                  <SharePatientPortalLink
                    url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                  >
                    <div style={styles.shareBtns}>
                      <FacebookShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <FacebookIcon size="32" round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <TwitterIcon size="32" round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <LinkedinIcon size="32" round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <WhatsappIcon size="32" round />
                      </WhatsappShareButton>
                    </div>
                  </SharePatientPortalLink>
                )}
              </div>
            </div>
          }
          large={
            <div style={{ marginTop: "3em" }}>
              {this.HoldAndChargeNotification()}
              {todayVideoCalls && todayVideoCalls.length ? (
                <AcceptedVideoCalls
                  todayCallList={todayVideoCalls}
                  {...this.props}
                />
              ) : (
                ""
              )}
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <DoctorConsults
                    consults={allAcceptedConsultsByDoc}
                    consultStatus="Preliminary"
                    loading={isFetchingAcceptedConsults}
                  />
                </div>
                <div style={styles.rightCol}>
                  <DoctorConsults
                    consults={allPendingConsultsNearDoc}
                    consultStatus="New"
                    loading={isFetchingPendingConsults}
                  />
                </div>
                <div style={styles.rightCol}>
                  <Notifications messages={unseenMsgs} />
                </div>
              </div>
              {!videocallFee ? (
                <VideoCallFeeNotification doctor={this.state.doctorDetails} />
              ) : (
                ""
              )}
              {/* <div style={styles.flex}>
                <div style={styles.leftCol}> */}
              {/* </div>
                <div style={styles.rightCol}> */}
              {/*<PendingVideoCalls
                    upcomingCallList={upcomingVideoCalls}
                    fetchDashboardData={this.fetchDashboardData}
                    {...this.props}
                  />
                */}
              {/* </div>
              </div> */}

              <div style={styles.rightCol}>
                <DoctorConsults
                  consults={allDoctorClosedConsult}
                  consultStatus="Completed"
                  loading={isFetchingAcceptedConsults}
                />
              </div>
              <div style={styles.singleCol}>
                {Object.keys(this.state.doctorDetails).length && (
                  <SharePatientPortalLink
                    url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                  >
                    <div style={styles.shareBtns}>
                      <FacebookShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <FacebookIcon size="32" round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <TwitterIcon size="32" round />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <LinkedinIcon size="32" round />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        url={`${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${this.state.doctorDetails.practiceId}`}
                      >
                        <WhatsappIcon size="32" round />
                      </WhatsappShareButton>
                    </div>
                  </SharePatientPortalLink>
                )}
              </div>
            </div>
          }
        />
      </Fragment>
    ) : null
  }
}

export default connect(
  null,
  { push, showNotification }
)(Dashboard)
