import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const mutationUpdatePrescribe = gql`
  mutation updateDoctor($id: ID!, $canPrescribe: Boolean, $prescribeInfoDisplayed: Boolean){
    updateDoctor(
          id: $id,
          canPrescribe: $canPrescribe
          prescribeInfoDisplayed: $prescribeInfoDisplayed
        ){
          id
          canPrescribe
          prescribeInfoDisplayed
        }
      }
`

const updatePrescribe = async variables => {
  const updatePrescribeResult = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdatePrescribe,
      variables: variables,
      fetchPolicy: "no-cache",
    })
    return mutation
  })

  return updatePrescribeResult
}

export default updatePrescribe