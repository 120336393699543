import React from "react"
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Link,
  TextInput,
  Filter,
  ReferenceInput,
  SelectInput,
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import Pagination from "../Pagination"
import states from "../staticData/USstates"

const choiceStates = states.map(i => {
  return { id: i, name: i }
})

export const DoctorFilter = props => {
  // console.log("doctor filter", props);
  return (
    <Filter {...props}>
      <TextInput label="First Name" source="firstName" />
      <TextInput label="Last Name" source="lastName" />
      <TextInput label="Name" source="name" />
      <TextInput label="Email" source="email" />
      <TextInput label="Phone" source="phone" />
      <SelectInput
        label="licensedStates"
        source="licensedStates"
        choices={choiceStates}
      />
      {/* <TextInput label="Practice Name" source="practiceName" /> */}
      {props && props.permissions && props.permissions === "admin" && (
        <ReferenceInput
          label="Practice"
          source="practiceId"
          reference="Practice"
          // validate={validatePractice}
          // id="practiceList"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
    </Filter>
  )
}

export const DoctorList = props => {
  let filter = {}
  if (!props.permissions) return <></>
  if (props.permissions === "practiceAdmin") {
    filter.practiceId = localStorage.getItem("adminPracticeId")
    filter.role = props.permissions
  } else if (props.permissions === "staff" || props.permissions === "nurse") {
    filter.practiceId = localStorage.getItem("userPracticeId")
  }

  return (
    <List
      {...props}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      filter={filter}
      filters={<DoctorFilter permissions={"admin"} />}
      pagination={<Pagination {...props} />}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="licensedStates" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
