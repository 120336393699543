import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import LoadingSpinner from "../Components/LoadingSpinner"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    width: "100%",
  },
})

class ConfirmationModal extends Component {
  state = {
    value: "",
    isDisabled: false
  }

  handleOnChange = event => {
    this.setState({
      value: event.target.value,
    })
  }

  render() {
    const {
      classes,
      isOpen,
      handleClose,
      handleConfirmation,
      isLoading,
      status,
    } = this.props
    const { value, isDisabled } = this.state
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onBackdropClick={() => {
            this.setState({
              value: "",
            })
            return handleClose()
          }}
        >
          <div style={getModalStyle()} className={classes.paper}>
            <Typography variant="subheading" id="simple-modal-description">
              Are you sure you want to continue with this action?
            </Typography>
            {status === "closed_unpaid" ? (
              <div>
                <TextField
                  id="closedUnpaidReason"
                  label="Reason"
                  placeholder="Please tell us the reason for doing this!"
                  multiline
                  className={classes.textField}
                  margin="normal"
                  rowsMax="4"
                  onChange={this.handleOnChange}
                  value={value}
                />
                <div>
                  {!isLoading ? (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!value}
                      className={classes.button}
                      onClick={() => {
                        this.setState({
                          value: "",
                        })

                        return handleConfirmation(null, value)
                      }}
                    >
                      Confirm
                    </Button>
                  ) : (
                    <LoadingSpinner />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isDisabled}
                    className={classes.button}
                    onClick={() => {
                      this.setState({
                        value: "",
                        isDisabled: true
                      })
                      return handleClose()
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                {!isLoading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => handleConfirmation()}
                  >
                    Confirm
                  </Button>
                ) : (
                  <LoadingSpinner />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
              </div>
            )}
            <ConfirmationModalWrapped />
          </div>
        </Modal>
      </div>
    )
  }
}

ConfirmationModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

// We need an intermediary variable for handling the recursive nesting.
const ConfirmationModalWrapped = withStyles(styles)(ConfirmationModal)

export default ConfirmationModalWrapped
