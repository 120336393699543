import React from "react"
import {
  CardActions,
  ListButton,
  Edit,
  Link,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  AutocompleteInput,
  BooleanInput
} from "react-admin"
import Paper from '@material-ui/core/Paper'
import logo from "../Images/dermio-logo.svg"
import { DateInput, TimeInput } from 'react-admin-date-inputs';
import Card from '@material-ui/core/Card';
import { validateText } from "../util/validation"
import getCurrentTimezoneName from "../util/getCurrentTimezoneName"

export const DoctorIcon = null

const CustomEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    {/* <RefreshButton basePath={basePath} record={data} /> */}
  </CardActions>
)

export const AvailabilityEdit = props => {
  return (
    <Edit
      actions={<CustomEditActions />}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      style={{ marginTop: "4rem" }}
      {...props}
    >
      <SimpleForm
        defaultValue={
          props.permissions === "doctor"
            ? {
              doctorId: localStorage.getItem("userId"),
              createdBy: "doctor",
            }
            : { createdBy: "admin" }
        }
      >
        <AutocompleteInput source="day" choices={[
          { id: 'Monday', name: 'Monday' },
          { id: 'Tuesday', name: 'Tuesday' },
          { id: 'Wednesday', name: 'Wednesday' },
          { id: 'Thursday', name: 'Thursday' },
          { id: 'Friday', name: 'Friday' },
          { id: 'Saturday', name: 'Saturday' },
          { id: 'Sunday', name: 'Sunday' },
          ]} 
          validate={validateText}
        />
        <BooleanInput label="Active Status" source="isActive" />
        <ArrayInput source="slots">
          <SimpleFormIterator>
            <TimeInput source="from" label="From" options={{ clearable: true, ampm: true }} validate={validateText} />
            <TimeInput source="to" label="To" options={{ clearable: true, ampm: true }} validate={validateText}/>
          </SimpleFormIterator>
        </ArrayInput>
        <Paper style={{ padding: 10 }}>
         <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
            Timezone: {getCurrentTimezoneName()}
          </p>
        </Paper>
      </SimpleForm>
    </Edit>
  )
}
