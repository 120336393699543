import React, { Component, Fragment, cloneElement } from "react"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { TextField as MUITextField, Divider } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"

import { UPDATE, SimpleForm } from "react-admin"
import Storage from "@aws-amplify/storage"

import CustomEditToolbar from "./CustomEditToolbar"
import TemplateAutoSelect from "./TemplateAutoSelect"
import fetchTemplates from "../queries/fetchTemplates"
import FormControl from "@material-ui/core/FormControl"
import Paper from "@material-ui/core/Paper"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Slide from "@material-ui/core/Slide"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import AddIcon from "@material-ui/icons/AddCircleOutline"
import createPdfMutation from "../queries/createPdfQuery"

import { diseaseDetailsForm } from "./Form/diseaseDetailsForm"
import { createNewMedic } from "./Form/createNewMedication"
import { ConsoleLogger } from "@aws-amplify/core"

Storage.configure({
  region: process.env.REACT_APP_BUCKET_REGION,
  bucket: process.env.REACT_APP_BUCKET_NAME,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  userPoolId: process.env.REACT_APP_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
})

const styles = theme => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    backgroundColor: "#fff",
    border: "2px solid #eee",
    marginBottom: 20,
  },
  formControl: {
    margin: theme.spacing.unit,
    paddingBottom: "10px",
    minWidth: "100%",
    border: "none",
    // minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  divider: {
    height: theme.spacing.unit * 3,
  },
  addBtnsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing.unit,
  },
  addBtn: {
    width: "45%",
    alignSelf: "center",
  },
  createBtn: {
    marginTop: theme.spacing.unit,
  },
  bottom: {
    // Fix for mobile views.
    flexDirection: "row",
  },
  paper: {
    padding: "10px",
    margin: "5px",
  },
})

class Form extends Component {
  state = {
    impression: "",
    plan: "",
    impressionDescription: "",
    sideEffectsString: "",
    skinCareInstructions: "",
    suggestions: [],
    loading: true,
    consultDiagnosisData: [],
  }

  componentDidMount = async () => {
    const {
      impression,
      plan,
      impressionDescription,
      sideEffectsString,
      skinCareInstructions,
      consultDiagnosisData,
    } = this.props.record
    let { doctorId } = this.props.record
    if (consultDiagnosisData && consultDiagnosisData.length) {
      // console.log("impression ", impression);
      this.setState({
        consultDiagnosisData: (consultDiagnosisData &&
          consultDiagnosisData.length &&
          consultDiagnosisData) || [{}],
      })
    } else {
      this.setState({
        consultDiagnosisData: [
          {
            impression,
            plan,
            impressionDescription,
            sideEffectsString,
            skinCareInstructions,
          },
        ],
      })
    }
    // console.log("doctorId ", doctorId);
    doctorId = doctorId
      ? doctorId
      : JSON.parse(localStorage.getItem("doctorDetails")).id
    if (doctorId) {
      try {
        const response = await fetchTemplates(doctorId)
        // console.log("suggestions ", response.data);
        const suggestions = response.data.allTemplates.items
          .concat(response.data.allAdminTemplates.items)
          .map(template => {
            // console.log(template, 'YEMEEM');
            // console.log(template, 'ow');

            return {
              value: {
                plan: template.plan,
                impressionDescription: template.impressionDescription,
                sideEffectsString: template.sideEffectsString,
                skinCareInstructions: template.skinCareInstructions,
              },
              label: template.impression,
            }
          })
        // console.log("suggestions ", suggestions);
        this.setState(
          {
            suggestions,
            loading: false,
          },
          () => {
            this.props.handleDataChange(this.state)
          }
        )
      } catch (e) {
        console.error(e)
      }
    } else {
      this.setState({ loading: false })
    }
  }

  handleSave = values => {
    const {
      dispatch,
      onSave,
      basePath,
      record: { id },
      generatePdf,
      handleDataChange,
    } = this.props

    const consultDiagnosisData = this.state.consultDiagnosisData.filter(
      diagnosisData => {
        return JSON.stringify(diagnosisData) != "{}"
      }
    )

    this.setState({ consultDiagnosisData }, () => {
      handleDataChange(this.state, generatePdf)
    })

    return dispatch({
      type: "QUICK_UPDATE",
      payload: {
        data: {
          id,
          plan: this.state.plan,
          consultDiagnosisData: consultDiagnosisData,
        },
      },
      meta: {
        fetch: UPDATE,
        resource: "Consult",
        onSuccess: {
          notification: {
            body: "The consult has been successfully updated.",
            level: "info",
          },
          redirectTo: `/Consult/${id}/show/${Math.random() + Math.random()}`,
          basePath,
        },
        onFailure: {
          notification: {
            body: "OOPS! Something went wrong",
            level: "warning",
          },
        },
      },
    })
  }

  handleInput = (e, index, fieldName) => {
    let consultDiagnosisData = this.state.consultDiagnosisData
    consultDiagnosisData[index][fieldName] = e.target.value
      ? e.target.value
      : null
    this.setState({ consultDiagnosisData })
  }

  handleSelect = (selected, selectId) => {
    let consultDiagnosisData = this.state.consultDiagnosisData
    consultDiagnosisData[selectId] = {
      impression: selected.label ? selected.label : "",
      plan: selected.value
        ? selected.value.plan
          ? selected.value.plan
          : null
        : null,
      impressionDescription: selected.value
        ? selected.value.impressionDescription
          ? selected.value.impressionDescription
          : null
        : null,
      sideEffectsString: selected.value
        ? selected.value.sideEffectsString
          ? selected.value.sideEffectsString
          : null
        : null,
      skinCareInstructions: selected.value
        ? selected.value.skinCareInstructions
          ? selected.value.skinCareInstructions
          : null
        : null,
    }
    this.setState({ consultDiagnosisData })
  }

  handleAddDiagnosis = diagnosisId => {
    this.setState({
      consultDiagnosisData: [...this.state.consultDiagnosisData, {}],
    })
  }

  handleRemoveDiagnosis = removeIndex => {
    const consultDiagnosisData = this.state.consultDiagnosisData
    const result = consultDiagnosisData.filter((data, index) => {
      return removeIndex != index
    })
    this.setState({
      consultDiagnosisData: result,
    })
  }

  render() {
    const { submitting, classes, hideActions } = this.props
    // console.log("permissions ",permissions)
    const {
      impression,
      suggestions,
      loading,
      consultDiagnosisData,
    } = this.state
    
    if (suggestions.length <= 0 && loading) {
      return (
        <div>
          <h4>Loading</h4>
        </div>
      )
    } else if (suggestions.length <= 0 && !loading) {
      return (
        <div>
          <h4>No Diagnosis Templates found. Please Create one.</h4>
        </div>
      )
    }

    return (
      <SimpleForm
        toolbar={<CustomEditToolbar hideActions={hideActions}/>}
        form="my-form"
        save={this.handleSave}
        saving={submitting}
        redirect={false}
        className={classes.root}
      >
        {consultDiagnosisData &&
          consultDiagnosisData.map((diagnosisData, index) => {
            return (
              <Fragment key={index}>
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                  <Fragment>
                    {(
                      <Button
                        variant="outlined"
                        color="red"
                        style={{
                          float: "right",
                          // marginTop: 15,
                          marginRight: 15,
                          color: "red",
                        }}
                        onClick={() => {
                          return this.handleRemoveDiagnosis(index)
                        }}
                      >
                        Remove
                      </Button>
                    )}
                    {!hideActions && consultDiagnosisData.length === index + 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          // float: "left",
                          // margin: "0 15",
                          color: "white",
                        }}
                        onClick={() => {
                          return this.handleAddDiagnosis(index)
                        }}
                      >
                        New Impression
                      </Button>
                    )}
                    <TemplateAutoSelect
                      selectId={index}
                      doctorId={this.props.record.doctorId}
                      recordValue={diagnosisData.impression}
                      label="Impression"
                      suggestions={suggestions}
                      handleData={this.handleSelect}
                      placeholder="Type impression name to select"
                    />
                    <FormControl className={classes.formControl}>
                      <MUITextField
                        id="impressionDescription"
                        label="impressionDescription"
                        fullWidth
                        placeholder="Write a brief description of your treatment..."
                        multiline
                        name="impressionDescription"
                        rowsMax="5"
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        value={diagnosisData.impressionDescription}
                        onChange={e => {
                          return this.handleInput(
                            e,
                            index,
                            "impressionDescription"
                          )
                        }}
                        margin="normal"
                      />
                      <MUITextField
                        id="plan"
                        label="Plan"
                        fullWidth
                        placeholder="Write a brief plan of your treatment..."
                        multiline
                        name="plan"
                        rowsMax="5"
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        value={diagnosisData.plan ? diagnosisData.plan : ""}
                        onChange={e => {
                          return this.handleInput(e, index, "plan")
                        }}
                        margin="normal"
                      />
                      <MUITextField
                        id="sideEffectsString"
                        label="Side effects"
                        fullWidth
                        placeholder="Write a brief description of side effects..."
                        multiline
                        name="sideEffectsString"
                        rowsMax="5"
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        value={diagnosisData.sideEffectsString}
                        onChange={e => {
                          return this.handleInput(e, index, "sideEffectsString")
                        }}
                        margin="normal"
                      />
                      <MUITextField
                        id="skinCareInstructions"
                        label="skin care instructions"
                        fullWidth
                        placeholder="Write a brief description of skin care instructions..."
                        multiline
                        name="skinCareInstructions"
                        rowsMax="5"
                        InputLabelProps={{ shrink: true }}
                        className={classes.textField}
                        value={diagnosisData.skinCareInstructions}
                        onChange={e => {
                          return this.handleInput(
                            e,
                            index,
                            "skinCareInstructions"
                          )
                        }}
                        margin="normal"
                      />
                    </FormControl>
                  </Fragment>
                </Slide>
              </Fragment>
            )
          })}
      </SimpleForm>
    )
  }
}

const mapStateToProps = state => ({
  submitting: state.admin.loading > 0,
})

const enhance = compose(
  connect(mapStateToProps),
  withStyles(styles)
)

export default enhance(Form)
