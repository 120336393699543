import React from "react"
import { Auth } from "aws-amplify"
import { ForgotPassword } from "aws-amplify-react"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import { withStyles } from "@material-ui/core/styles"
import dermioLogo from "../Images/dermio-logo.svg"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
})

class CustomForgotPassword extends ForgotPassword {
  state = {
    delivery: null,
    error: "",
    value:""
  }

  handleChange = e => {
    this.handleInputChange(e)
    this.setState({
      error: "",
    })
  }


  handleChangeSubmit = e => {
    
    this.handleInputChange(e)
    this.setState({
      error: "",
    })
  }

  handleSend = e => {
    e.preventDefault()

    const { username } = this.inputs
    const lowerCasedEmail = username.toLowerCase();
    // Client side validation

    Auth.forgotPassword(lowerCasedEmail)
      .then(data => {
        this.setState({
          delivery: data.CodeDeliveryDetails,
          error: "",
        })
      })
      .catch(err => {
        console.error(err)

        if (err.code === "NotAuthorizedException") {
          this.setState({
            error: "Please enter the temporary password sent to your email id",
          })
        }

        if (err.code === "UserNotFoundException") {
          this.setState({
            error: "User does not exist. Please enter the correct username.",
          })
        }
        if (err.code === "LimitExceededException") {
          this.setState({
            error: "Attempt limit exceeded, please try after some time.",
          })
        }
      })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { username, code, password } = this.inputs
    const lwrCsEmail = username.toLowerCase();
    Auth.forgotPasswordSubmit(lwrCsEmail, code, password)
      .then(data => {
        this.changeState("signIn")
        this.setState({
          delivery: null,
          error: "",
        })
      })
      .catch(err => {
        console.error(err)

        if (err.code === "CodeMismatchException") {
          this.setState({
            error:
              "Invalid verification code provided. Please enter the correct code.",
          })
        }

        if (err.code === "InvalidParameterException") {
          this.setState({
            error: "Password must have a length greater than or equal to 6.",
          })
        }
      })
  }

  render() {
    const { classes, authState } = this.props
    const { delivery } = this.state
    if (authState === "forgotPassword") {
      return (
        <div className={classes.main}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div>
            {delivery ? (
              <form onSubmit={this.handleSubmit}>
                <div className={classes.hint}>RESET YOUR PASSWORD</div>
                <div className={classes.error}>{this.state.error}</div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <TextField
                      id="Enter Code"
                      name="code"
                      placeholder="Enter Code *"
                      margin="normal"
                      type="text"
                      fullWidth={true}
                      required
                      value={this.state.value}
                      autoFocus={true}
                      autoComplete="off"
                      onChange={(e)=>{
                        this.setState({value:e.target.value})
                        this.handleChangeSubmit(e)
                      }}
                    />
                    <TextField
                      id="password"
                      name="password"
                      placeholder="New Password *"
                      margin="normal"
                      type="password"
                      fullWidth={true}
                      required
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button onClick={this.send}>Resend Code</Button>
                  <Button
                    variant="raised"
                    type="submit"
                    color="primary"
                    className={classes.button}
                    fullWidth
                  >
                    Submit
                  </Button>
                </CardActions>
              </form>
            ) : (
                <form onSubmit={this.handleSend}>
                  <div className={classes.hint}>RESET YOUR PASSWORD</div>
                  <div className={classes.error}>{this.state.error}</div>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <TextField
                        id="username"
                        name="username"
                        label="Username"
                        margin="normal"
                        fullWidth={true}
                        type="email"
                        required
                        error={this.state.error ? true : false}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <CardActions className={classes.actions}>
                    <Button onClick={() => this.changeState("signIn")}>
                      Back to Sign In
                  </Button>
                    <Button
                      variant="raised"
                      type="submit"
                      color="primary"
                      className={classes.button}
                      fullWidth
                    >
                      Send Code
                  </Button>
                  </CardActions>
                </form>
              )}
          </Card>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(CustomForgotPassword)
