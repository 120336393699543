import Appsync from "../util/client"
import { v4 as uuid } from "uuid"
import { Auth } from "aws-amplify"
const gql = require("graphql-tag")

const client = Appsync()

const mutationCreateDoctorChat = gql`
  mutation Create(
    $id: ID!
    $consultId: String!
    $senderId: String!
    $receiverId: String!
    $senderRole: String!
    $message: String
    $createdAt: String!
    $receiverRole: String!
    $seen: Boolean
    $phone_number: String
    $source: String!
    $portalUrl: String
    $email: String
  ) {
    createDoctorChat(
      id: $id
      consultId: $consultId
      senderRole: $senderRole
      createdAt: $createdAt
      receiverRole: $receiverRole
      senderId: $senderId
      receiverId: $receiverId
      message: $message
      seen: $seen
      phone_number: $phone_number
      source: $source
      portalUrl: $portalUrl
      email: $email
    ) {
      id
      createdAt
      senderRole
      senderId
      receiverId
      message
      source
    }
  }
`

const createDoctorChatMutation = async (message, record, practice) => {
  const { id: consultId, patientId: receiverId, source, phone_number, email } = record

  const senderId = localStorage.getItem("userId")

  const id = uuid()

  let seen = false

  const chatDetails = {
    id,
    consultId,
    senderId,
    senderRole: "doctor",
    createdAt: new Date().toISOString(),
    message,
    receiverId,
    receiverRole: "patient",
    seen,
    phone_number,
    source,
    portalUrl: `${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?from=dermio`,
    email
  }

  if (source !== "dermio.com") {
    chatDetails.portalUrl = `${process.env.REACT_APP_PATIENT_PORTAL_URL}/#/patient/dashboard?practiceId=${record.practiceId ||
      practice.id}`
  }

  const updatedConsultChat = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationCreateDoctorChat,
      variables: chatDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsultChat
}

export default createDoctorChatMutation;

const mutationCreateChatImage = gql`
  mutation Create(
    $senderId: String!
    $receiverId: String!
    $senderRole: String!
    $consultId: String!
    $createdAt: String!
    $receiverRole: String!
    $seen: Boolean
    $file: S3ObjectInput
    $url: String
  ) {
    createChat(
      senderRole: $senderRole
      createdAt: $createdAt
      receiverRole: $receiverRole
      senderId: $senderId
      consultId: $consultId
      receiverId: $receiverId
      file: $file
      url: $url
      seen: $seen
    ) {
      createdAt
      senderRole
      senderId
      url
      file {
        region
        bucket
        key
      }
    }
  }
`

export const createChatImgMsgMutation = async (
  pictures,
  consultId,
  receiverId,
  patientname
) => {
  const { name, type: mimeType } = pictures[0]
  const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name)
  const key = [uuid(), extension].filter(x => !!x).join(".")

  const bucket = process.env.REACT_APP_BUCKET_NAME
  const url = `https://${bucket}.s3.us-east-1.amazonaws.com/images/${key}`
  const senderId = await Auth.currentSession().then(res => {
    return res.idToken.payload["sub"]
  })

  let seen = false

  const chatDetails = {
    senderId, // string
    senderRole: "doctor",
    consultId,
    createdAt: new Date().toISOString(),
    receiverId,
    receiverRole: "patient",
    seen,
    url,
    file: {
      region: "us-east-1",
      bucket,
      key: `public/${key}`,
      mimeType,
      localUri: pictures[0],
    }
    // name: patientname,
  }

  const updatedConsultChat = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationCreateChatImage,
      variables: chatDetails,
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedConsultChat
}
