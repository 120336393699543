import { UPDATE } from "react-admin"

export const ARCHIVE_CONSULT = "ARCHIVE_CONSULT"

export const archiveConsult = (id, data, basePath) => ({
  type: ARCHIVE_CONSULT,
  payload: { id, data: { ...data, archived: true, id } },
  meta: {
    resource: "Consult",
    fetch: UPDATE,
    refresh: true,
    unselectAll: true,
    onSuccess: {
      redirectTo: "/Consult",
      basePath,
    },
    onFailure: {
      notification: {
        body: "Something went wrong. Please try again!",
        level: "warning",
      },
    },
  },
})
