import React,{Component, useState, useEffect} from "react"
import moment from "moment"
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  Link,
  TextInput,
  Filter,
  DateField,
  BooleanField,
  RefreshButton
} from "react-admin"
import gql from "graphql-tag"
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import logo from "../Images/dermio-logo.svg"
import Pagination from "../Pagination"
import DoneIcon from '@material-ui/icons/Done'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import Appsync from "../util/client"
import UnavailabilityConfirmationModal from "./UnavailabilityConfirmationModal"
import createUnavailability from "../queries/createUnavailability.js"
import deleteUnavailability from "../queries/deleteUnavailability.js"
import getCurrentTimezoneName from "../util/getCurrentTimezoneName"

const client = Appsync()

const query = gql`
    query allUnavailabilities(
      $doctorId: String
    ){
      allUnavailabilities(filter:{
        doctorId:{
          eq: $doctorId
        }
      }){
        items{
          id
          dateString
          day
          datetime
          doctorId
          slots{
            from
            to
          }
        }
      }
    }
  `;

export const DoctorFilter = props => {
  // console.log("doctor filter", props);
  return (
    <Filter {...props}>
      <TextInput label="Status" source="callStatus" />
      <TextInput label="Date" source="videoCallDate" />
    </Filter>
  )
}

class TagsField extends Component{

  state = {
    chipData: [],
    isModalOpen: false,
    slotToDelete: null,
    selectedDateSlot: null,
    selectedDateSlotIndex: 0,
    isLoading: false
  };

  componentDidMount=()=>{
    const {record, unavailableDates} = this.props;
    if(record && record.day && record.slots && record.slots.length){

      let newData = record.slots.map(item => {
        let i = 0;
        let chipData = [...Array(5)].map((_) =>{
          let labelDate = moment().day(record.day).add(i, 'week').format("DD-MMM-YY");
          
          if(moment().day(record.day).add(i, 'week').unix() < moment().unix()){
            labelDate = moment().day(record.day).add(i+1, 'week').format("DD-MMM-YY");
            ++i;
          }

          ++i;

          return {
            key: i, 
            slot: `${moment(item.from).format("LT")} - ${moment(item.to).format("LT")}`, 
            label: labelDate,
            available: true,
            deleteId: null 
          } 
        });
        return chipData;
      });

       if(unavailableDates && unavailableDates.length){
          newData = newData.map((slot)=>{
            let slots = slot.filter((slotDateObj)=>{
                let unavailableDatesStringList = unavailableDates.filter((unavailableDate)=>{
                  return  (moment(unavailableDate.datetime).format("DD-MMM-YY") === slotDateObj.label &&
                    `${moment(unavailableDate.slots.from).format("LT")} - ${moment(unavailableDate.slots.to).format("LT")}`=== slotDateObj.slot
                    );
                 });
                
                let newSlotDateObj = slotDateObj;

                if(unavailableDatesStringList && unavailableDatesStringList.length){
                  newSlotDateObj.deleteId = unavailableDatesStringList && unavailableDatesStringList.length ? unavailableDatesStringList[0].id : null;
                  newSlotDateObj.available = false;
                  return newSlotDateObj;
                }else{
                  return newSlotDateObj;
                }
            });
            return slots;
          });
        }

      this.setState({chipData: newData});
    }
  }

  handleDelete = () => {
    this.setState({isLoading : true});
    const chipToDelete = this.state.slotToDelete;
    const slot = this.state.selectedDateSlot;
    const slotIndex = this.state.selectedDateSlotIndex;

    let chipData = this.state.chipData;
    if(this.state.slotToDelete.available){
      this.setUnavailabilityDate(slot, chipToDelete, chipData);
    }else{
      this.deleteUnavailabilityDate(slot, chipToDelete, chipData);
    }
  };

  setUnavailabilityDate = (slots, unavailableDate, chipDatas) =>{
    const {record} = this.props;

    const chipToDelete = this.state.slotToDelete;
    const slot = this.state.selectedDateSlot;
    const slotIndex = this.state.selectedDateSlotIndex;

    let chipData = this.state.chipData;
    if(chipData[slotIndex] &&chipData[slotIndex].length){
       chipData[slotIndex] = this.state.chipData[slotIndex].map(chip => {
        let newChip = chip;
        if(chip.key !== chipToDelete.key){
          return newChip;
        }else{
          newChip.available = false;
          return newChip;
        }
       });
    }

    if(record && record.id){
      const variables = {
        doctorId: record.doctorId,
        day: record.day,
        datetime: moment(unavailableDate.label, 'DD-MMM-YY').toISOString(),
        dateTimeString: unavailableDate.label,
        slots: {
          from: slot.from,
          to: slot.to
        }
      }
      createUnavailability(variables).then((result)=>{
        const {data: {createUnavailability}} = result;
        // console.log(result)
        if(createUnavailability && createUnavailability.id){
          this.setState({chipData},()=>{
            this.handleModalClose();
          });
        }
      }).catch((error)=>{
        console.log(error);
        this.handleModalClose();
      });
    }
  }

  deleteUnavailabilityDate = (slots, unavailableDate, chipDatas) =>{

    const chipToDelete = this.state.slotToDelete;
    const slot = this.state.selectedDateSlot;
    const slotIndex = this.state.selectedDateSlotIndex;
    
    let chipData = this.state.chipData;
    if(chipData[slotIndex] &&chipData[slotIndex].length){
       chipData[slotIndex] = this.state.chipData[slotIndex].map(chip => {
        let newChip = chip;
        if(chip.key !== chipToDelete.key){
          return newChip;
        }else{
          newChip.available = true;
          return newChip;
        }
       });
    }

    if(unavailableDate && unavailableDate.deleteId){
      const variables = {
        id: unavailableDate.deleteId
      }
      deleteUnavailability(variables).then((result)=>{
        const {data: {deleteUnavailability}} = result;
          this.setState({chipData, loading: false},()=>{
            this.handleModalClose();
          });
      }).catch((error)=>{
        console.log(error);
        this.handleModalClose();
      });
    }
  }

  handleModalOpen = (chipToDelete, slot, slotIndex) =>{
    this.setState({ isModalOpen: true, slotToDelete: chipToDelete, selectedDateSlot: slot, selectedDateSlotIndex: slotIndex})
  }
    

  handleModalClose = () =>
    this.setState({ 
      isModalOpen: false,
      slotToDelete: null, 
      selectedDateSlot: null, 
      selectedDateSlotIndex: null,
      isLoading: false
    })

  render (){
      const{record} = this.props;
      const {chipData, isModalOpen, slotToDelete, isLoading} = this.state;

    return(
      (
      <ul>
          {record.slots && record.slots.map((item, slotIndex) => (
              <li key={item.from} style={{ paddingTop: 5 }}>
              {moment(item.from).format("LT")} - {moment(item.to).format("LT")}
              <span style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 13}}> ({getCurrentTimezoneName()})</span>
              {chipData && chipData[slotIndex] && chipData[slotIndex].length? <Paper style={{ padding: 10, margin: 10}}>
                {chipData[slotIndex].map(data => {
                  return (
                    <Chip
                      key={data.key}
                      size={"small"}
                      variant={"outlined"}
                      label={data.label}
                      onDelete={()=>this.handleModalOpen(data, item, slotIndex)}
                      // onDelete={this.handleDelete(data, item, slotIndex)}
                      style={{
                        margin: 5,
                        border: `1px solid ${data.available ? "green" : "red"}`
                      }}
                      // color={"primary"}
                      deleteIcon={data.available ? <HighlightOffIcon/>:<DoneIcon />}
                      disabled={false}
                    />
                  );
                })}
              </Paper>: ""}
              </li>
          ))}
          <UnavailabilityConfirmationModal
            key={record && record.id}
            isOpen={isModalOpen}
            handleConfirmation={()=>{this.handleDelete()}}
            handleClose={this.handleModalClose}
            isLoading={isLoading}
            status={slotToDelete}
          />
      </ul>
  ))
  }
}

TagsField.defaultProps = { addLabel: true };

const filterFunc = () => {
  return {
    doctorId: localStorage.getItem("userId"),
  }
}


export const AvailabilityList = (props) => {

  const [loading, setLoading] = useState(true);
  const [unavailableDates, setUnavailableDates] = useState(null);

  useEffect(()=>{
    // console.log("herere ", props);
    const variables ={
      todayISOString: moment().toISOString(),
      doctorId: localStorage.getItem("userId")
    }

    client.hydrated().then((cl)=>{
      cl.query({ query, variables, fetchPolicy: "no-cache" }).then((res)=>{
        const { allUnavailabilities } = res.data;
        // console.log(res)
        if(allUnavailabilities && allUnavailabilities.items){
          setUnavailableDates(allUnavailabilities.items);
          setLoading(false);
        }
      }).catch((error)=>{
        setLoading(false);
        console.log(error);
      });
    }).catch((error)=>{
        setLoading(false);
        console.log(error);
      });

  },[]);

    return (
    <div style={{marginTop: "55px"}}>
      <Paper style={{marginBottom: 10, padding: 10}}>
        <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 13, fontWeight: "600" }}>
          <span style={{ color: "#3fcff3" }}>Note: </span>If you are unavailable on a particular date just remove that date from the date list.
        </p>
      </Paper>
      {
        loading ? "" : 
      <List
              {...props}
              title={
                <Link to="/">
                  <img
                    className="dashboard__img-logo"
                    src={logo}
                    alt="dermio-logo"
                    style={{ paddingTop: "0.3em" }}
                  />
                </Link>
              }
              // filters={<DoctorFilter />}
              filter={filterFunc()}
              pagination={<Pagination {...props} />}
              style={{marginTop: 0}}
              bulkActionButtons={false}
            >
              <Datagrid>
                <TextField source="day" />
                <TagsField source="slots" unavailableDates={unavailableDates}/>
                {/*<BooleanField source="isActive" label={"Active"}/>*/}
                <EditButton />
              </Datagrid>
        </List>
      }
    </div>
    )
}
