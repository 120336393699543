import React, { Component, Fragment } from "react"
import { Responsive, Title } from "react-admin"
import { connect } from "react-redux"
import { push } from "react-router-redux"
import { showNotification } from "react-admin"
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import DoctorList from "./AllDoctors"
import Appsync from "../../util/client"
import Consults from "./Consults"
import logo from "../../Images/dermio-logo.svg"
import "./css/adminDashboard.css"
import decryptConsult from "../../util/decryptConsult"
import decryptDash from "../../util/decryptDash"
import moment from "moment"

const client = Appsync()

const query = gql`
  query adminQuery($filterDate: String) {
    allAcceptedConsults: allConsults(
      status: { eq: "accepted" }
      filter: {}
      limit: 10
    ) {
      items {
        id
        status
        patientId
        doctorId
        medication
        allergy
        patientname
        createdAt
        state
      }
    }

    allPendingConsults: allConsults(
      status: { eq: "pending" }
      filter: {}
      limit: 10
    ) {
      items {
        id
        status
        patientId
        doctorId
        medication
        allergy
        patientname
        createdAt
        state
      }
    }

    allDoctors: allDoctors(
      filter: { enabled: { eq: false }, createdAt: { gt: $filterDate } }
    ) {
      items {
        id
        name
        stripeId
        practiceId
        practice {
          id
          stripeId
        }
      }
    }
  }
`
// , createdAt: { gt: $filterDate }
// $filterDate: String

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column", marginTop: "4em" },
  leftCol: { flex: 1, marginRight: "1em" },
  rightCol: { flex: 1, marginLeft: "1em" },
  singleCol: { marginTop: "2em", marginBottom: "2em" },
}

class Dashboard extends Component {
  state = {
    consults: {
      allAcceptedConsults: [],
      allPendingConsults: [],
    },
    allDoctors: [],
  }

  async componentDidMount() {
    try {
      let date = moment().toISOString()
      date = moment()
        .subtract("100", "days")
        .toISOString()
      const cl = await client.hydrated()
      const res = await cl.query({
        query,
        variables: {
          filterDate: date,
        },
        fetchPolicy: "no-cache",
      })

      // console.log(res);

      const { items: allDoctors } = res.data.allDoctors
      let { items: allAcceptedConsults } = res.data.allAcceptedConsults
      let { items: allPendingConsults } = res.data.allPendingConsults

      allAcceptedConsults = await decryptDash(allAcceptedConsults)
      allPendingConsults = await decryptDash(allPendingConsults)

      const sortedAcceptedConsults = allAcceptedConsults.sort((a, b) => {
        return a.createdAt > b.createdAt
          ? -1
          : a.createdAt < b.createdAt
          ? 1
          : 0
      })
      const sortedPendingConsults = allPendingConsults.sort((a, b) => {
        return a.createdAt > b.createdAt
          ? -1
          : a.createdAt < b.createdAt
          ? 1
          : 0
      })

      this.setState({
        allDoctors,
        consults: {
          allAcceptedConsults: sortedAcceptedConsults,
          allPendingConsults: sortedPendingConsults,
        },
      })
    } catch (e) {
      //   console.error(e)
    }
  }

  render() {
    const {
      consults: { allAcceptedConsults, allPendingConsults },
      allDoctors,
    } = this.state

    return (
      <Fragment>
        <Title
          title={
            <Link to="/">
              <img
                className="dashboard__img-logo"
                src={logo}
                alt="dermio-logo"
                style={{ paddingTop: "0.3em" }}
              />
            </Link>
          }
        />
        <Responsive
          xsmall={
            <div style={styles.flexColumn}>
              <Consults consults={allPendingConsults} consultStatus="New" />
              <Consults
                consults={allAcceptedConsults}
                consultStatus="Preliminary"
              />
              <DoctorList doctors={allDoctors} />
            </div>
          }
          small={
            <div style={styles.flexColumn}>
              <Consults consults={allPendingConsults} consultStatus="New" />
              <Consults
                consults={allAcceptedConsults}
                consultStatus="Preliminary"
              />
              <DoctorList doctors={allDoctors} />
            </div>
          }
          medium={
            <div style={{ marginTop: "3em" }}>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <Consults
                    consults={allAcceptedConsults}
                    consultStatus="Preliminary"
                  />
                </div>
                <div style={styles.rightCol}>
                  <Consults consults={allPendingConsults} consultStatus="New" />
                </div>
              </div>
              <div style={styles.singleCol}>
                <DoctorList doctors={allDoctors} />
              </div>
            </div>
          }
          large={
            <div style={{ marginTop: "3em" }}>
              <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <Consults
                    consults={allAcceptedConsults}
                    consultStatus="Preliminary"
                  />
                </div>
                <div style={styles.rightCol}>
                  <Consults consults={allPendingConsults} consultStatus="New" />
                </div>
                <div style={styles.rightCol}>
                  <DoctorList doctors={allDoctors} />
                </div>
              </div>
            </div>
          }
        />
      </Fragment>
    )
  }
}

export default connect(
  null,
  { push, showNotification }
)(Dashboard)
