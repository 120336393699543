import { Auth } from "aws-amplify"

function dataToDecrypt(consultDetails) {
  const fieldsToDecrypt = [
    "patientname",
    "age",
    "email",
    "gender",
    "phone_number",
    // "firstname",
    // "lastname",
  ]
  const objectToDecrypt = fieldsToDecrypt.reduce((res, i) => {
    if (consultDetails[i]) {
      return { ...res, [i]: consultDetails[i] }
    }
    return res
  }, {})

  return objectToDecrypt
}
export default function decryptConsult(consultDetails) {
  return new Promise(async (resolve, reject) => {
    
    let data = { consultDetails: dataToDecrypt(consultDetails) };

    const currentUser = (await Auth.currentSession()).getIdToken();

    if(currentUser && currentUser.payload && currentUser.payload.sub){
      data.decryptorDetails = {
        id: currentUser.payload.sub,
        role: currentUser.payload["cognito:groups"],
        patientId: consultDetails && consultDetails.id
      };
    }
    // THIS URL IS FROM PRODUCTION AWS API GATEWAY

    fetch(
      process.env.REACT_APP_DECRYPT_URL,
      // "https://ggax3opd48.execute-api.us-east-1.amazonaws.com/dev/api/decryptconsult",
      // "https://ij1omrmtl7.execute-api.us-east-1.amazonaws.com/dev/api/decryptconsult",
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, cors, *same-origin
        headers: {
          Authorization: currentUser.getJwtToken(),
        },
        cache: "no-cache",
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )
      .then(response => {
        return response.text()
      })
      .then(result => {
        const decryptedFields = JSON.parse(result)
        if (consultDetails.patientDob) {
          let createdDate = Number(new Date(consultDetails.createdAt).getDate())
          let createdMonth = Number(
            new Date(consultDetails.createdAt).getMonth()
          )
          let createdYear = Number(
            new Date(consultDetails.createdAt).getFullYear()
          )

          let month = Number(
            consultDetails.patientDob
              .split(" ")
              .slice(0, 1)
              .join(" ")
          )
          let date = Number(
            consultDetails.patientDob
              .split(" ")
              .slice(1, 2)
              .join(" ")
          )

          // console.log(
          // 	createdMonth <= month,
          // 	createdDate < date,
          // 	createdMonth,
          // 	month,
          // 	createdDate,
          // 	date,
          // 	'\n\nwoah!!\n\n'
          // );

          if (
            createdMonth < month &&
            (createdMonth === month ? createdDate < date : true)
          ) {
            decryptedFields.patientDob =
              consultDetails.patientDob
                .split(" ")
                .slice(0, 2)
                .join(" ") +
              " " +
              (createdYear - Number(decryptedFields.age) - 1)
          } else {
            decryptedFields.patientDob =
              consultDetails.patientDob
                .split(" ")
                .slice(0, 2)
                .join(" ") +
              " " +
              (createdYear - decryptedFields.age)
          }
        }
        const finalData = { ...consultDetails, ...decryptedFields }
        resolve(finalData)
      })
      .catch(reject)
  })
}
