import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
    query fetchPracticeUser($id: ID!) {
        PracticeUser(id: $id){
          id
          firstName
          lastName
          name
          enabled
          practiceId
          email
          phone
        }
    }
`

const fetchPracticeUser = async practiceAdminId => {
  // console.log(practiceAdminId)
  const variables = { id: practiceAdminId }
  //  console.log("variables ",variables)
  const practiceUserDetails = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })

  return practiceUserDetails
}

export default fetchPracticeUser
