import React, { Component } from "react"
import { SmokingRooms } from "@material-ui/icons"
import AwsImageComponent from "./Components/AwsImageComponent"
import animLoader from "./util/animLoader/animLoader"
import decryptConsult from "./util/decryptConsult"
import { Responsive } from "react-admin/lib"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import LinearProgress from "@material-ui/core/LinearProgress"
import moment from "moment"
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';

const styles = theme => ({
  imgPanelDetails: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "center",
  },
})

const ExpansionPanel = withStyles({
  root: {
    margin: "1rem 0",
  },
  expanded: {},
})(MuiExpansionPanel)

class PatientDetails extends Component {
  state = {
    imgUrl: "",
    rotationAngle: 0
  }

  ChargeIdValidityComponent = (createdAtString, cardValidity) => {
    return (
      <p
        style={{
          marginBottom: 10,
          display: "inline",
          fontSize: "small",
        }}
      >
        <span
          style={{
            background: "#ff6333",
            padding: 2,
            borderRadius: 2,
            margin: 5,
            lineHeight: "25px",
          }}
        >
          This consult card details are valid from ({createdAtString}) to (
          {cardValidity.format("l")}  {cardValidity.format("LT")} )
        </span>
      </p>
    )
  }

  rotateImage = (event) => {
    const { rotationAngle } = this.state;
    let div = document.getElementById('modal-image');
    let angle = rotationAngle;

    if (rotationAngle === 360) {
      this.setState({ rotationAngle: 0 });
      angle = 0;
    } else {
      angle = angle + 90;
      this.setState({ rotationAngle: angle });
    }

    console.log("event ", event);
    console.log("div ", div);
    console.log("angle ", angle);
    div.style.webkitTransform = 'rotate(' + angle + 'deg)';
    div.style.mozTransform = 'rotate(' + angle + 'deg)';
    div.style.msTransform = 'rotate(' + angle + 'deg)';
    div.style.oTransform = 'rotate(' + angle + 'deg)';
    div.style.transform = 'rotate(' + angle + 'deg)';
  }

  render() {
    const { record, classes, isFetching, role } = this.props
    // console.log(record, "rec")
    // console.log(this.props)
    if (record) {
      const dateObj = new Date(record.createdAt)
      const createdAtString = `${dateObj.getMonth() +
        1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
      const cardValidity = moment(record.createdAt).add(7, "days")
      return (
        <Responsive
          small={
            <div
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 20,
                color: "black",
              }}
            >
              {record.chargeId &&
                this.ChargeIdValidityComponent(createdAtString, cardValidity)}
              {(record.insuranceUrl1 ||
                record.insuranceUrl2 ||
                record.drivingLicenseUrl) && (
                  <div style={{ margin: "1rem 0" }}>
                    <ExpansionPanel elevation={5} defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography variant="caption">
                            Insurance Images
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <Divider />
                      <ExpansionPanelDetails className={classes.imgPanelDetails}>
                        {record.insuranceUrl1 ? (
                          <AwsImageComponent
                            src={record.insuranceUrl1}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.insuranceUrl2 ? (
                          <AwsImageComponent
                            src={record.insuranceUrl2}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.drivingLicenseUrl ? (
                          <AwsImageComponent
                            src={record.drivingLicenseUrl}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.medicaidRefferalUrl1 ? (
                          <AwsImageComponent
                            src={record.medicaidRefferalUrl1}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.medicaidRefferalUrl2 ? (
                          <AwsImageComponent
                            src={record.medicaidRefferalUrl2}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                )}
              <div style={{ margin: "1rem 0" }}>
                <ExpansionPanel elevation={5} defaultExpanded>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography variant="caption">Images</Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails className={classes.imgPanelDetails}>
                    {record.url1 ? (
                      <AwsImageComponent
                        src={record.url1}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                    {record.url2 ? (
                      <AwsImageComponent
                        src={record.url2}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                    {record.url3 ? (
                      <AwsImageComponent
                        src={record.url3}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                    {record.url4 ? (
                      <AwsImageComponent
                        src={record.url4}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                style={{
                  height: "100%",
                  width: this.state.imgUrl ? "100%" : "0%",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  zIndex: 101,
                  backgroundColor: "#2a2a2a",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    right: 15,
                    top: 15,
                    color: "white",
                    fontSize: 25,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      imgUrl: "",
                    })
                  }
                >
                  X
                </span>
                <img
                  src={this.state.imgUrl}
                  style={{
                    maxHeight: "90%",
                    maxWidth: "100%",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isFetching ? (
                  <div>
                    <span style={{ color: "#c5c2c2" }}>loading...</span>
                  </div>
                ) : (
                  <div>
                    <span>
                      {record.patientname ? record.patientname : "no name"}
                    </span>{" "}
                    / {record.age ? record.age : "no age"} / {record.gender} /{" "}
                    {record.state}
                  </div>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "#636e72" }}>
                    {record.source ? record.source : ""}
                  </div>
                  <div>
                    {record.createdAt
                      ? `${dateObj.getMonth() +
                      1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
                      : null}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgMjEuMjI0di0xMi43NzJjMS4xMDYtLjU5NCAxLjY3NC0xLjc2MiAxLjY3NC0zLjEwNCAwLTEuODc0LTEuNDczLTMuNDExLTMuMzItMy41MTUtLjc2OC0xLjA2OC0yLjIyMS0xLjgzMy0zLjczOS0xLjgzMy0uOTcxIDAtMS43OC4zMjItMi41ODIuOTY0LTEuMjkyLS40MjItMi43NDctLjE0My0zLjc5NS43OTItMi4xNTUtLjM0Mi00LjIzOCAxLjI0NC00LjIzOCAzLjUwMSAwIDEuMzk2LjgxOSAyLjU4MSAyIDMuMTV2MTIuODE3YzAgLjY2NC0uMzM2IDEuMjAzLTEgMS4yMDN2MS41NzNoMTZ2LTEuNTczYy0uNjY0IDAtMS0uNTM5LTEtMS4yMDN6bS05LS4yMjRoLTJ2LTEyLjcxOWMuNjcyLjQyMiAxLjUxNi40MDYgMiAuMjY3djEyLjQ1MnptNy4xNTQtMTMuNzA3Yy0uNjI3IDAtMS4xODQtLjI5Ni0xLjUzOS0uNzU2bC0uNDUxLjMxNmMtMS41MjIgMS4xNzgtLjExMyAzLjMwMy0uMDEgNC4zMjQuMTAyLjk3Ny0uNTA1IDEuNzEyLTEuNDU4IDEuNzEyLS45MyAwLTEuNDc1LS43ODYtMS40MDEtMS43MTIuMDc4LS45NzggMS41NjItMi45MTgtLjA4My00LjQzOGwtLjYyMy0uNTY4Yy0uNDI1LjQ5Ny0xLjA1Ni44MTMtMS43NjEuODEzLS42MDUgMC0xLjE1NS0uMjMxLTEuNTY4LS42MTEtLjM1NC40ODctLjkyNy44MDQtMS41NzUuODA0LTIuNzE2IDAtMi44MTctMy44ODkgMC0zLjg4OS4zOTggMCAuNzcuMTIgMS4wNzcuMzI2LjM4NC0uNzUxIDEuMTYzLTEuMjY2IDIuMDY1LTEuMjY2LjU5MSAwIDEuMTMxLjIyMiAxLjU0LjU4Ni40OTQtLjgxNCAxLjM5LTEuMzU5IDIuNDEyLTEuMzU5IDEuMjU1IDAgMi4zMi44MiAyLjY4NSAxLjk1NS4yMTMtLjA4Mi40NDUtLjEyNi42ODktLjEyNiAxLjA3MiAwIDEuOTQzLjg3MSAxLjk0MyAxLjk0NHMtLjg2OSAxLjk0NS0xLjk0MiAxLjk0NXptNy44NDYgNS4xNTljMCAyLjUzOS0xLjc5MSA1Ljc1LTUgNi45NjN2LTIuMTZjMy4xNTQtMS44MyAzLjk2OS02LjI1NSAxLjU1My02LjI1NWgtMS41NTN2LTJoMS45MTJjMi4xNDQgMCAzLjA4OCAxLjUzNCAzLjA4OCAzLjQ1MnoiLz48L3N2Zz4=" />
                <span style={{ marginLeft: 5 }}>
                  {record.drinks_per_week ? record.drinks_per_week : 0} / week
                </span>

                <SmokingRooms style={{ marginLeft: 15, fontSize: 30 }} />
                <span style={{ marginLeft: 5 }}>
                  {record.cigrattes_per_day ? record.cigrattes_per_day : 0} /
                  day
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Allergy: </span>
                <span>
                  {record.allergy ? record.allergy : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Medication: </span>
                {/* <span>
                  `${dateObj.getMonth() + 1}/${dateObj.getDate()}/$
                  {dateObj.getFullYear()}`
                </span> */}
                <span>
                  {record.currentMedicalIssue
                    ? record.currentMedicalIssue
                    : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Surgeries: </span>
                <span>
                  {record.surgeries ? record.surgeries : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Email: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.email ? record.email : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Phone number: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.phone_number
                      ? `+1${record.phone_number}`
                      : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Date of birth: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.patientDob
                      ? record.patientDob.split(" ").join("/")
                      : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Pharmacy name: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.pharmacy_Name
                      ? record.pharmacy_Name
                      : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Pharmacy NCPDPID: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.pharmacy_NCPDPID
                      ? record.pharmacy_NCPDPID
                      : "No info available"}
                  </span>
                )}
              </div>
            </div>
          }
          medium={
            <div
              style={{
                paddingTop: 20,
                paddingLeft: 10,
                paddingRight: 10,
                paddingBottom: 20,
                color: "black",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  // alignItems: "center",
                }}
              >
                {record.chargeId &&
                  this.ChargeIdValidityComponent(createdAtString, cardValidity)}
                <h1
                  style={{
                    color: "#3fcef3",
                    marginBottom: 10,
                    fontSize: "x-large",
                  }}
                >
                  {record.isVideoCallConsult ? "Videocall" : ""} Patient
                  Information
                </h1>
              </div>
              {(record.insuranceUrl1 ||
                record.insuranceUrl2 ||
                record.drivingLicenseUrl) && (
                  <div style={{ margin: "1rem 0" }}>
                    <ExpansionPanel elevation={5} defaultExpanded>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div>
                          <Typography variant="caption">
                            Insurance Images
                          </Typography>
                        </div>
                      </ExpansionPanelSummary>
                      <Divider />
                      <ExpansionPanelDetails className={classes.imgPanelDetails}>
                        {record.insuranceUrl1 ? (
                          <AwsImageComponent
                            src={record.insuranceUrl1}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.insuranceUrl2 ? (
                          <AwsImageComponent
                            src={record.insuranceUrl2}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.drivingLicenseUrl ? (
                          <AwsImageComponent
                            src={record.drivingLicenseUrl}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.medicaidRefferalUrl1 ? (
                          <AwsImageComponent
                            src={record.medicaidRefferalUrl1}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                        {record.medicaidRefferalUrl2 ? (
                          <AwsImageComponent
                            src={record.medicaidRefferalUrl2}
                            onClick={url => {
                              this.setState({
                                imgUrl: url,
                              })
                            }}
                          />
                        ) : null}
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                )}
              <div style={{ margin: "1rem 0" }}>
                <ExpansionPanel
                  elevation={5}
                // defaultExpanded={record.status === "pending" ? true : false}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                      <Typography variant="caption">Images</Typography>
                    </div>
                  </ExpansionPanelSummary>
                  <Divider />
                  <ExpansionPanelDetails className={classes.imgPanelDetails}>
                    {record.url1 ? (
                      <AwsImageComponent
                        src={record.url1}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                    {record.url2 ? (
                      <AwsImageComponent
                        src={record.url2}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                    {record.url3 ? (
                      <AwsImageComponent
                        src={record.url3}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                    {record.url4 ? (
                      <AwsImageComponent
                        src={record.url4}
                        onClick={url => {
                          this.setState({
                            imgUrl: url,
                          })
                        }}
                      />
                    ) : null}
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <div
                style={{
                  height: "100%",
                  width: this.state.imgUrl ? "100%" : "0%",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  zIndex: 101,
                  backgroundColor: "#2a2a2a",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden"
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    right: 15,
                    top: 15,
                    color: "white",
                    fontSize: 25,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({
                      imgUrl: "",
                    })
                  }
                >
                  X
                </span>
                <img
                  id="modal-image"
                  src={this.state.imgUrl}
                  style={{
                    maxHeight: "90%",
                    maxWidth: "100%",
                    WebkitTransition: "all 0.5s ease-in-out",
                    MozTransition: "all 0.5s ease-in-out",
                    OTransition: "all 0.5s ease-in-out",
                    transition: "all 0.5s ease-in-out",
                  }}
                />
                <span
                  style={{
                    position: "absolute",
                    left: 15,
                    top: 15,
                    color: "white",
                    fontSize: 20,
                    cursor: "pointer",
                    display: "flex"
                  }}
                  onClick={this.rotateImage}
                >
                  <Rotate90DegreesCcwIcon fontSize={15} />
                  Rotate
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isFetching ? (
                  <div>
                    <span style={{ color: "#c5c2c2" }}>loading...</span>
                  </div>
                ) : (
                  <div>
                    <span>
                      {record.patientname ? record.patientname : "no name"}
                    </span>{" "}
                    / {record.age ? record.age : "no age"} / {record.gender} /{" "}
                    {record.state}
                  </div>
                )}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "#636e72" }}>
                    {record.source ? record.source : ""}
                  </div>
                  <div>{record.createdAt ? createdAtString : null}</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgMjEuMjI0di0xMi43NzJjMS4xMDYtLjU5NCAxLjY3NC0xLjc2MiAxLjY3NC0zLjEwNCAwLTEuODc0LTEuNDczLTMuNDExLTMuMzItMy41MTUtLjc2OC0xLjA2OC0yLjIyMS0xLjgzMy0zLjczOS0xLjgzMy0uOTcxIDAtMS43OC4zMjItMi41ODIuOTY0LTEuMjkyLS40MjItMi43NDctLjE0My0zLjc5NS43OTItMi4xNTUtLjM0Mi00LjIzOCAxLjI0NC00LjIzOCAzLjUwMSAwIDEuMzk2LjgxOSAyLjU4MSAyIDMuMTV2MTIuODE3YzAgLjY2NC0uMzM2IDEuMjAzLTEgMS4yMDN2MS41NzNoMTZ2LTEuNTczYy0uNjY0IDAtMS0uNTM5LTEtMS4yMDN6bS05LS4yMjRoLTJ2LTEyLjcxOWMuNjcyLjQyMiAxLjUxNi40MDYgMiAuMjY3djEyLjQ1MnptNy4xNTQtMTMuNzA3Yy0uNjI3IDAtMS4xODQtLjI5Ni0xLjUzOS0uNzU2bC0uNDUxLjMxNmMtMS41MjIgMS4xNzgtLjExMyAzLjMwMy0uMDEgNC4zMjQuMTAyLjk3Ny0uNTA1IDEuNzEyLTEuNDU4IDEuNzEyLS45MyAwLTEuNDc1LS43ODYtMS40MDEtMS43MTIuMDc4LS45NzggMS41NjItMi45MTgtLjA4My00LjQzOGwtLjYyMy0uNTY4Yy0uNDI1LjQ5Ny0xLjA1Ni44MTMtMS43NjEuODEzLS42MDUgMC0xLjE1NS0uMjMxLTEuNTY4LS42MTEtLjM1NC40ODctLjkyNy44MDQtMS41NzUuODA0LTIuNzE2IDAtMi44MTctMy44ODkgMC0zLjg4OS4zOTggMCAuNzcuMTIgMS4wNzcuMzI2LjM4NC0uNzUxIDEuMTYzLTEuMjY2IDIuMDY1LTEuMjY2LjU5MSAwIDEuMTMxLjIyMiAxLjU0LjU4Ni40OTQtLjgxNCAxLjM5LTEuMzU5IDIuNDEyLTEuMzU5IDEuMjU1IDAgMi4zMi44MiAyLjY4NSAxLjk1NS4yMTMtLjA4Mi40NDUtLjEyNi42ODktLjEyNiAxLjA3MiAwIDEuOTQzLjg3MSAxLjk0MyAxLjk0NHMtLjg2OSAxLjk0NS0xLjk0MiAxLjk0NXptNy44NDYgNS4xNTljMCAyLjUzOS0xLjc5MSA1Ljc1LTUgNi45NjN2LTIuMTZjMy4xNTQtMS44MyAzLjk2OS02LjI1NSAxLjU1My02LjI1NWgtMS41NTN2LTJoMS45MTJjMi4xNDQgMCAzLjA4OCAxLjUzNCAzLjA4OCAzLjQ1MnoiLz48L3N2Zz4=" />
                <span style={{ marginLeft: 5 }}>
                  {record.drinks_per_week ? record.drinks_per_week : 0} / week
                </span>

                <SmokingRooms style={{ marginLeft: 15, fontSize: 30 }} />
                <span style={{ marginLeft: 5 }}>
                  {record.cigrattes_per_day ? record.cigrattes_per_day : 0} /
                  day
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Allergy: </span>
                <span>
                  {record.allergy ? record.allergy : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Medication: </span>
                <span>
                  {record.medication ? record.medication : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>
                  History of medical issues:{" "}
                </span>
                <span>
                  {record.medical_issues
                    ? record.medical_issues
                    : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>
                  Current medical issue:{" "}
                </span>
                <span>
                  {record.currentMedicalIssue
                    ? record.currentMedicalIssue
                    : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Surgeries: </span>
                <span>
                  {record.surgeries ? record.surgeries : "No info available"}
                </span>
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Email: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.email ? record.email : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Phone number: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.phone_number
                      ? `+1${record.phone_number}`
                      : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Date of birth: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.patientDob
                      ? record.patientDob.split(" ").join("/")
                      : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Pharmacy name: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.pharmacy_Name
                      ? record.pharmacy_Name
                      : "No info available"}
                  </span>
                )}
              </div>
              <div style={{ marginTop: 15 }}>
                <span style={{ color: "#636e72" }}>Pharmacy NCPDPID: </span>
                {isFetching ? (
                  <span style={{ color: "#c5c2c2" }}>loading...</span>
                ) : (
                  <span>
                    {record.pharmacy_NCPDPID
                      ? record.pharmacy_NCPDPID
                      : "No info available"}
                  </span>
                )}
              </div>
            </div>
          }
        />
      )
    }

    return null
  }
}

export default withStyles(styles)(PatientDetails)
