import React, { Component } from "react"
import Amplify, { Auth } from "aws-amplify"
import { withAuthenticator } from "aws-amplify-react"
import buildGraphQLProvider from "ra-data-graphql"
import "./App.css"
// import buildGraphQLProvider from 'ra-data-graphql-simple';
import { Admin, Resource } from "react-admin"
import authProvider from "./authProvider"
import AppSync from "./AppSync"
import customRoutes from "./customRoutes"
import AWSAppSyncClient from "aws-appsync"
import { AUTH_TYPE } from "aws-appsync/lib/link/auth-link"
import buildQuery from "./queryBuilder"
import LoginPage from "./LoginPage"
import Dashboard from "./dashboard"
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import Loading from "./Components/CustomLoading"
import encryptConsult from "./util/encryptConsult"

import {
  CustomSignIn,
  CustomForgotPassword,
  CustomRequireNewPassword,
  CustomVerifyContact,
} from "./Authentication"

import { createMuiTheme } from "@material-ui/core/styles"
import LogoutButton from "./Components/LogoutButton"
import logo from "./Images/dermio-logo.svg"
import CustomLayout from "./customLayout/CustomLayout"
import { fetchDoctorDetails } from "./queries/fetchDoctorDetails"
import { resourceList } from "./resourceList"

import { inactiveChecker } from "./logoutOnInactive"
import decryptConsult from "./util/decryptConsult"
import fetchPracticeAdmin from "./queries/fetchPracticeAdmin"
import fetchPracticeUser from "./queries/fetchPracticeUser"

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
  },
  Storage: {
    bucket: process.env.REACT_APP_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket
    region: process.env.REACT_APP_BUCKET_REGION, //OPTIONAL -  Amazon service region
  },
})

const enablePatientNameFiltering = requestHandler => async (
  type,
  resource,
  params
) => {
  if (
    type === "GET_LIST" &&
    resource === "Consult" &&
    (params.filter.patientname || params.filter.firstname || params.filter.lastname)
  ) {
    let filter = { ...params.filter };
    if (params.filter.lastname) {
      const { lastname } = await encryptConsult({
        lastname: params.filter.lastname.toLowerCase(),
      })
      filter = { ...params.filter, lastname }
    } else if (params.filter.firstname) {
      const { firstname } = await encryptConsult({
        firstname: params.filter.firstname.toLowerCase(),
      })
      filter = { ...params.filter, firstname }
    } else if (params.filter.patientname) {
      const { patientname } = await encryptConsult({
        patientname: params.filter.patientname.toLowerCase(),
      })
      filter = { ...params.filter, patientname }
    }
    // console.log(filter)
    const updatedParams = {
      ...params,
      filter: filter,
    }

    return requestHandler(type, resource, updatedParams)
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params)
}

const client = new AWSAppSyncClient({
  url: AppSync.graphqlEndpoint,
  region: AppSync.region,
  disableOffline: true,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
})

const theme = createMuiTheme({
  palette: {
    primary: { 300: "#647073", 500: "#3fcff3", 700: "#3c4345" },
    secondary: { A400: "#3c4345" },
  },
  typography: {
    fontSize: 18,
  },
  overrides: {},
})

class App extends Component {
  constructor() {
    super()
    this.state = { dataProvider: null, showConsult: true, isDeactivated: false }
    inactiveChecker()
  }

  async componentDidMount() {
    buildGraphQLProvider({ client, buildQuery }).then(dataProvider => {
      // console.log(dataProvider, "...");
      this.setState({ dataProvider })
    })

    let doctorId = localStorage.getItem("userId")
    if (!doctorId) {
      try {
        // const currentSessionRes = await Auth.currentSession();
        const currentSessionRes = await Auth.currentAuthenticatedUser()

        // if (currentSessionRes.idToken.payload['cognito:groups'][0] === 'doctor') {
        // doctorId = currentSessionRes.idToken.payload["sub"];
        doctorId = currentSessionRes.attributes.sub
        try {
          const doctorDetails = await fetchDoctorDetails(doctorId)
          if(doctorDetails){
            this.setState({
              showConsult: doctorDetails.enabled,
              isDeactivated: doctorDetails.deactivated
            })
          }
        } catch (e) {
          console.error(e)
        }
        // }
      } catch (e) {
        console.error(e)
      }
    } else {
      try {
        const doctorDetails = await fetchDoctorDetails(doctorId)
        if(doctorDetails){
          this.setState({
            showConsult: doctorDetails.enabled,
            isDeactivated: doctorDetails.deactivated
          })
        }
      } catch (e) {
        console.error(e)
      }
    }

    // Check if user is Practice admin
    Auth.currentSession().then(async res => {
      const roles = res.idToken.payload["cognito:groups"]
      // console.log("roles", roles)
      const role = roles[0]
      if(role && role === "practiceAdmin"){
        // console.log("role ",role);
        const {data: { Admin }} = await fetchPracticeAdmin(res.idToken.payload["sub"]);
        if(Admin && Admin.practiceId){
          localStorage.setItem("adminPracticeId", Admin.practiceId);
        }
      }

      if(role && (role === "staff" || role === "nurse")){
        const {data: { PracticeUser }} = await fetchPracticeUser(res.idToken.payload["sub"]);
        // console.log(PracticeUser)
        if(PracticeUser && PracticeUser.practiceId){
          localStorage.setItem("userPracticeId", PracticeUser.practiceId);
        }
      }
    })
  }

  showConsultTab() {
    this.setState({
      showConsult: true,
    })
  }

  render() {
    const { dataProvider,showConsult, isDeactivated } = this.state

    if (!dataProvider) {
      return <Loading />
    }

    return (
      <Admin
        appLayout={
          CustomLayout
          // userId={localStorage.getItem("userId")}
          // doctorDetails={localStorage.getItem("doctorDetails")}
        }
        theme={theme}
        loginPage={LoginPage}
        dashboard={Dashboard}
        dataProvider={enablePatientNameFiltering(dataProvider)}
        customRoutes={customRoutes}
        authProvider={authProvider}
        title={
          <Link to="/">
            <img src={logo} alt="dermio-logo" />
          </Link>
        }
        logoutButton={LogoutButton}
      >
        {role => resourceList(role, showConsult, isDeactivated)}
      </Admin>
    )
  }
}

export default withAuthenticator(App, false, [
  <CustomSignIn />,
  <CustomForgotPassword />,
  <CustomRequireNewPassword />,
  <CustomVerifyContact />,
])
