import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const mutationDeleteUnavailability = gql`
mutation deleteUnavailability(
  $id:ID!
){
  deleteUnavailability(id: $id){
    id
  } 
}
`

const deleteUnavailability= async variables => {
  const deleteUnavailabilityResult = await client.hydrated().then(function (cl) {
    const mutation = cl.mutate({
      mutation: mutationDeleteUnavailability,
      variables: variables,
      fetchPolicy: "no-cache",
    })
    return mutation
  })

  return deleteUnavailabilityResult
}

export default deleteUnavailability