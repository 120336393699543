import states from "../staticData/USstates"
import React from "react"

export default class StateSearch extends React.Component {
  constructor() {
    super()
    this.state = {
      suggestionList: [],
      val: "",
      add: false,
      licensedStates: [],
      search: "",
    }
  }
  getSuggestions = e => {
    this.setState({ search: e.target.value })
    let searchTerm = e.target.value
      .trim()
      .slice()
      .toLowerCase()
    let val = searchTerm.length
    //console.log(typeof searchTerm);
    //console.log(searchTerm);
    let suggestions = states.filter(
      eachstate =>
        eachstate
          .trim()
          .slice(0, val)
          .toLowerCase() == searchTerm
    )
    this.setState({ suggestionList: suggestions })
  }
  addToLicensedState = async e => {
    e.preventDefault()
    await this.setState({
      licensedStates: [...this.state.licensedStates, this.state.val],
    })
    this.props.fieldUpdate(this.state.licensedStates, "LicensedState")
    //console.log(this.props.data["LicensedState"]);
    this.clear()
  }
  clear = () => {
    this.setState({ val: "", add: false, search: "" })
  }
  render() {
    return (
      <div>
        <input
          type="text"
          placeholder="enter state here"
          onChange={this.getSuggestions}
          value={this.state.add ? this.state.val : this.state.search}
        />
        <ul>
          {this.state.suggestionList.map((suggestion, key) => {
            return (
              <li key={key}>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    this.setState({
                      val: suggestion,
                      add: true,
                      suggestionList: [],
                    })
                  }}
                >
                  {suggestion}
                </a>
              </li>
            )
          })}
        </ul>
        <button onClick={this.addToLicensedState}>Add</button>
      </div>
    )
  }
}
