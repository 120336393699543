import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { Auth } from "aws-amplify"
import Messenger from "./Messenger"
import fetchConsultChat from "../queries/fetchConsultChat"
import createChatMutation from "../queries/createChatMutation"
import updatePatientChats, {
  mutationUpdateChatsGen,
  mutationUpdateChatsRead,
} from "../queries/updatePatientChats"

import gql from "graphql-tag"
import Appsync from "../util/client"

const client = Appsync()

const query = gql`
  query doctor($id: ID!) {
    doctorDetails: Doctor(id: $id) {
      practice {
        id
        website
      }
    }
  }
`

const styles = theme => ({
  dialog: {
    display: "flex",
    backgroundColor: "#000",
  },
  flex: {
    flex: 1,
  },
  button: {
    margin: theme.spacing.unit,
  },
})

class FullScreenDialog extends Component {
  state = {
    open: false,
    data: {
      consultChatMsgs: [],
    },
    practice: {},
    isSendingText: false,
    viewedMessages: [],
  }

  componentDidMount = async () => {
    const { record } = this.props
    // console.log(this.props, "this.props")

    if (!record.practiceId) {
      try {
        // let doctorId = localStorage.getItem("userId")
        let doctorId = record.doctorId
        // console.log("==========doctorId=======", doctorId)
        if (!doctorId) {
          try {
            const currentSessionRes = await Auth.currentSession()

            doctorId = currentSessionRes.idToken.payload["sub"]
          } catch (e) {
            console.error(e)
          }
        }

        const cl = await client.hydrated()
        const res = await cl.query({
          query,
          variables: { id: doctorId },
          fetchPolicy: "no-cache",
        })
        // console.log("*********result *******", res)
        const { practice } = res.data.doctorDetails

        this.setState({
          practice,
        })
      } catch (e) {
        console.error(e)
      }
    }

    if (record.status !== "pending") {
      try {
        const data = await fetchConsultChat(record)

        const consultChatMsgs = data.data.allChats

        this.setState({
          data: {
            consultChatMsgs: [...consultChatMsgs],
            record,
          },
        })

        const patientChatsIdArr = this.state.data.consultChatMsgs
          .filter(chat => chat.senderRole === "patient" && chat.seen === false)
          .map(patientChat => patientChat.id)

        const doctorUnreadChatsId = this.state.data.consultChatMsgs
          .filter(chat => chat.senderRole === "patient" && !chat.read)
          .map(patientChat => patientChat.id)

        if (patientChatsIdArr.length) {
          this.setState({ viewedMessages: patientChatsIdArr })

          // console.log("doctorUnreadChatsId ", doctorUnreadChatsId)
          // Check unread message id array and update read:true
          if (doctorUnreadChatsId && doctorUnreadChatsId.length) {
            await updatePatientChats(
              mutationUpdateChatsRead(doctorUnreadChatsId)
            )
              .then(data => {
                console.log("Updated doctorUnreadChatsId ")
              })
              .catch(e => {
                console.error(e)
                console.log("Updated error ",e)
              })
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  sendChatMessage = async (e, chatMessage) => {
    this.setState({ isSendingText: true })

    const { record } = this.props
    const { practice, viewedMessages } = this.state

    if (viewedMessages && viewedMessages.length) {
      // Set chat:seen=true only after replying to message
      await updatePatientChats(mutationUpdateChatsGen(viewedMessages))
        .then(data => {})
        .catch(e => {
          console.error(e)
        })
    }

    await createChatMutation(chatMessage, record, practice)
      .then(data => {
        let { consultChatMsgs } = this.state.data

        consultChatMsgs = [...consultChatMsgs, data.data.createDoctorChat]

        this.setState({
          data: { consultChatMsgs, record },
          isSendingText: false,
        })
      })
      .catch(e => {
        console.error(e)
        let { consultChatMsgs } = this.state.data
        this.setState({
          data: { consultChatMsgs },
          isSendingText: false,
        })
      })
  }

  render() {
    const { permissions } = this.props

    // console.log(this.state.data.consultChatMsgs)

    return (
      <Fragment>
        <Messenger
          permissions={permissions}
          chatMsgs={this.state.data.consultChatMsgs}
          consultRecord={this.state.data.record}
          sendChatMessage={this.sendChatMessage}
          isSendingText={this.state.isSendingText}
        />
      </Fragment>
    )
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FullScreenDialog)
