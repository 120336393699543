import { TypeKind } from "graphql"
import gql from "graphql-tag"
import { v4 as uuid } from "uuid"
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  // WARNING : Currently commented out inorder to prevent user from deleting data
  DELETE,
} from "react-admin/lib"
import { QUERY_TYPES } from "ra-data-graphql"
import { Auth } from "aws-amplify"
import { encodeQuery, encodeMutation } from "./graphqlify"
/**
 * Ensure we get the real type even if the root type is NON_NULL or LIST
 * @param {GraphQLType} type
 */
export const getFinalType = type => {
  if (type.kind === TypeKind.NON_NULL || type.kind === TypeKind.LIST) {
    return getFinalType(type.ofType)
  }

  return type
}

/**
 * Check wether the type is a LIST (or a NON_NULL LIST)
 * @param {GraphQLType} type
 */
export const isList = type => {
  if (type.kind === TypeKind.NON_NULL) {
    return isList(type.ofType)
  }

  return type.kind === TypeKind.LIST
}

let existingResults = {}
let startFresh = false
window.nextTokenDict = {}

const transform = fileObj => {
  const f = fileObj.rawFile

  const { name, type: mimeType } = f
  const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(name)
  const key = [uuid(), extension].filter(x => !!x).join(".")
  const file = {
    region: process.env.REACT_APP_BUCKET_REGION,
    bucket: process.env.REACT_APP_BUCKET_NAME,
    key: `public/${key}`,
    mimeType,
    localUri: f,
  }
  return file
}
export const buildFields = introspectionResults => fields =>
  fields.reduce((acc, field) => {
    const type = getFinalType(field.type)

    if (type.name.startsWith("_")) {
      return acc
    }

    if (type.kind !== TypeKind.OBJECT) {
      return { ...acc, [field.name]: {} }
    }

    const linkedResource = introspectionResults.resources.find(
      r => r.type.name === type.name
    )

    if (linkedResource) {
      return { ...acc, [field.name]: { fields: { id: {} } } }
    }

    const linkedType = introspectionResults.types.find(
      t => t.name === type.name
    )

    if (linkedType) {
      return {
        ...acc,
        [field.name]: {
          fields: buildFields(introspectionResults)(linkedType.fields),
        },
      }
    }

    // NOTE: We might have to handle linked types which are not resources but will have to be careful about
    // ending with endless circular dependencies
    return acc
  }, {})

const isRequired = type => {
  if (type.kind === TypeKind.LIST) {
    return isRequired(type.ofType)
  }

  return type.kind === TypeKind.NON_NULL
}

export const getArgType = arg => {
  const type = getFinalType(arg.type)
  const required = isRequired(arg.type)
  const list = isList(arg.type)

  return `${list ? "[" : ""}${type.name}${list ? "!]" : ""}${
    required ? "!" : ""
  }`
}

export const buildArgs = (query, variables) => {
  if (query.args.length === 0) {
    return ""
  }

  const validVariables = Object.keys(variables).filter(
    // k => !!variables[k] && variables[k] !== null
    k => variables[k] !== null
  )
  let args = query.args
    .filter(a => validVariables.includes(a.name))
    .reduce((acc, arg) => ({ ...acc, [`${arg.name}`]: `$${arg.name}` }), {})

  if (
    query.name === "allDoctors" &&
    (variables && variables.role && variables.role === "practiceAdmin")
  ) {
    args.practiceId = "$practiceId"
  }

  return args
}

export const buildApolloArgs = (query, variables) => {
  if (query.args.length === 0) {
    return ""
  }

  const validVariables = Object.keys(variables).filter(
    // k => !!variables[k] && variables[k] !== null
    k => variables[k] !== null
  )

  let args = query.args
    .filter(a => validVariables.includes(a.name))
    .reduce((acc, arg) => {
      if (arg.name.endsWith("Ids")) {
        return { ...acc, [`$${arg.name}`]: "[ID!]" }
      }

      // Test this before integrating

      // if (arg.name.endsWith('Id')) {
      // return { ...acc, [`$${arg.name}`]: 'ID' };
      // }

      return { ...acc, [`$${arg.name}`]: getArgType(arg) }
    }, {})

  return args
}

// NOTE: Building queries by merging/concatenating strings is bad and dirty!
// The ApolloClient.query method accepts an object of the shape { query, variables }.
// The query is actually a DocumentNode which is builded by the gql tag function.
// We should investigate how to build such DocumentNode from introspection results
// as it would be more robust.
export const buildQuery = introspectionResults => (
  resource,
  aorFetchType,
  queryType,
  variables
) => {
  const apolloArgs = buildApolloArgs(queryType, variables)
  const args = buildArgs(queryType, variables)
  const fields = buildFields(introspectionResults)(resource.type.fields)
  if (
    aorFetchType === GET_LIST ||
    aorFetchType === GET_MANY ||
    aorFetchType === GET_MANY_REFERENCE
  ) {
    const finalFields = { items: { fields }, nextToken: {} }
    const result = encodeQuery(queryType.name, {
      params: apolloArgs,
      fields: {
        items: {
          field: queryType.name,
          params: args,
          fields: finalFields,
        },
      },
    })
    return result
  }

  // WARNING : Currently commented out inorder to prevent user from deleting data
  if (aorFetchType === DELETE) {
    return encodeMutation(queryType.name, {
      params: apolloArgs,
      fields: {
        data: {
          field: queryType.name,
          params: args,
          fields: { id: {} },
        },
      },
    })
  }

  const query = {
    params: apolloArgs,
    fields: {
      data: {
        field: queryType.name,
        params: args,
        fields,
      },
    },
  }

  const result = QUERY_TYPES.includes(aorFetchType)
    ? encodeQuery(queryType.name, query)
    : encodeMutation(queryType.name, query)

  return result
}

export const buildVariables = introspectionResults => (
  resource,
  aorFetchType,
  params,
  queryType
) => {
  switch (aorFetchType) {
    case GET_LIST: {
      const filter = Object.keys(params.filter).reduce((acc, key) => {
        if (key === "archived") {
          if (params.filter[key] === null || params.filter[key] === false) {
            return {
              ...acc,
              [key]: {
                ne: true,
              },
            }
          }

          return {
            ...acc,
            [key]: {
              eq: params.filter[key],
            },
          }
        }

        if (key === "ids") {
          return { ...acc }
        }

        // if (typeof params.filter[key] === 'object') {
        //     const type = introspectionResults.types.find(
        //         t => t.name === `${resource.type.name}Filter`
        //     );
        //     const filterSome = type.inputFields.find(
        //         t => t.name === `${key}_some`
        //     );

        //     if (filterSome) {
        //         const filter = Object.keys(params.filter[key]).reduce(
        //             (acc, k) => ({
        //                 ...acc,
        //                 [`${k}_in`]: params.filter[key][k],
        //             }),
        //             {}
        //         );
        //         return { ...acc, [`${key}_some`]: filter };
        //     }
        // }

        const parts = key.split(".")

        if (parts.length > 1) {
          if (parts[1] == "id") {
            // const type = introspectionResults.types.find(
            //     t => t.name === `${resource.type.name}Filter`
            // );
            // const filterSome = type.inputFields.find(
            //     t => t.name === `${parts[0]}_some`
            // );

            // if (filterSome) {
            return {
              ...acc,
              [`${parts[0]}Id`]: {
                eq: params.filter[key],
              },
              // };
            }
          }
        }

        const resourceField = resource.type.fields.find(f => f.name === key)
        if (resourceField.type.name === "Int") {
          return {
            ...acc,
            [key]: {
              eq: parseInt(params.filter[key]),
            },
          }
        }
        if (resourceField.type.name === "Float") {
          return {
            ...acc,
            [key]: {
              eq: parseFloat(params.filter[key]),
            },
          }
        }
        console.log("resourceField ", resourceField)
        if (resourceField.type.name === "String") {
          if (key === "status") {
            return {
              ...acc,
              [key]: {
                eq: params.filter[key],
              },
            }
          }

          return {
            ...acc,
            [key]: {
              contains: params.filter[key],
            },
          }
        }

        if (resourceField.name === "licensedStates") {
          return {
            ...acc,
            [key]: {
              contains: params.filter[key],
            },
          }
        }

        return {
          ...acc,
          [key]: {
            eq: params.filter[key],
          },
        }
      }, {})
      const resourceName = resource.type.name
      const tok = window.nextTokenDict[resourceName]
      let finalToken = null
      if (params.pagination.page != 1) {
        finalToken = tok
        startFresh = false
      } else {
        // when the page is 1
        window.nextTokenDict[resourceName] = null
        finalToken = null
        startFresh = true
      }

      // allConsults query change by status Index
      let status = filter.status
      if (resourceName === "Consult" && (status && status.eq)) {
        let tmpFilter = { ...filter }
        delete tmpFilter.status
        return {
          skip: parseInt(
            (params.pagination.page - 1) * params.pagination.perPage
          ),
          first: parseInt(params.pagination.perPage),
          orderBy: `${params.sort.field}_${params.sort.order}`,
          status: status,
          nextToken: finalToken,
          filter: tmpFilter,
        }
      }

      if (
        resourceName === "Doctor" &&
        (filter && filter.role && filter.role.contains === "practiceAdmin")
      ) {
        let tmpFilter = { ...filter }
        delete tmpFilter.role
        delete tmpFilter.practiceId
        return {
          skip: parseInt(
            (params.pagination.page - 1) * params.pagination.perPage
          ),
          first: parseInt(params.pagination.perPage),
          orderBy: `${params.sort.field}_${params.sort.order}`,
          nextToken: finalToken,
          filter: tmpFilter,
          practiceId: filter.practiceId.eq,
          role: filter.role.contains,
        }
      }

      // allVideoCalls query change by doctorId Index
      if (
        resourceName === "VideoCall" &&
        (filter.doctorId && filter.doctorId.eq)
      ) {
        let tmpFilter = { ...filter }
        delete tmpFilter.doctorId
        return {
          skip: parseInt(
            (params.pagination.page - 1) * params.pagination.perPage
          ),
          first: parseInt(params.pagination.perPage),
          orderBy: `${params.sort.field}_${params.sort.order}`,
          doctorId: filter.doctorId.eq,
          nextToken: finalToken,
          filter: tmpFilter,
        }
      }

      return {
        skip: parseInt(
          (params.pagination.page - 1) * params.pagination.perPage
        ),
        first: parseInt(params.pagination.perPage),
        orderBy: `${params.sort.field}_${params.sort.order}`,
        nextToken: finalToken,
        filter,
      }
    }
    case GET_MANY:
      return {
        filter: {},
      }
    case GET_MANY_REFERENCE: {
      const parts = params.target.split(".")

      return {
        filter: {},
      }
    }
    case GET_ONE:
      return {
        id: params.id,
      }
    case UPDATE: {
      return Object.keys(params.data).reduce((acc, key) => {
        if (Array.isArray(params.data[key])) {
          const arg = queryType.args.find(a => a.name === `${key}Ids`)

          if (arg) {
            return {
              ...acc,
              [`${key}Ids`]: params.data[key].map(({ id }) => id),
            }
          }
        }

        if (
          key === "file" ||
          key === "botAvatarFile" ||
          key === "userAvatarFile"
        ) {
          if (params.data[key] && params.data[key].rawFile) {
            const file = transform(params.data[key])
            const url = `https://${file.bucket}.s3.us-east-1.amazonaws.com/${file.key}`
            return {
              ...acc,
              [key]: file,
              url,
            }
          } else {
            return acc
          }
        }
        // if (typeof params.data[key] === "object") {
        //     const arg = queryType.args.find(a => a.name === `${key}Id`);

        //     if (arg) {
        //         return {
        //             ...acc,
        //             [`${key}Id`]: params.data[key].id,
        //         };
        //     }
        // }

        if (params.data[key] === "") {
          return {
            ...acc,
            [key]: " ",
          }
        }

        return {
          ...acc,
          [key]: params.data[key],
        }
      }, {})
    }

    case CREATE: {
      return Object.keys(params.data).reduce((acc, key) => {
        if (Array.isArray(params.data[key])) {
          const arg = queryType.args.find(a => a.name === `${key}Ids`)

          if (arg) {
            return {
              ...acc,
              [`${key}Ids`]: params.data[key].map(({ id }) => id),
            }
          } else {
            return {
              ...acc,
              [key]: params.data[key],
            }
          }
        }

        if (typeof params.data[key] === "object") {
          const arg = queryType.args.find(a => a.name === `${key}Id`)

          if (arg) {
            return {
              ...acc,
              [`${key}Id`]: params.data[key].id,
            }
          }
        }

        return {
          ...acc,
          [key]: params.data[key],
        }
      }, {})
    }

    // WARNING : Currently commented out inorder to prevent user from deleting data
    case DELETE:
      return {
        id: params.id,
      }
  }
}

export const sanitizeResource = (introspectionResults, resource) => data => {
  const result = Object.keys(data).reduce((acc, key) => {
    if (key.startsWith("_")) {
      return acc
    }

    const field = resource.type.fields.find(f => f.name === key)
    const type = getFinalType(field.type)

    if (type.kind !== TypeKind.OBJECT) {
      return { ...acc, [field.name]: data[field.name] }
    }

    // FIXME: We might have to handle linked types which are not resources but will have to be careful about
    // endless circular dependencies
    const linkedResource = introspectionResults.resources.find(
      r => r.type.name === type.name
    )

    if (linkedResource) {
      if (Array.isArray(data[field.name])) {
        return {
          ...acc,
          [field.name]: data[field.name].map(
            sanitizeResource(introspectionResults, linkedResource)
          ),
          [`${field.name}Ids`]: data[field.name].map(d => d.id),
        }
      }

      return {
        ...acc,
        [`${field.name}.id`]: data[field.name].id,
        [field.name]: sanitizeResource(introspectionResults, linkedResource)(
          data[field.name]
        ),
      }
    }
    return { ...acc, [field.name]: data[field.name] }
  }, {})

  return result
}

export const getResponseParser = introspectionResults => (
  aorFetchType,
  resource
) => response => {
  const sanitize = sanitizeResource(introspectionResults, resource)
  const data = response.data

  const resourceName = resource.type.name
  if (
    aorFetchType === GET_LIST ||
    aorFetchType === GET_MANY ||
    aorFetchType === GET_MANY_REFERENCE
  ) {
    const existingRes = existingResults[resourceName] || []
    const results = data.items.items
    const nextToken = data.items.nextToken
    const tok = window.nextTokenDict[resourceName]
    window.nextTokenDict[resourceName] = nextToken
    let finalRes
    if (!startFresh && tok && existingRes.length !== 0) {
      finalRes = [...results, ...existingRes]
    } else {
      finalRes = results
    }
    existingResults[resourceName] = finalRes
    return {
      data: finalRes.map(sanitize),
      total: finalRes.length,
    }
  }

  return { data: sanitize(data.data) }
}

export default introspectionResults => {
  const knownResources = introspectionResults.resources.map(r => r.type.name)
  return (aorFetchType, resourceName, params) => {
    if (params.customType) {
      return {
        query: gql`
          ${params.query}
        `,
        variables: params.variables,
        parseResponse: res => {
          return { data: [], total: 2, res } // have data, total as dummy thing to weedout warning
        },
      }
    }
    const resource = introspectionResults.resources.find(
      r => r.type.name === resourceName
    )

    if (!resource) {
      throw new Error(
        `Unknown resource ${resource}. Make sure it has been declared on your server side schema. Known resources are ${knownResources.join(
          ", "
        )}`
      )
    }

    const queryType = resource[aorFetchType]

    if (!queryType) {
      throw new Error(
        `No query or mutation matching aor fetch type ${aorFetchType} could be found for resource ${resource.type.name}`
      )
    }

    const variables = buildVariables(introspectionResults)(
      resource,
      aorFetchType,
      params,
      queryType
    )
    const query = buildQuery(introspectionResults)(
      resource,
      aorFetchType,
      queryType,
      variables
    )
    const parseResponse = getResponseParser(introspectionResults)(
      aorFetchType,
      resource,
      queryType
    )

    if (
      resourceName === "Doctor" &&
      params.filter &&
      params.filter.role == "practiceAdmin"
    ) {
      return {
        query: gql`
          query allPracticeDoctors(
            $filter: TableDoctorFilterInput
            $practiceId: String
          ) {
            items: allPracticeDoctors(
              filter: $filter
              practiceId: $practiceId
            ) {
              items {
                accountNumber
                accountname
                address
                bio
                city
                clinicFax
                clinicPhone
                clinicZip
                dea
                deaName
                email
                enabled
                fax
                file
                firstName
                id
                lastName
                licensedStates
                name
                npi
                phone
                practice {
                  id
                }
                practiceId
                practiceName
                residentState
                role
                routingNumber
                state
                street
                stripeId
                url
                zipCode
                error
                notifyByEmail
                notifyBySms
                onVacation
                startVacationDate
                endVacationDate
                stateLicenseNumber
                videocallFee
                canPrescribe
                disableVideoCall
                prescribeInfoDisplayed
                createdAt
              }
              nextToken
            }
          }
        `,
        variables: variables,
        parseResponse,
      }
    }

    return {
      query: gql`
        ${query}
      `,
      variables,
      parseResponse,
    }
  }
}
