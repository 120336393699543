import React from "react"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import ChevronRight from "@material-ui/icons/ChevronRight"

export default ({ page, perPage, total, setPage, resource, ...props }) => {
  return (
    <Toolbar>
      <h5> Showing {total} results </h5>
      {window.nextTokenDict[resource] ? (
        <Button
          primary
          key="next"
          icon={<ChevronRight />}
          onClick={() => setPage(page + 1)}
          labelPosition="before"
        >
          Load more
        </Button>
      ) : null}
    </Toolbar>
  )
}
