import React, { Fragment } from "react"
import { ImageField, ImageInput } from "react-admin"
import AwsImageComponent from "./AwsImageComponent"

const BotAvatar = ({ record, permissions }) => {
  const styles = {
    height: "100px",
    width: "100px",
    display: "block",
  }

  let url = ""

  if (record.botAvatarFile) {
    url = `https://${record.botAvatarFile.bucket}.s3.us-east-1.amazonaws.com/${record.botAvatarFile.key}`
  }

  return (
    <Fragment>
      {record.botAvatarFile && (
        <AwsImageComponent
          style={styles}
          src={url}
          alt="bot Avatar"
          componentFrom="practiceLogo"
        />
      )}
      <ImageInput
        source="botAvatarFile"
        label={record.botAvatarFile ? "Update bot avatar" : "Select bot avatar"}
        accept="image/*"
        placeholder={<p>Drop your photo here</p>}
      >
        <ImageField source="image" title="title" />
      </ImageInput>
    </Fragment>
  )
}

export default BotAvatar
