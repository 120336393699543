import React from "react"
import AdminDashboard from "./adminDashboard/DashboardIndex"
import PracticeAdminDashboard from "./adminDashboard/PracticeAdminDashboardIndex"
import DoctorDashboard from "./doctorDashboard/DashboardIndex"

export default class Dashboard extends React.Component {
  state = {
    role: null,
    userId: null,
  }

  render() {
    const role = this.props.permissions
    
    if (!role) return null
    if(role === "practiceAdmin" || role === "nurse" || role === "staff") return <PracticeAdminDashboard />
    if (role === "admin") return <AdminDashboard />
    return <DoctorDashboard />
  }
}
