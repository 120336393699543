import React from "react"
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices,
} from "react-admin"

const requiredLicensedStates = (
  message = "Atleast one licensed state must be selected"
) => (value, allValues, props) => {
  return value && value.length ? (
    undefined
  ) : (
    <span style={{ color: "red" }}>{props.translate(message)}</span>
  )
}

const validateFirstName = [required(), minLength(2), maxLength(50)]
const validateLastName = [required(), minLength(2), maxLength(20)]
const validateEmail = [required(), email()]
// regex() not working as expected
// const validatePhone = [regex(/^((\+1|1)\s?\-?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$/g, 'Please enter a valid phone number')];
const validateNpiNumber = [
  required(),
  number(),
  minLength(10, "NPI Number must be 10 digits exactly"),
  maxLength(10, "NPI Number must be 10 digits exactly"),
]
const validateDeaNumber = [
  required(),
  minLength(9, "DEA Number must be 9 characters"),
]
const validateLicensedStates = [requiredLicensedStates()]
const validatePractice = [required()]
const validateRequiredTextField = [required(), minLength(2)]
const validateRequiredTextFieldState = [required(), minLength(2), maxLength(2)]
const validateRequiredNumberField = [required(), number(), minValue(0)]
const validateRequiredPositiveNumberField = [required(), number(), minValue(1)]
const validateRequiredPhoneNumField = [
  required(),
  number(),
  minLength(10),
  maxLength(10),
]
const validateZipCode = [
  required(),
  regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Please enter a valid zipcode"),
]
const validateAltPhone = [number(), maxLength(10)]
const validateText = [required(), maxLength(50)]
const validateRequiredAddressField = [required(), minLength(2), maxLength(35)]
const validatePositiveNumberField = [number(), minValue(1)]

// Only method that works for now for phone validation.
const validatePhone = values => {
  // console.log('form', values);
  const errors = {}
  const phoneRegex = /^((\([0-9]{3}\))|[0-9]{3})[\s\-]?[0-9]{3}[\s\-]?[0-9]{4}$/g
  if (!phoneRegex.test(values.phone)) {
    errors.phone = ["Please enter a valid phone number"]
  }
  return errors
}

export {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validateNpiNumber,
  validateDeaNumber,
  validateLicensedStates,
  validatePractice,
  validateZipCode,
  validateRequiredTextField,
  validateRequiredTextFieldState,
  validateRequiredNumberField,
  validateRequiredPhoneNumField,
  validateAltPhone,
  validateText,
  validateRequiredAddressField,
  validateRequiredPositiveNumberField,
  validatePositiveNumberField
}
