import React from "react"
import compose from "recompose/compose"
import Card from "@material-ui/core/Card"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import avatar from "../../Chat/images/stock-avatar.png"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CommentIcon from "@material-ui/icons/Comment"
import Divider from "@material-ui/core/Divider"
import { Link } from "react-router-dom"
import { translate } from "react-admin"
import CardIcon from "./CardIcon"
// import Storage from '@aws-amplify/storage';
import checkIsImage from "../../util/checkIsImage"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import DoneAllIcon from "@material-ui/icons/DoneAll"

const styles = theme => ({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
    marginBottom: 20,
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
  },
  value: {
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
})

const DoctorConsults = ({ messages = [], classes }) => (
  <div className={classes.main}>
    <CardIcon Icon={CommentIcon} bgColor="#3fcff3" />
    <Card className={classes.card}>
      <Typography className={classes.title} style={{ color: "#3fcff3" }}>
        New Messages
      </Typography>
      <Typography variant="headline" component="h2" className={classes.value}>
        {" "}
        {messages.length}
      </Typography>
      <Divider />
      <List style={{ maxHeight: "50vh", overflow: "auto" }}>
        {messages
          .sort((a, b) => {
            // sort latest messages to display at the top
            return new Date(b.createdAt) - new Date(a.createdAt)
          })
          .map(messageObj => (
            <ListItem
              key={messageObj.id}
              button
              component={Link}
              to={`/Consult/${messageObj.consultId}/show`}
              style={{ padding: "10px" }}
            >
              {/* <Avatar > {messageObj.consultId}</Avatar> */}
              {/* <Avatar><img src={avatar} alt="default user avatar" /></Avatar>   */}
              <ListItemText
                primary={
                  <span>
                    {/* {messageObj.name.split(' ')[0]
                      ? `\nMessage from ${messageObj.name.split(' ')[0]} : ${
                      messageObj.message
                        ? messageObj.message.slice(0, 10) + "..."
                        : "Image"
                      }`
                      :  */}
                    Message:{" "}
                    {messageObj.message
                      ? messageObj.message.slice(0, 10) + "..."
                      : checkIsImage(messageObj.url)
                      ? "Image"
                      : "File"}
                    {/* } */}
                  </span>
                }
                secondary={new Date(messageObj.createdAt).toLocaleString()}
                className={classes.listItemText}
                style={{ paddingRight: 0 }}
              />
              <ListItemIcon>
                <DoneAllIcon
                  style={{
                    color: messageObj.read ? "#3fcff3" : "#989898",
                    margin: "0px",
                  }}
                />
              </ListItemIcon>
            </ListItem>
          ))}
      </List>
    </Card>
  </div>
)

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(DoctorConsults)
