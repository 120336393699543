import React, { Fragment } from "react"
import { change } from "redux-form"
import {
  ReferenceInput,
  SelectInput,
  required,
  SelectArrayInput,
  Edit,
  EditController,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  FormDataConsumer,
  Link,
  BooleanInput,
  REDUX_FORM_NAME,
  NumberInput,
  AutocompleteInput
} from "react-admin"
import { DateTimeInput } from "react-admin-date-inputs"
import logo from "../Images/dermio-logo.svg"
import { withStyles } from "@material-ui/core/styles"
import DoctorEnableSwitch from "../Components/DoctorEnableSwitch"
import DoctorBankDetails from "../Components/DoctorBankDetails"
import DoctorImage from "../Components/DoctorImage"
import CustomEditActions from "../Components/CustomEditActions"
import CustomEditToolbar from "../Components/CustomEditToolbar"
import ConstructDoctorName from "../Components/ConstructDoctorName"
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhone,
  validateNpiNumber,
  validateDeaNumber,
  validateLicensedStates,
  validatePractice,
  validateRequiredPhoneNumField,
  validateText,
  validatePositiveNumberField
} from "../util/validation"
import states from "../staticData/USstates"

export const DoctorIcon = null

const choiceStates = states.map(i => {
  return { id: i, name: i }
})

const styles = {
  root: {
    marginTop: "3rem",
  },
}

export const DoctorEdit = withStyles(styles)(props => {
  // const redirect = (basePath, id, data) => `/`;
  const redirect = (basePath, id, data) => `/Doctor/${data.id}`
  const { permissions, classes } = props
  const queryString = props.history.location.search
  return (
    <Edit
      actions={<CustomEditActions permissions={permissions} />}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      {...props}
      classes={classes}
    >
      <TabbedForm
        toolbar={<CustomEditToolbar permissions={permissions} />}
        submitOnEnter={false}
        redirect={permissions === "doctor" && redirect}
        validate={validatePhone}
        style={{ minHeight: "1250px" }}
      >
        <FormTab label="Basic Info">
          <p
            style={{
              width: "90%",
              fontSize: "1rem",
              marginTop: 18,
              marginBottom: 18,
              color: "#202124",
            }}
          >
            {queryString === "?fieldsNotFilled"
              ? "Note: Please fill the required fields and connect your bank account details to get your dashboard and consults."
              : queryString === "?fieldsFilled"
                ? "Note: Please make sure you have connected your bank details and wait until the admin activate your account."
                : ""}
          </p>
          <TextInput source="firstName" validate={validateFirstName} />
          <TextInput source="lastName" validate={validateLastName} />

          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <ConstructDoctorName source="name" formData={formData} />
            )}
          </FormDataConsumer>
          <TextInput source="email" type="email" validate={validateEmail} />
          <TextInput source="phone" validate={validateRequiredPhoneNumField} />
          {/* <TextInput source="phone" /> */}
          <div>
            <BooleanInput label="Is certified assistant?" source="isAssistant" />
            <p
              style={{
                width: "90%",
                fontSize: "12px",
                marginTop: 10,
                marginBottom: 18,
                color: "#aeaeae",
              }}
            >
              Ex:<span style={{ marginLeft: 2, color: "#828282" }}>Physician assistant or Nurse practitioner</span>
            </p>
          </div>
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              if (formData && formData.isAssistant) {
                return <div>
                  <TextInput source="assistantTitle" label="Assistant title" />
                  <p
                    style={{
                      width: "90%",
                      fontSize: "12px",
                      marginTop: 10,
                      marginBottom: 18,
                      color: "#aeaeae",
                    }}
                  >
                    Ex: PA-C
                  </p>
                </div>;
              } else {
                return <></>;
              }
            }}
          </FormDataConsumer>
          <DoctorImage permissions={permissions} />
          <LongTextInput source="bio" />
          <SelectArrayInput
            label="Licensed States"
            source="licensedStates"
            choices={choiceStates}
            validate={validateLicensedStates}
          />
          <TextInput
            source="npi"
            label="NPI Number"
            validate={validateNpiNumber}
          />
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              if (formData.canPrescribe || formData.dea) {
                return <></>
              } else {
                return <div style={{ marginTop: 15 }}>
                  <h4>Do you want to prescribe:</h4>
                  <BooleanInput label="Yes" source="canPrescribe" />
                </div>
              }
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              if (formData.canPrescribe || formData.dea) {
                return <><TextInput
                  source="dea"
                  label="DEA Number"
                  validate={validateDeaNumber}
                />
                  <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 15 }}>
                    Note: To prescribe DEA number is important.
                  </p>
                </>
              } else {
                return <></>
              }
            }}
          </FormDataConsumer>
          <TextInput
            source="stateLicenseNumber"
            label="State License Number"
            validate={[required()]}
          />
          {/* <TextInput
            source="website"
            label="Practice Website"
          // validate={validateText}
          /> */}
          <NumberInput
            source="videocallFee"
            label="Video call Fee"
            validate={validatePositiveNumberField}
          />
          <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 14 }}>
            Note: This video call fee will be added to your actual consult fee.</p>
          {permissions === "admin" && (
            <>
              <ReferenceInput
                label="Practice"
                source="practiceId"
                reference="Practice"
                validate={validatePractice}
                filterToQuery={searchText => {
                  console.log(searchText)
                  if (searchText.length > 3) {
                    return { name: searchText }
                  } else {
                    return {}
                  }
                }}>
                <AutocompleteInput
                  options={{
                    suggestionsContainerProps: {
                      disablePortal: true,
                    }
                  }}
                  optionText="name"
                  allowEmpty={true}
                />
              </ReferenceInput>
              <p
                style={{
                  width: "90%",
                  fontSize: "10px",
                  marginTop: 18,
                  marginBottom: 18,
                  color: "#aeaeae",
                }}
              >
                To clear practice: cmd+a or ctlr+a then backspace
              </p>
            </>
          )}

          {permissions === "doctor" && (
            <div style={{ marginTop: 15 }}>
              <h4>Get notifications through:</h4>
              <BooleanInput label="SMS" source="notifyBySms" />
              <BooleanInput label="Email" source="notifyByEmail" />
              <div style={{ marginTop: 15 }}>
                <h4>Opt out of dermio.com consults:</h4>
                <BooleanInput label="Enabled" source="optedOut" />
              </div>
            </div>
          )}
          {permissions === "doctor" && (
            <div style={{ marginTop: 15 }}>
              <h4>Vacation Toggle:</h4>
              <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => {
                  let vacationDates = [
                    <BooleanInput
                      label="On Vacation"
                      source="onVacation"
                      onChange={value => {
                        dispatch(
                          change(REDUX_FORM_NAME, "startVacationDate", null)
                        )

                        dispatch(
                          change(REDUX_FORM_NAME, "endVacationDate", null)
                        )
                      }}
                    />,
                  ]

                  if (formData.onVacation) {
                    vacationDates = [
                      <Fragment>
                        <BooleanInput
                          label="On Vacation"
                          source="onVacation"
                          onChange={value => {
                            dispatch(
                              change(REDUX_FORM_NAME, "startVacationDate", null)
                            )

                            dispatch(
                              change(REDUX_FORM_NAME, "endVacationDate", null)
                            )
                          }}
                        />
                        <DateTimeInput
                          source="startVacationDate"
                          label="Start Date"
                          options={{
                            format: "MM/dd/yyyy, HH:mm:ss",
                            ampm: false,
                            clearable: true,
                            disablePast: true,
                          }}
                          validate={[required()]}
                          {...rest}
                        />
                        <DateTimeInput
                          source="endVacationDate"
                          label="End Date"
                          options={{
                            format: "MM/dd/yyyy, HH:mm:ss",
                            ampm: false,
                            clearable: true,
                            disablePast: true,
                          }}
                          validate={[required()]}
                          {...rest}
                        />
                      </Fragment>,
                    ]
                  }

                  return vacationDates
                }}
              </FormDataConsumer>
            </div>
          )}
          <div style={{ marginTop: 15 }}>
            <h4>Disable video call:</h4>
            <BooleanInput
              label="Disable"
              source="disableVideoCall"
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <h4>Away from:</h4>
            <BooleanInput
              label="Disabled"
              source="deactivated"
            />
          </div>
          {(permissions === "admin" || permissions === "practiceAdmin") && (<div style={{ marginTop: 15 }}>
            <h4>Deactivate doctor:</h4>
            <BooleanInput
              label="Deactivate"
              source="deactivated"
            />
            <p style={{ color: "rgba(3, 3, 3, 0.74)", fontSize: 14 }}>
              Note: When you want deactivate also switch off the "Enabled" option.</p>
          </div>)}
          <DoctorBankDetails permissions={permissions} />
          {(permissions === "admin" || permissions === "practiceAdmin") && <DoctorEnableSwitch />}
        </FormTab>
      </TabbedForm>
    </Edit>
  )
})
