import React from "react"
import { CardActions, ListButton, RefreshButton } from "react-admin"

const CustomEditActions = ({ permissions, basePath, data, resource }) => (
  <CardActions>
    {permissions === "admin" && (
      <ListButton basePath={basePath} record={data} />
    )}
    {/* <RefreshButton basePath={basePath} record={data} /> */}
  </CardActions>
)

export default CustomEditActions
