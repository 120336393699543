import React, { Component } from "react"
import formatDistance from "date-fns/formatDistance"
import { connect } from "react-redux"
import compose from "recompose/compose"
import { showNotification } from "react-admin"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Link } from "react-router-dom"
import { translate } from "react-admin"
import Prism from "prismjs"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CodeIcon from "@material-ui/icons/Code"
import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import ShareIcon from "@material-ui/icons/Share"
import Divider from "@material-ui/core/Divider"

import "prismjs/themes/prism-okaidia.css"

import CardIcon from "../dashboard/doctorDashboard/CardIcon"
import { auto } from "async"

const styles = theme => ({
  main: {
    flex: "1",
    marginRight: "1em",
    marginTop: 20,
    marginBottom: 20,
  },
  titleLink: { textDecoration: "none", color: "inherit" },
  card: {
    overflow: "inherit",
    textAlign: "right",
    padding: 16,
  },
  value: {
    minHeight: 48,
  },
  avatar: {
    background: theme.palette.background.avatar,
  },
  listItemText: {
    overflowY: "hidden",
    height: "4em",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
  viewMoreBtn: {
    textAlign: "center",
  },
})

class SharePatientLink extends Component {
  state = {
    copied: false,
    clipBoardText: "",
  }

  componentDidMount = () => {
    const { url } = this.props
    if (url) {
      const clipBoardText = `${url}`

      return this.setState({
        clipBoardText,
      })
    }
  }

  componentDidUpdate = () => {
    Prism.highlightAll()
  }

  onCopy = () => {
    const { showNotification } = this.props

    this.setState({ copied: true })
    showNotification("Patient Portal link is copied to clipboard.")
  }

  render() {
    const { clipBoardText } = this.state
    const { children, classes } = this.props

    return (
      <div className={classes.main}>
        <CardIcon Icon={ShareIcon} bgColor="#3fcff3" />
        <Card className={classes.card}>
          <Typography className={classes.title} style={{ color: "#3fcff3" }}>
            Copy and share the patient portal link to sign up patients
          </Typography>
          <Divider />
          <div>
            <Typography
              style={{
                backgroundColor: "#000",
                color: "#fff",
                textShadow: "0 1px rgba(0, 0, 0, 0.3)",
                textAlign: "left",
                padding: "1em",
                margin: "0.5em 0",
                borderRadius: "0.3em",
              }}
            >
              {clipBoardText}
            </Typography>
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CopyToClipboard
                text={clipBoardText}
                onCopy={() => this.onCopy()}
              >
                <Button color="primary" variant="contained">
                  <CodeIcon style={{ paddingRight: "0.5em" }} />
                  Click to copy the Patient Portal link to clipboard
                </Button>
              </CopyToClipboard>
              {children}
            </div>
          </div>
        </Card>
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    null,
    { showNotification }
  )
)

export default enhance(SharePatientLink)
