import { Auth } from "aws-amplify"

export function inactiveChecker() {
  const activities = [
    "mousemove",
    "mousedown",
    "keypress",
    "DOMMouseScroll",
    "mousewheel",
    "touchmove",
    "MSPointerMove",
  ]
  activities.forEach(activity => {
    document.body.addEventListener(activity, resetTimer, false)
  })
  // document.body.addEventListener("mousemove", resetTimer, false);
  // document.body.addEventListener("mousedown", this.resetTimer, false);
  // document.body.addEventListener("keypress", this.resetTimer, false);
  // document.body.addEventListener("DOMMouseScroll", this.resetTimer, false);
  // document.body.addEventListener("mousewheel", this.resetTimer, false);
  // document.body.addEventListener("touchmove", this.resetTimer, false);
  // document.body.addEventListener("MSPointerMove", this.resetTimer, false);
  startTimer()

  let timeoutID

  function startTimer() {
    // wait 2 seconds before calling goInactive
    timeoutID = window.setTimeout(goInactive, 900000)
  }

  function resetTimer(e) {
    window.clearTimeout(timeoutID)
    goActive()
  }

  function goInactive() {
    Auth.signOut().then(res => {
      localStorage.removeItem("userId")
      localStorage.removeItem("doctorDetails")
      window.location.href = "/"
    })

    const activities = [
      "mousemove",
      "mousedown",
      "keypress",
      "DOMMouseScroll",
      "mousewheel",
      "touchmove",
      "MSPointerMove",
    ]
    activities.forEach(activity => {
      document.body.removeEventListener(activity, resetTimer)
    })
  }

  function goActive() {
    // do something
    startTimer()
  }
}
