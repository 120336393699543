import React, { Component, Fragment } from "react"
import {
  Filter,
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  ChipField,
  ArrayField,
  EditButton,
  TextInput,
  LongTextInput,
  ArrayInput,
  CloneButton,
  CardActions,
  ListButton,
  RefreshButton,
  Link,
  SaveButton,
  Responsive,
  SimpleList,
} from "react-admin"
import Pagination from "./Pagination"
import logo from "./Images/dermio-logo.svg"
import { validateText } from "./util/validation"

const CustomEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    {/* <RefreshButton basePath={basePath} record={data} /> */}
  </CardActions>
)

const filterFunc = () => {
  return {
    doctorId: localStorage.getItem("userId"),
  }
}

const adminFilterFunc = () => {
  return {
    createdBy: "admin",
  }
}

export const TemplateFilter = props => (
  <Filter {...props}>
    <TextInput label="Impression" source="impression" />
  </Filter>
)

export const TemplateList = ({ permissions, ...props }) => (
  <List
    {...props}
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    filter={filterFunc()}
    filters={<TemplateFilter />}
    bulkActionButtons={false}
  >
    <Responsive
      small={<SimpleList primaryText={record => record.impression} />}
      medium={
        <Datagrid>
          <TextField source="impression" label="Impression" />
          <EditButton />
          <CloneButton />
        </Datagrid>
      }
    />
  </List>
)

export const AdminTemplateList = ({ permissions, ...props }) => (
  <List
    {...props}
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    filter={adminFilterFunc()}
    filters={<TemplateFilter />}
  >
    <Responsive
      small={<SimpleList primaryText={record => record.impression} />}
      medium={
        <Datagrid>
          <TextField source="impression" label="Impression" />
          <EditButton />
          <CloneButton />
        </Datagrid>
      }
    />
  </List>
)

const TemplateTitle = ({ record }) => {
  return <span>Template {record ? `${record.impression}` : ""}</span>
}

export const TemplateEdit = props => {
  return (
    <Edit
      actions={<CustomEditActions />}
      title={
        <Link to="/">
          <img
            className="dashboard__img-logo"
            src={logo}
            alt="dermio-logo"
            style={{ paddingTop: "0.3em" }}
          />
        </Link>
      }
      style={{ marginTop: "4rem" }}
      {...props}
    >
      <SimpleForm
        defaultValue={
          props.permissions === "doctor"
            ? {
                doctorId: localStorage.getItem("userId"),
                createdBy: "doctor",
              }
            : { createdBy: "admin" }
        }
      >
        <TextInput
          source="impression"
          label="Impression"
          validate={validateText}
        />
        <LongTextInput
          source="impressionDescription"
          label="Impression description"
        />
        <LongTextInput
          source="skinCareInstructions"
          label="Skin care instructions"
        />
        <LongTextInput source="plan" label="Plan" resettable />
        <LongTextInput
          source="sideEffectsString"
          label="Side effects"
          resettable
        />
      </SimpleForm>
    </Edit>
  )
}

export const TemplateCreate = props => (
  <Create
    title={
      <Link to="/">
        <img
          className="dashboard__img-logo"
          src={logo}
          alt="dermio-logo"
          style={{ paddingTop: "0.3em" }}
        />
      </Link>
    }
    style={{ marginTop: "4rem" }}
    {...props}
  >
    <SimpleForm
      defaultValue={
        props.permissions === "doctor"
          ? {
              doctorId: localStorage.getItem("userId"),
              createdBy: "doctor",
            }
          : { createdBy: "admin" }
      }
      toolbar={<SaveButton id="templateCreate" style={{ marginTop: 10 }} />}
      redirect="/Template"
    >
      <TextInput
        source="impression"
        label="Impression"
        validate={validateText}
      />
      <LongTextInput
        source="impressionDescription"
        label="Impression description"
      />
      <LongTextInput
        source="skinCareInstructions"
        label="Skin care instructions"
      />
      <LongTextInput source="plan" label="Plan" resettable />
      <LongTextInput
        source="sideEffectsString"
        label="Side effects"
        resettable
      />
    </SimpleForm>
  </Create>
)
