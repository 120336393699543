import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
  query getConsult($id: String!) {
    Consult(id: $id) {
      id
      status
    }
  }
`

const mutationUpdateConsults = gql`
  mutation Create(
    $doctorId: String!
    $status: String!
    $id: ID!
    $closedUnPaidReason: String
    $prescribed: Boolean
    $doctorName: String
    $consultationFee: Int
    $videocallFee: Int
    $totalFee: Int
  ) {
    updateConsult(
      doctorId: $doctorId
      status: $status
      id: $id
      closedUnPaidReason: $closedUnPaidReason
      prescribed: $prescribed
      doctorName: $doctorName
      consultationFee: $consultationFee
      videocallFee: $videocallFee
      totalFee: $totalFee
    ) {
      id
      status
      closedUnPaidReason
    }
  }
`

const mutationUpdatePrescribe = gql`
  mutation Create($id: ID!, $prescribed: Boolean!) {
    updateConsult(id: $id, prescribed: $prescribed) {
      id
      status
    }
  }
`

const mutationChargeConsults = gql`
  mutation Create(
    $cardToken: String!
    $status: String!
    $id: ID!
    $stripeId: String!
    $consultationFee: Int!
    $source: String
    $email: String
    $phoneNumber: String
    $customerId: String
    $didVideoCall: String
    $doctorFee: Int
    $doctorId: String
    $doctorName: String
    $consultName: String
    $chargeId: String
  ) {
    chargeCustomer(
      id: $id
      status: $status
      cardToken: $cardToken
      stripeId: $stripeId
      consultationFee: $consultationFee
      source: $source
      email: $email
      phoneNumber: $phoneNumber
      customerId: $customerId
      doctorFee: $doctorFee
      didVideoCall: $didVideoCall
      doctorId: $doctorId
      doctorName: $doctorName
      consultName: $consultName
      chargeId: $chargeId
    ) {
      id
      status
    }
  }
`

const updateConsults = async (
  record,
  status,
  closedUnPaidReason,
  prescribed,
  didVideoCall,
  doctorDetails,
  practiceStripeId
) => {
  // console.log("didVideoCall ",didVideoCall)
  // console.log("doctorDetails ",doctorDetails)
  console.log("chargeID  ",record)
  const { id, cardToken, source, email, phone_number, patientname } = record

  // const doctorId = '193d87b9-5751-4f9c-94b2-dca7da25aa88';
  const doctorId = localStorage.getItem("userId")
  let stripeId = null
  let consultationFee = null
  const doctorDetail = JSON.parse(localStorage.getItem("doctorDetails"))
  consultationFee = doctorDetail.practice.consultationFee
  const doctorVideocallFee =
    doctorDetails && doctorDetails.videocallFee ? doctorDetails.videocallFee : 0

  if (practiceStripeId) {
    stripeId = practiceStripeId
    console.log("Transfer fee to Practice account")
    // console.log("1.practiceStripeId ",stripeId)
  } else {
    stripeId = doctorDetail.stripeId
    console.log("Transfer fee to Doctor account")
    // console.log("2.doctorDetail.stripeId ",stripeId)
  }

  const updateDetails = {
    doctorId, // string
    status, // string
    id, // string
    doctorName: doctorDetails && doctorDetails.name, // doctorName
  }

  const chargeDetails = {
    status,
    id,
    cardToken,
    stripeId,
    consultationFee,
    source,
    email,
    phoneNumber: phone_number,
    customerId: record.stripeId,
    doctorFee: doctorVideocallFee,
    didVideoCall,
    doctorId: doctorDetails && doctorDetails.id,
    doctorName: doctorDetails && doctorDetails.name,
    consultName: patientname,
    chargeId: record.chargeId
  }

  const unPaidDetails = {
    doctorId,
    status,
    id,
    closedUnPaidReason,
  }

  const consultDetails = {
    id,
  }

  const insuranceConsultDetails = {
    doctorId,
    status,
    id,
    closedUnPaidReason,
    consultationFee: Number(consultationFee),
    videocallFee: Number(doctorVideocallFee),
    totalFee: didVideoCall
      ? Number(consultationFee) + Number(doctorVideocallFee)
      : Number(consultationFee),
  }

  if (prescribed) {
    return await client.hydrated().then(function(cl) {
      const mutation = cl.mutate({
        mutation: mutationUpdatePrescribe,
        variables: { id, prescribed },
        fetchPolicy: "no-cache",
      })
      return mutation
    })
  }

  // Check status of consult again before mutating

  if (status === "accepted") {
    const data = await client.hydrated().then(function(cl) {
      return cl
        .query({ query, variables: consultDetails })
        .then(data => {
          // console.log(data);
          return data
        })
        .catch(e => {
          // console.log('Something went wrong', e);
        })
    })

    const consult = data.data.Consult

    // console.log(consult);

    if (consult.status !== "pending") {
      return Promise.reject("Accepted Consult")
    }
  }

  if (status === "closed_paid") {
    console.log("chargeDetails ",chargeDetails)
    return await client.hydrated().then(function(cl) {
      const mutation = cl.mutate({
        mutation: mutationChargeConsults,
        variables: chargeDetails,
        fetchPolicy: "no-cache",
      })
      return mutation
    })
  }

  if (status === "closed_unpaid") {
    return await client.hydrated().then(function(cl) {
      const mutation = cl.mutate({
        mutation: mutationUpdateConsults,
        variables: unPaidDetails,
        fetchPolicy: "no-cache",
      })
      return mutation
    })
  }

  if (status === "closed_insurance") {
    return await client.hydrated().then(function(cl) {
      const mutation = cl.mutate({
        mutation: mutationUpdateConsults,
        variables: insuranceConsultDetails,
        fetchPolicy: "no-cache",
      })
      return mutation
    })
  }

  const sendNotificationToPatient = async record => {
    const options = {
      method: "POST",
      body: JSON.stringify({ phoneNumber: record.phone_number }),
    }
    const sns = await fetch(process.env.REACT_APP_SNS_URL, options)
    const data = await sns.json()
  }

  const updatedConsult = await client.hydrated().then(function(cl) {
    const mutation = cl.mutate({
      mutation: mutationUpdateConsults,
      variables: updateDetails,
      fetchPolicy: "no-cache",
    })
    return mutation
  })

  console.log(updatedConsult)
  console.log("updated consult")
  if (updatedConsult.data.updateConsult.status === "accepted") {
    sendNotificationToPatient(record)
  }

  return updatedConsult
}

export default updateConsults
