import React from "react"
import { Auth } from "aws-amplify"
import { VerifyContact } from "aws-amplify-react"
import { I18n, JS, ConsoleLogger as Logger } from "@aws-amplify/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import { withStyles } from "@material-ui/core/styles"
import dermioLogo from "../Images/dermio-logo.svg"

const styles = theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background:
      "url(https://images.unsplash.com/photo-1519781542704-957ff19eff00?ixlib=rb-1.2.1&auto=format&fit=crop&w=2046&q=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  error: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: "rgb(196, 26, 22)",
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
})

class CustomVerifyContact extends VerifyContact {
  state = {
    verifyAttr: null,
    error: "",
  }

  handleChange = e => {
    this.handleInputChange(e)
    this.setState({
      error: "",
    })
  }

  submit(e) {
    e.preventDefault()
    const attr = this.state.verifyAttr
    const { code } = this.inputs

    Auth.verifyCurrentUserAttributeSubmit(attr, code)
      .then(data => {
        this.changeState("signedIn", this.props.authData)
        this.setState({ verifyAttr: null, error: "" })
      })
      .catch(err => {
        console.error(err)

        if (err.code === "CodeMismatchException") {
          this.setState({
            error:
              "Invalid verification code provided. Please enter the correct code.",
          })
        }
      })
  }

  verify(e) {
    e.preventDefault()
    const { contact, checkedValue } = this.inputs
    if (!contact) {
      console.error("Neither Email nor Phone Number selected")
      return this.setState({
        error: "Neither Email nor Phone Number selected",
      })
    }

    Auth.verifyCurrentUserAttribute(checkedValue)
      .then(data => {
        this.setState({ verifyAttr: checkedValue })
      })
      .catch(err => {
        console.error(err)
      })
  }

  verifyView() {
    const { authData: user, classes } = this.props

    if (!user) {
      return null
    }

    const { unverified } = user

    if (!unverified) {
      return null
    }

    const { email, phone_number } = unverified

    return (
      <form onSubmit={this.verify}>
        <div className={classes.hint}>VERIFY ACCOUNT</div>
        <div className={classes.error}>{this.state.error}</div>
        <div className={classes.form}>
          <div className={classes.input}>
            {email ? (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  Account recovery requires verified contact information
                </FormLabel>
                <FormControlLabel
                  value="email"
                  control={
                    <Radio
                      id="contact"
                      name="contact"
                      value="email"
                      label="Email"
                      margin="normal"
                      required
                      onChange={this.handleInputChange}
                    />
                  }
                  label="Email"
                />
              </FormControl>
            ) : null}
            {phone_number ? (
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">
                  Account recovery requires verified contact information
                </FormLabel>
                <FormControlLabel
                  value="phone_number"
                  control={
                    <Radio
                      id="contact"
                      name="contact"
                      value="phone_number"
                      label="Phone Number"
                      margin="normal"
                      required
                      onChange={this.handleInputChange}
                    />
                  }
                  label="Phone Number"
                />
              </FormControl>
            ) : null}
          </div>
        </div>
        <CardActions className={classes.actions}>
          <Button
            variant="raised"
            type="submit"
            color="primary"
            className={classes.button}
            fullWidth
          >
            Verify
          </Button>
        </CardActions>
      </form>
    )
  }

  submitView() {
    const { classes } = this.props

    return (
      <form onSubmit={this.submit}>
        <div className={classes.hint}>VERIFY ACCOUNT</div>
        <div className={classes.error}>{this.state.error}</div>
        <div className={classes.form}>
          <div className={classes.input}>
            <FormControl className={classes.formControl}>
              <FormLabel component="legend">
                Account recovery requires verified contact information
              </FormLabel>
              <TextField
                id="code"
                name="code"
                label="Code"
                margin="normal"
                type="text"
                fullWidth={true}
                required
                autoComplete="off"
                error={this.state.error ? true : false}
                onChange={this.handleChange}
              />
            </FormControl>
          </div>
        </div>
        <CardActions className={classes.actions}>
          <Button
            variant="raised"
            type="submit"
            color="primary"
            className={classes.button}
            fullWidth
          >
            Submit
          </Button>
        </CardActions>
      </form>
    )
  }

  render() {
    const { classes, authState } = this.props
    const { verifyAttr } = this.state

    if (authState === "verifyContact") {
      return (
        <div className={classes.main}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <img
                alt="Dermio Logo"
                src={dermioLogo}
                className={classes.avatar}
              />
            </div>
            {verifyAttr ? this.submitView() : this.verifyView()}
          </Card>
        </div>
      )
    } else {
      return null
    }
  }
}

export default withStyles(styles)(CustomVerifyContact)
