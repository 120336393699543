import React, { useState, useEffect, useRef } from 'react';
import AWS from 'aws-sdk';
import { SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import Typography from "@material-ui/core/Typography"
import { Responsive } from "react-admin/lib"
import Divider from "@material-ui/core/Divider"
import { Button, CardContent, Card } from "@material-ui/core"
// import Container from '@material-ui/core/Container';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CallEndIcon from '@material-ui/icons/CallEnd';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment'
import VideoCallIcon from '@material-ui/icons/VideoCall';
import Image from '../Images/wait.svg'
import Modal from "@material-ui/core/Modal"
import getCurrentTimezoneName from "../util/getCurrentTimezoneName"
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicOffIcon from '@material-ui/icons/MicOff';

import './VideoCall.css';



import updateVideoCallStartTime from "../queries/updateStartTime.js"
import updateVideoCallEndTime from "../queries/updateEndTime.js"
import updateVideoCallLastActiveTime from "../queries/updateLastActiveTime.js"


import { mutateStatus, subscribe } from './subscription';



let doctorStatus = 'initial';
let startTime = false;
let master = {};
let docSrcReady = false;
export default function (props) {


  const channelName = props.consultRecordObj.record.doctorId;
  const date = moment(props.consultRecordObj.record.scheduledDateTime);
  const callStatus = props.consultRecordObj.record.callStatus;
  const inviteStatus = props.consultRecordObj.record.inviteStatus;
  const id = props.id;

  const patientVideo = useRef();
  const [showFullScreen, setfullScreen] = useState(false);
  const [doctorConnected, setDoctorConnected] = useState(false);
  const [joinCallButton, setJoinCallButton] = useState(true);
  const [patientConnected, setPaitentConnected] = useState(false);
  const isfullScreen = document.fullscreen;
  const [videoWindow, setVideoWindow] = useState(true);
  const [callCompleted, setCallCompleted] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isMute, setMute] = useState(false);
  const [isVidMute, setVidMute] = useState(false);
  const [connecting, setConnecting] = useState(false);

  useEffect(() => {
    // console.log('useEffect')
    if (master.signalingClient) {
      master.signalingClient.close();
      master.signalingClient = null;
      window.location.reload();
    }

    (async () => {
      const videoCallId = { id: id };
      await subscribe(videoCallId, actBasedOnPatientState);
      mutateStatus(id, doctorStatus);
    })()
  }, []);

  async function actBasedOnPatientState(data) {

    let patient = data.data.onUpdatePatientTime.patientStatus;
    console.log('master', doctorStatus, 'patient', patient)
    // if( doctorStatus == patient ) {

    // }
    if (doctorStatus == "initial" && patient == "ready") {
      
    }
    if (doctorStatus == "ready" && patient == "initial") {
      docSrcReady = false;
      setConnecting(false);
      getLocalStream();
    }
    if (doctorStatus === "local" && patient === "local") {
      docSrcReady = false;
      mutateStatus(id, 'local');
    }
    if (doctorStatus == "local" && patient == "ready" && !docSrcReady) {
      setConnecting(true);
      await doctorScreen();
    }
    if (doctorStatus == "ready" && patient == "ready") {

    }
    if (doctorStatus == "ready" && patient == "connected") {
      if (!startTime && !props.consultRecordObj.record.startTime) {
        startTime = true;
        const videoCall = props.consultRecordObj.record;
        const variables = {
          id: videoCall.id,
          startTime: moment()
        }
        await updateVideoCallStartTime(variables);
        await updateLastActive();
      } else {
        await updateLastActive();
      }
      mutateStatus(id, 'connected');
    }
    if ((doctorStatus == "connected" || doctorStatus == "ready") && patient == "disconnected") {
      setConnecting(false);
      alert('Patient ends the call');
      docSrcReady = false;
      getLocalStream();
    }
  }

  async function updateLastActive() {
    const videoCall = props.consultRecordObj.record;
    const variables = {
      id: videoCall.id,
      lastActive: moment()
    }
    await updateVideoCallLastActiveTime(variables);
  }

  async function getLocalStream() {
    // console.log('local stream')
    setJoinCallButton(false);
    setPaitentConnected(false);
    if (master.signalingClient) {
      master.signalingClient.close();
    }
    try {
      if (patientVideo && patientVideo.current && patientVideo.current.srcObject) {
        master.remoteStream = null;
        patientVideo.current.srcObject = null;
      }
      const resolution = { width: 640, height: 480 };
      const constraints = {
        video: resolution,
        audio: true,
      }
      let localStream = await navigator.mediaDevices.getUserMedia(constraints)
      document.getElementById("doctor").srcObject = localStream;
      doctorStatus = "local";
      setDoctorConnected(true);
      mutateStatus(id, 'local');
    } catch (e) {
      alert("Could not find webcam, please check your webcam and try again")
    }
  }



  async function doctorScreen() {
    docSrcReady = true;
    try {
      const kinesisVideoClient = new AWS.KinesisVideo({
        region: process.env.REACT_APP_AWS_KINESIS_WEBRTC_REGION,
        accessKeyId: process.env.REACT_APP_AWS_KINESIS_WEBRTC_ACCESSKEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_KINESIS_WEBRTC_SECRET_ACCESSKEY,
        correctClockSkew: true,
      });
      try {
        await kinesisVideoClient
          .createSignalingChannel({
            ChannelName: channelName,
          })
          .promise();
      } catch{
        console.log("Channel already exist")
      }

      const describeSignalingChannelResponse = await kinesisVideoClient
        .describeSignalingChannel({
          ChannelName: channelName,
        })
        .promise();

      const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;

      const getSignalingChannelEndpointResponse = await kinesisVideoClient
        .getSignalingChannelEndpoint({
          ChannelARN: channelARN,
          SingleMasterChannelEndpointConfiguration: {
            Protocols: ['WSS', 'HTTPS'],
            Role: 'MASTER',
          },
        })
        .promise();
      const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
      }, {});

      master.signalingClient = new SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        role: 'MASTER',
        region: process.env.REACT_APP_AWS_KINESIS_WEBRTC_REGION,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_KINESIS_WEBRTC_ACCESSKEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_KINESIS_WEBRTC_SECRET_ACCESSKEY,
          systemClockOffset: kinesisVideoClient.config.systemClockOffset,
        },
      });

      const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
        region: process.env.REACT_APP_AWS_KINESIS_WEBRTC_REGION,
        accessKeyId: process.env.REACT_APP_AWS_KINESIS_WEBRTC_ACCESSKEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_KINESIS_WEBRTC_SECRET_ACCESSKEY,
        endpoint: endpointsByProtocol.HTTPS,
        correctClockSkew: true,
      });
      const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
        .getIceServerConfig({
          ChannelARN: channelARN,
        })
        .promise();
      const iceServers = [];
      iceServers.push({ urls: `stun:stun.kinesisvideo.us-east-1.amazonaws.com:443` });
      getIceServerConfigResponse.IceServerList.forEach(iceServer =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        }),
      );

      const configuration = {
        iceServers,
        iceTransportPolicy: 'all',
      };

      master.signalingClient.open();
      master.signalingClient.on('open', async () => {
        const resolution = { width: 640, height: 480 };
        const constraints = {
          video: resolution,
          audio: true,
        };
        try {
          master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
          let docVideoElem = document.getElementById('doctor');
          docVideoElem.srcObject = master.localStream;
          docVideoElem.muted = true;
          doctorStatus = "ready";
          mutateStatus(id, "ready");
        } catch (e) {
          alert('Check your webcam and try again...');
        }
      })

      master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
        console.log('offer received')
        const peerConnection = new RTCPeerConnection(configuration);
        master.peerConnectionByClientId = [];
        master.peerConnectionByClientId[remoteClientId] = peerConnection;

        peerConnection.addEventListener('track', event => {
          setPaitentConnected(true);
          let patientVidId = document.getElementById("patient");
          if (patientVidId && !patientVidId.srcObject) {
            patientVidId.srcObject = event.streams[0];
          }
        });

        if (master.localStream) {
          await master.localStream.getTracks().forEach(track => peerConnection.addTrack(track, master.localStream));
        }
        await peerConnection.setRemoteDescription(offer);

        await peerConnection.setLocalDescription(
          await peerConnection.createAnswer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
          }),
        );
        master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);

        peerConnection.addEventListener('icecandidate', ({ candidate }) => {
          if (candidate) {
            master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
              const peerConnection = master.peerConnectionByClientId[remoteClientId];
              peerConnection.addIceCandidate(candidate);
            });
            master.signalingClient.sendIceCandidate(candidate, remoteClientId);
          }
        });
      });

      master.signalingClient.on('error', error => {
        console.log(error)
        window.location.reload();
      });

      master.signalingClient.on('close', error => {
        console.log(error, 'close')
      });

    } catch (e) {
      console.log(e)
      try {
        let data = {
          data: e.message,
          consultName: "Video-call error",
        }
        fetch(
          process.env.REACT_APP_LOG_URL,
          {
            method: "POST",
            mode: "no-cors",
            body: JSON.stringify(data),
          }).then(response => {
            return response.text()
          }).then(result => {
            // console.log(result)
          }).catch(err => { // console.log(err)
          })
      } catch {
        console.log('err')
      }
    }

  }

  async function onClose() {

    setCallCompleted(true);
    if (master.signalingClient) {
      master.signalingClient.close();
      master.signalingClient = null;
    }

    if (master.peerConnectionByClientId) {
      master.peerConnectionByClientId = [];
      master.peerConnectionByClientId.forEach(clientId => {
        master.peerConnectionByClientId[clientId].close();
      });
    }

    if (master.localStream) {
      master.localStream.getTracks().forEach(track => track.stop());
      master.localStream = null;
    }

    if (master.remoteStreams) {
      master.remoteStreams.forEach(remoteStream => remoteStream.getTracks().forEach(track => track.stop()));
      master.remoteStreams = [];
    }

    if (master.peerConnectionStatsInterval) {
      clearInterval(master.peerConnectionStatsInterval);
      master.peerConnectionStatsInterval = null;
    }

    if (master.localView) {
      master.localView.srcObject = null;
    }

    if (master.remoteView) {
      master.remoteView.srcObject = null;
    }

    if (master.dataChannelByClientId) {
      master.dataChannelByClientId = {};
    }
    mutateStatus(id, "disconnected");
    setTimeout(() => {
      const videoCall = props.consultRecordObj.record;
      const variables = {
        id: videoCall.id,
        endTime: moment(),
        callStatus: "completed"
      }
      updateVideoCallEndTime(variables).then((response) => {
        props.history.push(`/`);
        window.location.reload();
      }).catch(console.log)
    }, 2000);

  }

  async function resumeLater() {
    window.location.reload();
  }

  const ConsultPageButton = ({ id }) => {
    // console.log(id, "id")
    return (
      <div style={{ paddingBottom: "1rem" }}><Divider />
        <Typography style={{ paddingLeft: "1rem", paddingTop: "1rem", color: "gray", textDecoration: "none" }}>
          To show consult chat or debit payment or prescribe medicine :&nbsp;
        <Button variant="outlined" color="primary"
            onClick={() => { props.history.push(`/Consult/${id}/show`) }}
            size="large" >Go to consult page</Button>
          <br />
        </Typography>
      </div>
    )
  }

  function openFullscreen() {
    const elem = document.getElementById("fullScreen");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  }

  function closeFullscreen() {
    if (isfullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      setfullScreen(!showFullScreen);
    }
  }

  const fullScreen = showFullScreen && isfullScreen ? (
    <div id="full-window" onClick={closeFullscreen}><Typography color="secondary" gutterBottom>
      Exit fullScreen
  </Typography></div>
  ) : (
      <div id="full-window"
        onClick={openFullscreen}><Typography color="secondary" gutterBottom>
          Fullscreen
  </Typography> </div>
    )

  return (
    <Responsive
      small={
        <div style={{ paddingTop: '30px' }} >

          {patientConnected ? null :
            joinCallButton ?
              date < moment() ?
                callStatus == "upcoming" ?
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: 'fit-content' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CardContent>
                          <br />

                          <Typography color="primary">
                            Note:{" "}
                            <ul>
                              <li>
                                <Typography color="textSecondary">
                                  Make sure your browser camera & microphone are enabled.
                      </Typography></li>
                              <li>
                                <Typography color="textSecondary">
                                  Please use chrome browser in PC, mac and android. Use safari browser in iOS devices (iPhone and iPad).
                                </Typography></li>
                              <li>
                                <Typography color="textSecondary">
                                  Once you join the call, if you face any issues, please refresh and join the call again.
                      </Typography></li>
                            </ul>
                          </Typography>
                          <br />
                        </CardContent>
                        <br />
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '2.5rem' }}>
                        {inviteStatus == "accepted" ?
                          <Button variant="outlined" onClick={getLocalStream}> <Typography
                            variant="caption"
                            style={{
                              color: "#3fcff3",
                            }}
                          >
                            <VideoCallIcon fontSize="large" id="hangup" />
                            Join call
                    </Typography></Button>
                          :
                          <Button variant="outlined" disabled> <Typography
                            variant="caption"
                            style={{
                              color: "#3fcff3",
                            }}
                          >
                            Consult declined
                  </Typography></Button>}
                      </div>
                      <ConsultPageButton id={props.consultRecordObj.record.consultId} />
                    </Card> </div>
                  : <ChatHistory {...props} /> :
                date > moment() ?
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Card style={{ width: 'fit-content' }}>
                      <h1 style={{ padding: 10 }}>Your consult time is scheduled at {date.format('LLLL')} ({getCurrentTimezoneName()})</h1>
                      <CardContent>
                        <Typography color="secondary" gutterBottom variant="h3" component="h3">
                          Join the consult on time.
                      </Typography>
                        <br />
                        <Typography color="primary">
                          Note:  <ul>
                            <li>
                              <Typography color="textSecondary">
                                Make sure your browser camera & microphone are enabled.
                      </Typography></li>
                            <li>
                              <Typography color="textSecondary">
                                Please use chrome browser in PC, mac and android. Use safari browser in iOS devices (iPhone and iPad).
                      </Typography></li></ul>
                        </Typography>
                        <br />
                      </CardContent>
                      <ConsultPageButton id={props.consultRecordObj.record.consultId} />
                    </Card>
                  </div>
                  :
                  <ChatHistory {...props} />
              :
              doctorConnected ? null : <div style={{ padding: '10%' }}> <Card><Button disabled> <Typography
                variant="caption"
                style={{
                  color: "#3fcff3",
                }}
              >
                <h1>Joining call...</h1>
                <CircularProgress size={20} thickness={3} />
              </Typography></Button>
                <Typography style={{ color: "rgb(63, 207, 243)", margin: '10px', marginLeft: "14px" }}>
                  Looks like your patient has not joined yet, please wait for a moment or send a message via chat.
                      </Typography>
                <ul><li>
                  <Typography style={{ color: "black", margin: "10px", padding: "5px" }}>
                    Once you join the call, if you face any issues, please refresh and join the call again.
                      </Typography></li>
                </ul></Card>
              </div>
          }

          <Modal
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={openModal}
            onClose={() => setOpenModal(false)}
          >
            {!startTime && !props.consultRecordObj.record.startTime ? <div
              style={{
                backgroundColor: "white",
                border: '1px solid #000',
              }}
            ><Typography color="textSecondary" gutterBottom>
                <h3>Patient did not connected, you want to complete this call?</h3>
                <h4>* You can resume the call with this patient later by clicking resume later button.</h4>
              </Typography>
              &nbsp;
              <span>&nbsp;
                <Button style={{ backgroundColor: "green" }} fontSize="small"
                  onClick={onClose} >Mark completed</Button>
              </span>
              &nbsp;&nbsp;
              <span>
                <Button style={{ backgroundColor: "red" }} fontSize="small"
                  onClick={() => setOpenModal(false)} >cancel</Button>
              </span>
              &nbsp;&nbsp;
              <span>
                <Button style={{ backgroundColor: "gray" }} fontSize="small"
                  onClick={resumeLater} >Resume later</Button>
              </span>
            </div> :
              <div
                style={{
                  backgroundColor: "white",
                  border: '1px solid #000',
                }}
              ><Typography color="textSecondary" gutterBottom>
                  <h3>Are you sure, you want to complete this call?</h3>
                </Typography>
                &nbsp;
              <span>&nbsp;
                <Button style={{ backgroundColor: "green" }} fontSize="small"
                    onClick={onClose} >Yes</Button>
                </span>
                &nbsp;&nbsp;
              <span>
                  <Button style={{ backgroundColor: "red" }} fontSize="small"
                    onClick={() => setOpenModal(false)} >No</Button>
                </span>
              </div>}
          </Modal>

          <Modal
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={openErrorModal}
            onClose={() => {
              setOpenErrorModal(false);
              props.history.push(`/`);
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                border: '1px solid #000',
              }}
            ><Typography color="textSecondary" gutterBottom>
                <h3 style={{ color: 'red' }}>Something went wrong!</h3>
                <Typography color="textSecondary" gutterBottom>
                  <h4>Make sure your system date, time is correct.</h4>
                  <h4>Please use chrome browser in PC, mac and android. Use safari browser in iOS devices (iPhone and iPad).</h4>
                </Typography>
              </Typography>
              &nbsp;
              <span>&nbsp;
                <Button style={{ backgroundColor: "green" }} fontSize="small"
                  onClick={() => props.history.push(`/`)} >Ok!</Button>
              </span>
            </div>
          </Modal>


          {callCompleted ? null : <div id='fullScreen' >
            <a onClick={() => setVideoWindow(!videoWindow)} >
              {!patientConnected ?
                doctorConnected ?
                  <div style={{
                    position: 'relative',
                    textAlign: 'center'
                  }}>
                    <img className={videoWindow ? "mainWindowImage" : "smallWindowImage"} src={Image} />
                    <Typography
                      variant="caption"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '40%',
                        color: "#3fcff3",
                      }}
                    >
                      {connecting ? <h3>Connecting call ...</h3> : <h3> Waiting for patient to join the call ...</h3>}
                      <CircularProgress size={30} thickness={4} />
                    </Typography>
                  </div> :
                  null :
                <video playsInline id="patient" ref={patientVideo} autoPlay className={videoWindow ? "mainWindow" : "smallWindow"} />
              }
            </a>

            <a onClick={() => setVideoWindow(!videoWindow)} >
              <video
                playsInline
                // muted 
                className={videoWindow ? "smallWindow" : "mainWindow"} id="doctor" autoPlay />
            </a>

            {doctorConnected ? <BottomNavigation className="navBar"  >
              <BottomNavigationAction onClick={() => setfullScreen(!showFullScreen)} icon={fullScreen} />
              {patientConnected ? <><BottomNavigationAction
                icon={<VideocamOffIcon
                  id="hangup"
                  label="video off"
                  fontSize="large"
                  color={isVidMute ? "error" : "gray"}
                />}
                onClick={() => {
                  master.localStream.getVideoTracks()[0].enabled = isVidMute;
                  setVidMute(!isVidMute)
                }}
              />
                <BottomNavigationAction
                  icon={<MicOffIcon
                    id="hangup"
                    label="mic off"
                    fontSize="large"
                    color={isMute ? "error" : "gray"}
                  />}
                  onClick={() => {
                    master.localStream.getAudioTracks()[0].enabled = isMute;
                    setMute(!isMute);
                  }}
                /></> : null}
              <BottomNavigationAction icon={<CallEndIcon fontSize="large" color="error"
                onClick={() => setOpenModal(true)} id="hangup" />} />

            </BottomNavigation> : null}
          </div>}

        </ div>}
    />
  );
}

const ChatHistory = (props) => {

  const ConsultPageButton = ({ id }) => {
    return (
      <div style={{ paddingBottom: "1rem" }}><Divider />
        <Typography style={{ paddingLeft: "2rem", paddingTop: "1rem", color: "gray", textDecoration: "none" }}>
          To show consult chat or debit payment or prescribe medicine :&nbsp;
        <Button variant="outlined" color="primary"
            onClick={() => { props.history.push(`/Consult/${id}/show`) }}
            size="large" >Go to consult page</Button>
          <br />
        </Typography>
      </div>
    )
  }


  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card >
        <CardContent>
          <Typography color="textSecondary" gutterBottom>
            <h1>Consult time is completed.</h1>
          </Typography>
          <br />
          {props.consultRecordObj.record.startTime && props.consultRecordObj.record.lastActive ?
            <><Typography color="Secondary">
              Consult Name : {props.consultRecordObj.record.consultName}
            </Typography>
              <br />
              <Typography color="Secondary">
                Date : {props.consultRecordObj.record.scheduledDateString}
              </Typography>
              <br />
              <Typography color="Secondary">
                Start time : {moment(props.consultRecordObj.record.startTime).format('LT')}
              </Typography>
              <br />
              <Typography color="Secondary">
                End time : {moment(props.consultRecordObj.record.endTime).format('LT')}
              </Typography>
              <br />
              <Typography color="Secondary">
                Duration : {moment(props.consultRecordObj.record.endTime || props.consultRecordObj.record.lastActive).diff(moment(props.consultRecordObj.record.startTime), "minutes")} minutes
                      </Typography>
            </> : <h2 style={{ textAlign: 'center' }}> Participants are not connected.</h2>}
        </CardContent>
        <ConsultPageButton id={props.consultRecordObj.record.consultId} />
      </Card>
    </div>
  )
}
