import React, { Component } from "react"
import { Layout } from "react-admin"
import { Auth } from "aws-amplify"

import DoctorCustomAppBar from "./DoctorCustomAppBar"
import AdminCustomAppBar from "./AdminCustomAppBar"
import CustomMenu from "./CustomMenu"
// import CustomSideBar from './CustomSideBar'

class CustomLayout extends Component {
  state = {
    role: null,
  }

  async componentDidMount() {
    try {
      const currentSessionRes = await Auth.currentSession()
      const role = currentSessionRes.idToken.payload["cognito:groups"][0]

      this.setState({
        role,
      })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const { role } = this.state

    if (!role) {
      return <Layout {...this.props} />
    }

    if (role === "admin" || role === "practiceAdmin" || role === "nurse" || role === "staff") {
      return (
        <Layout
          {...this.props}
          appBar={AdminCustomAppBar}
          // sidebar={CustomSideBar}
          menu={CustomMenu}
          // notification={MyNotification}
          role={role}
        />
      )
    }

    return (
      <Layout
        {...this.props}
        appBar={DoctorCustomAppBar}
        // sidebar={CustomSideBar}
        menu={CustomMenu}
        // notification={MyNotification}
      />
    )
  }
}

export default CustomLayout
