import React, { useState, useEffect } from "react"
import {
  CardActions,
  ListButton,
  Edit,
  Link,
  SimpleForm,
  BooleanInput,
  RadioButtonGroupInput,
  TextInput
} from "react-admin"
import logo from "../Images/dermio-logo.svg"
import { DateInput, TimeInput } from 'react-admin-date-inputs';



import { SmokingRooms } from "@material-ui/icons"
import AwsImageComponent from "../Components/AwsImageComponent"
import animLoader from "../util/animLoader/animLoader"
import decryptConsult from "../util/decryptConsult"
import { Responsive } from "react-admin/lib"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Divider from "@material-ui/core/Divider"
import LinearProgress from "@material-ui/core/LinearProgress"
import Appsync from "../util/client"
import gql from "graphql-tag"
import fetchMyConsult from "../queries/fetchConsultDetails";

import setInviteStatus from '../queries/updateInviteStatus';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

const client = Appsync()


const query = gql`
query VideoCall($id: ID!){
  VideoCall(id: $id){
    id
    doctorId
    patientId
    consultId
    scheduledDateString
    callStatus
    createdAt
    scheduledDateTime
    doctorName
    consultName
    startTime
    endTime
    inviteStatus
  }
}
`

const fetchVideoCallDetails = async practiceId => {
  console.log(practiceId)
  const variables = { id: practiceId }

  const videoCallDetails = await client.hydrated().then(function (cl) {
    return cl.query({ query, variables, fetchPolicy: "network-only" })
  })

  return videoCallDetails
}



export const DoctorIcon = null

const CustomEditActions = ({ basePath, data, resource }) => (
  <CardActions>
    <ListButton basePath={basePath} record={data} />
    {/* <RefreshButton basePath={basePath} record={data} /> */}
  </CardActions>
)


export const VideoCallEdit = props => {

  const [data, setData] = useState(null);
  console.log(data, "data========")
  const [status, setStatus] = useState(null);
  const [consultId, setConsultId] = useState(null);
  // console.log(props.id, "props========")

  useEffect(() => {
    if (!data) {
      fetchVideoCallDetails(props.id)
        .then((res) => {
          console.log(res)
          const c_Id = res.data.VideoCall.consultId;
          setConsultId(c_Id);
          setStatus(res.data.VideoCall.inviteStatus);
          const variables = { patientId: res.data.VideoCall.patientId }
          fetchMyConsult(variables)
            .then(async (data) => {
              const array = data.data.myConsult
              //  console.log(array)
              console.log(array.find(val => val.id === c_Id))
              const finalData = array.find(val => val.id === c_Id)
              const decryptedData = await decryptConsult(finalData)
              setData(decryptedData)
            })
            .catch((err) => console.log(err))
        })
        .catch((err) => console.log(err))
    }
  })

  console.log(props, "propss")
  const doctor = JSON.parse(localStorage.getItem("doctorDetails"));
  return (
    data && status && consultId ?
      <>
        <Edit
          actions={<CustomEditActions />}
          title={
            <Link to="/">
              <img
                className="dashboard__img-logo"
                src={logo}
                alt="dermio-logo"
                style={{ paddingTop: "0.3em" }}
              />
            </Link>
          }
          style={{ marginTop: "4rem" }}
          {...props}
        >
          
          <><Typography style={{ paddingLeft: "2rem" }}>
            <h4 style={{ color: "#3fcff3", textDecoration: "none" }}>Status :&nbsp;
          <span style={{ color: "gray", textDecoration: "none" }}>{status}</span></h4>
            <br />
          </Typography>
            <Divider />
            <div style={{ paddingLeft: "3rem", paddingTop: "1rem" }}><ActionButtonComponent status={status} id={props.id} consultId={consultId}/>
            </div>
            <br />
            <Divider /></>
        </Edit>
        <PatientDetails data={data} />
        <Divider />
        <Typography style={{ paddingLeft: "1rem", paddingTop: "1rem", color: "gray", textDecoration: "none" }}>
          To show consult chat or debit payment or prescribe medicine :&nbsp;
          <Button variant="outlined" color="primary"
            onClick={() => { props.history.push(`/Consult/${consultId}/show`) }}
            size="large" >Go to consult page</Button>
          <br />
        </Typography>
      </> : <h4>Loading ...</h4>
  )
}

const ExpansionPanel = withStyles({
  root: {
    margin: "1rem 0",
  },
  expanded: {},
})(MuiExpansionPanel)


const PatientDetails = ({ data }) => {
  console.log(data, " inside data")
  return (
    <div
      style={{
        paddingTop: 20,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 20,
        color: "black",
      }}
    >
      {data ? <> <h1
        style={{
          color: "#3fcef3",
          marginBottom: 10,
          fontSize: "x-large",
        }}
      >
        Patient information
              </h1>
        <div style={{ margin: "1rem 0" }}>
          <ExpansionPanel
            elevation={5}
          // defaultExpanded={record.status === "pending" ? true : false}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="caption">Images</Typography>
              </div>
            </ExpansionPanelSummary>
            <Divider />
            <ExpansionPanelDetails >
              {data.url1 ? (
                <AwsImageComponent
                  src={data.url1}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                    })
                  }}
                />
              ) : null}
              {data.url2 ? (
                <AwsImageComponent
                  src={data.url2}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                    })
                  }}
                />
              ) : null}
              {data.url3 ? (
                <AwsImageComponent
                  src={data.url3}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                    })
                  }}
                />
              ) : null}
              {data.url4 ? (
                <AwsImageComponent
                  src={data.url4}
                  onClick={url => {
                    this.setState({
                      imgUrl: url,
                    })
                  }}
                />
              ) : null}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div
          style={{
            height: "100%",
            width: "0%",
            position: "absolute",
            bottom: 0,
            left: 0,
            zIndex: 101,
            backgroundColor: "#2a2a2a",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span
            style={{
              position: "absolute",
              right: 15,
              top: 15,
              color: "white",
              fontSize: 25,
              cursor: "pointer",
            }}

          >
            X
                </span>
          <img
            style={{
              maxHeight: "90%",
              maxWidth: "100%",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <span>
              {data.patientname ? data.patientname : "no name"}
            </span>{" "}
            / {data.age ? data.age : "no age"} / {data.gender}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ color: "#636e72" }}>
              {data.source ? data.source : ""}
            </div>
            <div>
              {data.createdAt
                ? `${new Date(data.createdAt).getMonth() +
                1}/${new Date(data.createdAt).getDate()}/${new Date(data.createdAt).getFullYear()}`
                : null}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgMjEuMjI0di0xMi43NzJjMS4xMDYtLjU5NCAxLjY3NC0xLjc2MiAxLjY3NC0zLjEwNCAwLTEuODc0LTEuNDczLTMuNDExLTMuMzItMy41MTUtLjc2OC0xLjA2OC0yLjIyMS0xLjgzMy0zLjczOS0xLjgzMy0uOTcxIDAtMS43OC4zMjItMi41ODIuOTY0LTEuMjkyLS40MjItMi43NDctLjE0My0zLjc5NS43OTItMi4xNTUtLjM0Mi00LjIzOCAxLjI0NC00LjIzOCAzLjUwMSAwIDEuMzk2LjgxOSAyLjU4MSAyIDMuMTV2MTIuODE3YzAgLjY2NC0uMzM2IDEuMjAzLTEgMS4yMDN2MS41NzNoMTZ2LTEuNTczYy0uNjY0IDAtMS0uNTM5LTEtMS4yMDN6bS05LS4yMjRoLTJ2LTEyLjcxOWMuNjcyLjQyMiAxLjUxNi40MDYgMiAuMjY3djEyLjQ1MnptNy4xNTQtMTMuNzA3Yy0uNjI3IDAtMS4xODQtLjI5Ni0xLjUzOS0uNzU2bC0uNDUxLjMxNmMtMS41MjIgMS4xNzgtLjExMyAzLjMwMy0uMDEgNC4zMjQuMTAyLjk3Ny0uNTA1IDEuNzEyLTEuNDU4IDEuNzEyLS45MyAwLTEuNDc1LS43ODYtMS40MDEtMS43MTIuMDc4LS45NzggMS41NjItMi45MTgtLjA4My00LjQzOGwtLjYyMy0uNTY4Yy0uNDI1LjQ5Ny0xLjA1Ni44MTMtMS43NjEuODEzLS42MDUgMC0xLjE1NS0uMjMxLTEuNTY4LS42MTEtLjM1NC40ODctLjkyNy44MDQtMS41NzUuODA0LTIuNzE2IDAtMi44MTctMy44ODkgMC0zLjg4OS4zOTggMCAuNzcuMTIgMS4wNzcuMzI2LjM4NC0uNzUxIDEuMTYzLTEuMjY2IDIuMDY1LTEuMjY2LjU5MSAwIDEuMTMxLjIyMiAxLjU0LjU4Ni40OTQtLjgxNCAxLjM5LTEuMzU5IDIuNDEyLTEuMzU5IDEuMjU1IDAgMi4zMi44MiAyLjY4NSAxLjk1NS4yMTMtLjA4Mi40NDUtLjEyNi42ODktLjEyNiAxLjA3MiAwIDEuOTQzLjg3MSAxLjk0MyAxLjk0NHMtLjg2OSAxLjk0NS0xLjk0MiAxLjk0NXptNy44NDYgNS4xNTljMCAyLjUzOS0xLjc5MSA1Ljc1LTUgNi45NjN2LTIuMTZjMy4xNTQtMS44MyAzLjk2OS02LjI1NSAxLjU1My02LjI1NWgtMS41NTN2LTJoMS45MTJjMi4xNDQgMCAzLjA4OCAxLjUzNCAzLjA4OCAzLjQ1MnoiLz48L3N2Zz4=" />
          <span style={{ marginLeft: 5 }}>
            {data.drinks_per_week ? data.drinks_per_week : 0} / week
                </span>

          <SmokingRooms style={{ marginLeft: 15, fontSize: 30 }} />
          <span style={{ marginLeft: 5 }}>
            {data.cigrattes_per_day ? data.cigrattes_per_day : 0} /
                      day
                </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>Allergy: </span>
          <span>
            {data.allergy ? data.allergy : "No info available"}
          </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>Medication: </span>
          <span>
            {data.medication ? data.medication : "No info available"}
          </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>
            History of medical issues:{" "}
          </span>
          <span>
            {data.medical_issues
              ? data.medical_issues
              : "No info available"}
          </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>
            Current medical issue:{" "}
          </span>
          <span>
            {data.currentMedicalIssue
              ? data.currentMedicalIssue
              : "No info available"}
          </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>Surgeries: </span>
          <span>
            {data.surgeries ? data.surgeries : "No info available"}
          </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>Email: </span>
          <span>
            {data.email ? data.email : "No info available"}
          </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>Phone number: </span>
          <span>
            {data.phone_number
              ? `+1${data.phone_number}`
              : "No info available"}
          </span>
        </div>
        <div style={{ marginTop: 15 }}>
          <span style={{ color: "#636e72" }}>
            Date of birth:{" "}
          </span>
          <span>
            {data.patientDob
              ? data.patientDob.split(' ').join('/')
              : "No info available"}
          </span>
        </div></> : <h3>Loading ... </h3>}
    </div>
  );
}


const ActionButtonComponent = ({ id, status, consultId }) => {

  console.log(id, "idd")
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  function handleClick(id, value, consultId) {
    setInviteStatus(id, value, consultId)
      .then((res) => {
        setSnackbarOpen(true);
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const AcceptButton = () => {
    return <Button variant="outlined" size="large" style={{ backgroundColor: "green" }} onClick={() => handleClick(id, "accepted", consultId)} >Accept </Button>
  }

  const DisabledAcceptButton = () => { return <Button variant="outlined" size="large" disabled >Accept </Button> }

  const DenyButton = () => { return <Button variant="outlined" size="large" style={{ backgroundColor: "red" }} color="default" onClick={() => handleClick(id, "declined", consultId)}>Decline </Button> }

  const DisabledDenyButton = () => { return <Button variant="outlined" size="large" disabled >Decline </Button> }

  const SnackBar = () => {
    return <Snackbar
      open={isSnackbarOpen}
      onClose={() => setSnackbarOpen(false)}
      TransitionComponent={SlideTransition}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">Consult status updated.</span>}
    />
  }

  if (status == "invited") {
    return (
      <div>
        <AcceptButton />&nbsp;
        <DenyButton />
        <SnackBar />
      </div>
    )
  } else if (status == "accepted") {
    return (
      <div>
        <DisabledAcceptButton />&nbsp;
        <DenyButton />
        <SnackBar />
      </div>)
  } else {
    return (
      <div>
        <AcceptButton />&nbsp;
        <DisabledDenyButton />
        <SnackBar />
      </div>
    )
  }

}
