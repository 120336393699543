import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

const query = gql`
query Practice($id: ID!){
    Practice(id: $id){
      id
      name
      stripeId
    }
  }
`

const fetchPractice = async practiceId => {
  // console.log(practiceId)
  const variables = { id: practiceId }
  //  console.log("variables ",variables)
  const practiceDetails = await client.hydrated().then(function(cl) {
    return cl.query({ query, variables })
  })

  return practiceDetails
}

export default fetchPractice
