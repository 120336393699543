import Amplify, { Auth } from "aws-amplify"
import Appsync from "../util/client"
const gql = require("graphql-tag")
const client = Appsync()

export const mutationUpdateChatsGen = chatIds => {
  const createNestedQuery = ids => {
    return ids.map(
      id => `
      updateChat${id.replace(/-/g, "")}: updateChat(
        id: "${id}",
        seen: true
      ){ 
        id
        createdAt
        seen
        senderRole
        senderId
        receiverId
        message
      }`
    )
  }

  const nestedQuery = createNestedQuery(chatIds)
  const query = nestedQuery.join(" ")
  return gql`mutation update { ${query} }`
}

export const mutationUpdateChatsRead = chatIds => {
  const createNestedQuery = ids => {
    return ids.map(
      id => `
      updateChat${id.replace(/-/g, "")}: updateChat(
        id: "${id}",
        read: true
      ){ 
        id
        createdAt
        seen
        senderRole
        senderId
        receiverId
        message
      }`
    )
  }

  const nestedQuery = createNestedQuery(chatIds)
  const query = nestedQuery.join(" ")
  return gql`mutation update { ${query} }`
}

const updatePatientChats = async mutationUpdateChats => {
  const updatedPatientChats = await client.hydrated().then(cl => {
    const mutation = cl.mutate({
      mutation: mutationUpdateChats,
      variables: {},
      fetchPolicy: "no-cache",
    })

    return mutation
  })

  return updatedPatientChats
}

export default updatePatientChats
