import React from "react"

export default class BankForm extends React.Component {
  nextStep = e => {
    e.preventDefault()
    this.props.nextStep()
  }
  render() {
    return (
      <div>
        <h2>Bank account Details</h2>
        <ul className="form-fields">
          <li>
            <label>Name on Account</label>
            <input
              type="text"
              ref="Accountname"
              defaultValue={this.props.data["Accountname"]}
              onChange={event => {
                this.props.fieldUpdate(event, "Accountname")
              }}
            />
          </li>
          <li>
            <label>Account Number</label>
            <input
              type="text"
              ref="AccountNumber"
              defaultValue={this.props.data["AccountNumber"]}
              onChange={event => {
                this.props.fieldUpdate(event, "AccountNumber")
              }}
            />
          </li>
          <li>
            <label>Routing Number</label>
            <input
              type="text"
              ref="RoutingNumber"
              defaultValue={this.props.data["RoutingNumber"]}
              onChange={event => {
                this.props.fieldUpdate(event, "RoutingNumber")
              }}
            />
          </li>
          <li className="form-footer">
            <button className="btn -primary pull-right" onClick={this.nextStep}>
              Save &amp; Continue
            </button>
            {"\t"}
            <button
              className="btn -primary pull-left"
              onClick={this.props.previousStep}
            >
              back
            </button>
          </li>
        </ul>
      </div>
    )
  }
}
