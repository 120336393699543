import React from "react"
import {
  ShowButton,
  List,
  Datagrid,
  DateField,
  TextField,
  BooleanField,
} from "react-admin/lib"

import ArchiveButton from "../../Components/ArchiveButton"

import "../../../node_modules/react-dropzone-component/styles/filepicker.css"

import "font-awesome/css/font-awesome.min.css"

import PatientLinkField from "../../Components/PatientLinkField"
import Pagination from "../../Pagination"
import { DoctorFilter } from "./doctorFilter"

import logo from "../../Images/dermio-logo.svg"
import { Link } from "react-router-dom"

import "../../customStyle.css"

const ConsultTitle = () => {
  return (
    <Link to="/">
      <img src={logo} alt="dermio-logo" style={{ paddingTop: "0.3em" }} />
    </Link>
  )
}

const PostBulkActionButtons = props => <ArchiveButton {...props} />

export const FullConsultList = ({ permissions, ...props }) => (
  <List
    {...props}
    title={<ConsultTitle />}
    create={() => <div> </div>}
    filters={<DoctorFilter permissions={permissions} />}
    pagination={<Pagination {...props} />}
    bulkActionButtons={<PostBulkActionButtons />}
    filterDefaultValues={{ archived: null, status: "pending" }}
  >
    <Datagrid>
      {/* <PatientLinkField /> */}
      {/* <TextField source="age" label="Age" /> */}
      {/* <TextField source="gender" label="Gender" /> */}
      <TextField source="status" label="Status" />
      <TextField source="source" label="Consult Source" />
      {/* <TextField source="state" label="State"/> */}
      <DateField source="createdAt" label="Created At" locales="en-US" />
      <TextField source="doctorName" label="Doctor" />
      <BooleanField source="archived" />

      <ShowButton />
    </Datagrid>
  </List>
)
